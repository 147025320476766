import { strapiFetch } from '../../entities/@api/Strapi';
import { BrandItemResponse } from '../../entities/Brand/Brand';
import { generateBrandQuery } from '../../entities/Brand/BrandRequests';
import { transformToBrand, transformToBrandNavigation } from '../../entities/Brand/BrandTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setNavigation,
} from './brandOverviewSlice';

export const fetchBrandNavigation: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setNavigation(undefined));

    try {
        const query = generateBrandQuery(slug);

        const brandsResponse = await strapiFetch<BrandItemResponse>(`/brands?${query}`);

        if (!isFetchResultSuccessful(brandsResponse)) {
            dispatch(setError(brandsResponse.error));
            return;
        }

        const { data: brandsData } = brandsResponse.data;

        const brands = brandsData
            ? brandsData.map(transformToBrand)
            : undefined;

        const navigation = brands
            ? transformToBrandNavigation(brands)
            : undefined;

        dispatch(setNavigation(navigation));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBrandNavigation]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
