import { PaginationConfig } from '../../@api/Sylius';
import { PaginationInterface } from '../../Pagination/Pagination';
import { EnrichedOrderProductVariant, OrderBase } from '../OrderDetail/OrderDetail';

export enum OrderOverviewType {
    online = 'online',
    inStore = 'inStore',
}

export interface OrderOverviewOrder extends OrderBase {
    productVariants: EnrichedOrderProductVariant[];
}

export type OrderOverviewConfig = PaginationConfig;

export interface OrderOverviewInterface {
    orders: OrderOverviewOrder[];
    pagination: PaginationInterface;
}
