import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { IFrameInterface, IFrameResource } from './IFrame';

export const transformToIFrame = (resource: IFrameResource): IFrameInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    return {
        ...defaultBlock,
        type: BlockType.iframe,
        title: resource.title,
        src: resource.url,
    };
};
