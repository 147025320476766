import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Address, Picture } from '../../../../../components';
import { LinkTarget } from '../../../../../entities/Link/Link';
import { Store } from '../../../../../entities/Store/Store';
import { getLinkGoogleMapToStore } from '../../../../../helpers/linkGoogleMapToStore';
import { getStoreOpeningStatus } from '../../../../../helpers/storeDayOpeningStatus';
import { useTrans } from '../../../../../hooks';
import { StoreDetailHeaderSkeletons } from '../../skeletons';

import './StoreDetailHeader.scss';

interface StoreDetailHeaderProps {
    isLoading?: boolean;
    store?: Store;
    className?: string;
}

export const StoreDetailHeader: FC<StoreDetailHeaderProps> = ({
    isLoading,
    store,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    if (isLoading || !store) {
        return (
            <StoreDetailHeaderSkeletons className={className} />
        );
    }

    const linkToGoogleMapsStore = getLinkGoogleMapToStore(store);

    const firstColumn = store.openingHours
        ? store.openingHours.slice(0, 4)
        : [];

    const secondColumn = store.openingHours.slice(4)
        ? store.openingHours.slice(4)
        : [];

    return (
        <header className={`store-detail-header ${className}`}>
            {store.image && (
                <Picture
                    {...store.image}
                    alt={store.image.alt || store.title}
                    className="store-detail-header__image"
                />
            )}

            <div className="store-detail-header__info">
                <h4 className="store-detail-header__info-title">
                    {store.title}
                </h4>

                <Address
                    address={store.address}
                    className="store-detail-header__address"
                >
                    <Link
                        to={linkToGoogleMapsStore}
                        target={LinkTarget.blank}
                        rel="noreferrer"
                        className="store-detail-header__route-link"
                    >
                        {trans('containers.storeDetail.routeLink')}
                    </Link>
                </Address>

                <h4 className="store-detail-header__info-is-open">
                    <p className="top-navbar__shop-opening-hours">
                        {getStoreOpeningStatus(store.openingHours)}
                    </p>
                </h4>

                <div className="store-detail-header__info-opening-hours">
                    <div className="opening-hours__column">
                        {firstColumn.map(({ day, from, to }) => (
                            <div key={day}>
                                {trans(`date.days.${day}`)}
                                :
                                {`${from?.substring(0, 5)} - ${to?.substring(0, 5)} ${trans('date.hour')}`}
                            </div>
                        ))}
                    </div>

                    <div className="opening-hours__column">
                        {secondColumn.map(({ day, from, to }) => (
                            <div key={day}>
                                {trans(`date.days.${day}`)}
                                :
                                {`${from?.substring(0, 5)} - ${to?.substring(0, 5)} ${trans('date.hour')}`}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </header>
    );
};
