import { AddressFormData, CustomerResource } from '../@api/Sylius';
import { Address, CustomerAddress } from '../Address/Address';
import { Customer } from './Customer';

export const transformAddressToAddressFormData = (address: Address, customer?: Customer): AddressFormData => ({
    firstName: customer?.firstName || '[firstName]',
    lastName: customer?.lastName || '[lastName]',
    phoneNumber: customer?.phoneNumber,
    countryCode: address.country,
    street: address.street,
    number: address.houseNumber,
    addition: address.addition,
    city: address.city,
    postcode: address.postalCode,
});

export const transformToCustomer = (resource: CustomerResource, id: string, customerAddresses?: CustomerAddress[]): Customer => {
    const addresses = customerAddresses || [];
    const company = undefined; // TODO: Add company (waiting to be added to API)

    return ({
        id,
        addresses,
        firstName: resource.firstName || '',
        insertion: '',
        lastName: resource.lastName || '',
        email: resource.email,
        birthday: resource.birthday || undefined,
        phoneNumber: resource.phoneNumber || '',
        company,
    });
};
