import { FC, ReactElement, useMemo } from 'react';

import { Footer } from '../../containers';
import { Navigation } from '../../entities/Navigation/Navigation';
import { useClientEffect } from '../../hooks';
import { MOCK_SOCIAL_MEDIA_NAVIGATION } from '../../mock/mock-data';
import { fetchNavigation } from '../../redux/navigations/navigationsActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedFooterProps {
    showTopFooter?: boolean;
    className?: string;
}

export const ConnectedFooter: FC<ConnectedFooterProps> = ({
    showTopFooter,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.navigationsSlice.isLoading);
    const navigations = useTypedSelector(state => state.navigationsSlice.navigations);

    const navigationKey = 'footer';

    const navigation = useMemo((): Navigation | undefined => (
        navigations[navigationKey]
    ), [navigations, navigationKey]);

    useClientEffect((): void => {
        dispatch(fetchNavigation({
            key: navigationKey,
            slug: navigationKey,
        }));
    }, []);

    return (
        <Footer
            isLoading={isLoading}
            showTopFooter={showTopFooter}
            navigation={navigation}
            socialNavigation={MOCK_SOCIAL_MEDIA_NAVIGATION}
            className={className}
        />
    );
};
