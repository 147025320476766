import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { HeaderWithIntro, SpaceCard } from '../../../compositions';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { Space } from '../../../entities/Space/Space';
import { useTrans } from '../../../hooks';

import './SpacesOverviewRenderer.scss';

interface SpacesOverviewRendererProps {
    isLoading?: boolean;
    intro: string;
    spaces: Space[];
    className?: string;
}

export const SpacesOverviewRenderer: FC<SpacesOverviewRendererProps> = ({
    isLoading,
    intro,
    spaces,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`spaces-overview-renderer ${className}`}>
            <HeaderWithIntro
                isLoading={isLoading}
                intro={intro}
                linkToPath={trans(appRoutes[AppRoute.spacesOverview].label)}
            />

            {!isLoading && (
                <Wrapper className="spaces-overview-renderer__list-wrapper">
                    <ul className="spaces-overview-renderer__list">
                        {spaces.map(space => (
                            <li
                                key={space.id}
                                className="spaces-overview-renderer__list-item"
                            >
                                <SpaceCard
                                    {...space}
                                    className="spaces-overview-renderer__card"
                                />
                            </li>
                        ))}
                    </ul>
                </Wrapper>
            )}
        </div>
    );
};
