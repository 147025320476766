import { FC, ReactElement, useRef } from 'react';

import { Pagination } from '../../../compositions';
import { OrderOverviewInterface } from '../../../entities/@account/OrderOverview/OrderOverview';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { OrderOverviewSkeletons } from './skeletons';
import { OrderOverviewCard } from './subcomponents';

import './OrderOverview.scss';

interface OrderOverviewProps {
    isLoading?: boolean;
    showPagination?: boolean;
    orderOverview?: OrderOverviewInterface;
    pageSize?: number;
    onPaginationChange: (page: number) => void;
    className?: string;
}

export const OrderOverview: FC<OrderOverviewProps> = ({
    isLoading,
    showPagination,
    orderOverview,
    pageSize,
    onPaginationChange,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const orderOverviewRef = useRef<HTMLDivElement>(null);

    if (isLoading || !orderOverview) {
        return (
            <OrderOverviewSkeletons
                numberOfSkeletons={pageSize}
                className={`order-overview ${className}`}
            />
        );
    }

    const handlePaginationChange = (page: number): void => {
        scrollIntoView(orderOverviewRef);

        onPaginationChange(page);
    };

    return (
        <section ref={orderOverviewRef} className={`order-overview ${className}`}>
            {orderOverview.orders.length === 0 && (
                <p className="order-overview__no-orders">
                    {trans('containers.orderOverview.noOrders')}
                </p>
            )}

            {orderOverview.orders.length > 0 && (
                <ul className="order-overview__grid">
                    {orderOverview.orders.map(order => (
                        <OrderOverviewCard
                            key={order.orderNumber}
                            order={order}
                            className="order-overview__card"
                        />
                    ))}
                </ul>
            )}

            {showPagination && orderOverview.pagination?.pageCount > 1 && (
                <div className="order-overview__pagination-wrapper">
                    <Pagination
                        numberOfPages={orderOverview.pagination.pageCount}
                        currentPage={orderOverview.pagination.page}
                        onChange={handlePaginationChange}
                    />
                </div>
            )}
        </section>
    );
};
