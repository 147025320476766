import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import {
    AddressForm,
    Button,
    RadioList,
    Textarea,
} from '../../../../compositions';
import { PaymentStepFormData } from '../../../../entities/@forms/PaymentForm/PaymentForm';
import { Address, AddressType, CustomerAddress } from '../../../../entities/Address/Address';
import { PaymentMethod } from '../../../../entities/PaymentMethod/PaymentMethod';
import { useTrans } from '../../../../hooks';

import './PaymentStep.scss';

interface PaymentStepProps {
    addresses: CustomerAddress[];
    paymentMethods: PaymentMethod[];
    onGoToPay: (data: PaymentStepFormData) => void;
    className?: string;
}

export const PaymentStep: FC<PaymentStepProps> = ({
    addresses,
    paymentMethods,
    onGoToPay,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [paymentMethodId, setPaymentMethodId] = useState<string>('');
    const [commentsForDelivery, setCommentsForDelivery] = useState<string | undefined>();

    const [shippingAddress, setShippingAddress] = useState<Address | undefined>();
    const [billingAddress, setBillingAddress] = useState<Address | undefined>();

    const [isAwaitingNextStep, setIsAwaitingNextStep] = useState<boolean>(false);

    useEffect((): void => {
        const shippingAddressDefault = addresses.find(address => address.type === AddressType.shipping);
        const billingAddressDefault = addresses.find(address => address.type === AddressType.billing);

        if (shippingAddressDefault) {
            setShippingAddress(shippingAddressDefault);

            const address: CustomerAddress = {
                ...shippingAddressDefault,
                type: AddressType.billing,
            };

            setBillingAddress(billingAddressDefault || address);
        }
    }, [addresses]);

    const handleContinueButtonClick = (): void => {
        setIsAwaitingNextStep(true);

        const body: PaymentStepFormData = {
            shippingAddress: {
                number: shippingAddress?.houseNumber || '',
                postalCode: shippingAddress?.postalCode || '',
                city: shippingAddress?.city || '',
                addition: shippingAddress?.addition || '',
                street: shippingAddress?.street || '',
                country: shippingAddress?.country || '',
            },
            billingAddress: {
                number: billingAddress?.houseNumber || '',
                postalCode: billingAddress?.postalCode || '',
                city: billingAddress?.city || '',
                addition: billingAddress?.addition || '',
                street: billingAddress?.street || '',
                country: billingAddress?.country || '',
            },
            paymentMethodId: Number(paymentMethodId),
            notes: commentsForDelivery,
        };

        onGoToPay(body);
    };

    return (
        <div className={`payment-step ${className}`}>
            <div>
                <h2 className="payment-step__heading">
                    {trans('containers.checkout.paymentStep.heading')}
                </h2>

                <p className="payment-step__sub-heading">
                    {trans('containers.checkout.paymentStep.subHeading')}
                </p>
            </div>

            <div className="payment-step__addresses">
                {shippingAddress && (
                    <>
                        <p className="payment-step__addresses-heading">
                            {trans('containers.checkout.paymentStep.shippingAddress')}
                        </p>

                        <AddressForm
                            hideTypeButtons
                            address={shippingAddress}
                            onSubmit={setShippingAddress}
                            className="payment-step__address"
                        />
                    </>
                )}

                {billingAddress && (
                    <>
                        <p className="payment-step__addresses-heading">
                            {trans('containers.checkout.paymentStep.billingAddress')}
                        </p>

                        <AddressForm
                            hideTypeButtons
                            address={billingAddress}
                            onSubmit={setBillingAddress}
                            className="payment-step__address"
                        />
                    </>
                )}
            </div>

            <Textarea
                label={trans('containers.checkout.paymentStep.commentsForDelivery')}
                rows={3}
                value={commentsForDelivery}
                onChange={setCommentsForDelivery}
                className="payment-step__textarea"
                fieldClassName="payment-step__textarea-field"
            />

            <p className="payment-step__addresses-heading">
                {trans('containers.checkout.paymentStep.choosePaymentMethod')}
            </p>

            {paymentMethods && (
                <RadioList
                    hideLabel
                    label={trans('containers.checkout.paymentStep.choosePaymentMethod')}
                    name="payment-methods"
                    value={paymentMethodId}
                    options={paymentMethods}
                    onChange={setPaymentMethodId}
                    className="payment-step__payment-methods"
                    radioClassName="payment-step__payment-method-item"
                />
            )}

            <Button
                isSmall
                hasAnimation
                text={trans('containers.checkout.paymentStep.pay')}
                disabled={!paymentMethodId || isAwaitingNextStep}
                onClick={handleContinueButtonClick}
                className="payment-step__button"
            />
        </div>
    );
};
