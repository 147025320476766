import { SchedulingResource } from '../Scheduling/Scheduling';

export interface DefaultBlockResource {
    id?: number;
    __component?: string;
    scheduling?: SchedulingResource;
}

export interface DefaultBlock {
    id?: string;
    type?: string;
    isPublished?: boolean;
}

export type BlockResource = DefaultBlockResource & unknown;
export type Block = DefaultBlock & unknown;

export enum BlockTypeResource {
    topNavbar = 'data.top-navbar',
    // Data
    blogOverview = 'data.blog-overview',
    highlightedBlogPost = 'data.blog-display',
    productCollection = 'data.products-collection',
    featuredProducts = 'data.products-view',
    relatedBlogPosts = 'data.related-blog-posts',
    // General info
    urgentBanner = 'general-info.urgent-banner',
    // Display
    brandCarousel = 'display.brands-carousel',
    iframe = 'display.iframe',
    faq = 'display.faq',
    service = 'display.service',
    uspSection = 'display.unique-selling-point',
    // TextImage
    carousel = 'text-image.carousel',
    contentColumns = 'text-image.content-columns',
    header = 'text-image.media',
    iconColumns = 'text-image.icon-columns',
    mediaBanner = 'text-image.media-banner',
    mediaGrid = 'text-image.media-grid',
    // User input
    form = 'user-input.form',
    newsletterCallToAction = 'user-input.news-letter',
}

export enum BlockType {
    topNavbar = 'topNavbar',
    blogOverview = 'blogOverview',
    brandCarousel = 'brandCarousel',
    brandHeader = 'brandHeader',
    carousel = 'carousel',
    contentColumns = 'contentColumns',
    faqs = 'faqs',
    featuredProducts = 'featuredProducts',
    iframe = 'iframe',
    form = 'form',
    header = 'header',
    highlightedBlogPost = 'highlightedBlogPost',
    iconColumns = 'iconColumns',
    mediaBanner = 'mediaBanner',
    mediaGrid = 'mediaGrid',
    newsletterCallToAction = 'newsletterCallToAction',
    serviceSection = 'serviceSection',
    uspSection = 'uspSection'
}
