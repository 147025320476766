import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture, Price } from '../../../../../components';
import { EnrichedOrderProductVariant } from '../../../../../entities/@account/OrderDetail/OrderDetail';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

import './OrderDetailProductVariant.scss';

interface OrderDetailProductVariantProps {
    productVariant: EnrichedOrderProductVariant;
    className?: string;
}

export const OrderDetailProductVariant: FC<OrderDetailProductVariantProps> = ({
    productVariant,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: productVariant.brand?.href,
    });

    const productPath = trans(appRoutes[AppRoute.productDetail].path);
    const productUrl = replaceUrlParamKeysWithValues(productPath, {
        slug: productVariant.variantSlug,
    });

    return (
        <div className={`order-detail-product-variant ${className}`}>
            <div className="order-detail-product-variant__picture-wrapper">
                <Picture
                    {...productVariant.image}
                    className="order-detail-product-variant__picture"
                />
            </div>

            <div className="order-detail-product-variant__info-wrapper">
                {productVariant.brand && (
                    <Link to={brandDetailUrl} className="order-detail-product-variant__brand-link">
                        <h3 className="order-detail-product-variant__brand">
                            {productVariant.brand.label}
                        </h3>
                    </Link>
                )}

                <Link to={productUrl} className="order-detail-product-variant__product-link">
                    <h2 className="order-detail-product-variant__name">
                        {productVariant.productName}
                    </h2>
                </Link>

                <Price
                    amount={productVariant.priceTotal}
                    className="order-detail-product-variant__price"
                />
            </div>
        </div>
    );
};
