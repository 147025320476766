import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { LogoBroken, LogoDefault } from './set';

import './Logo.scss';

const logos = {
    broken: LogoBroken,
    default: LogoDefault,
};

export type LogoName = keyof typeof logos;

export interface LogoProps extends RefAttributes<SVGSVGElement> {
    name?: LogoName;
    className?: string;
}

export const Logo: ForwardRefExoticComponent<LogoProps> = forwardRef(({
    name = 'default',
    className = '',
}, ref: Ref<SVGSVGElement>): ReactElement | null => {
    const LogoComponent = logos[name];

    if (!LogoComponent) {
        return null;
    }

    return (
        <LogoComponent
            ref={ref}
            className={className}
        />
    );
});
