import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './SliderSectionSkeletons.scss';

interface SliderSectionSkeletonsProps {
    numberOfSkeletons?: number;
}

export const SliderSectionSkeletons: FC<SliderSectionSkeletonsProps> = ({
    numberOfSkeletons = 6,
}): ReactElement => {
    const sliderSectionSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <Wrapper>
            <div className="slider-section-skeletons__title-wrapper">
                <Skeleton className="slider-section-skeletons__title" />

                <Skeleton className="slider-section-skeletons__line" />
            </div>

            <div className="slider-section-skeletons__content">
                <ul className="slider-section-skeletons__list">
                    {sliderSectionSkeletons.map(skeleton => (
                        <li key={skeleton} className="slider-section-skeletons__item">
                            <div className="slider-section-skeletons__item-image" />

                            <Skeleton className="slider-section-skeletons__item-title" />
                        </li>
                    ))}
                </ul>
            </div>
        </Wrapper>
    );
};
