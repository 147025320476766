import { FC, ReactElement } from 'react';

import { Price } from '../../../../components';
import { Accordion, CouponInput } from '../../../../compositions';
import { useTrans } from '../../../../hooks';

import './Pricing.scss';

interface PricingProps {
    subTotal: number;
    total: number;
    discount: number;
    className?: string;
}

export const Pricing: FC<PricingProps> = ({
    subTotal,
    total,
    discount,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`pricing ${className}`}>
            <div className="pricing__price-item">
                <p>
                    {trans('containers.checkout.pricing.subTotal')}
                </p>

                <Price
                    includeCurrencySymbol
                    amount={subTotal}
                />
            </div>

            <div className="pricing__price-item">
                <p>
                    {trans('containers.checkout.pricing.discount')}
                </p>

                <Price
                    includeCurrencySymbol
                    amount={discount}
                    className="pricing__price--is-sale"
                />
            </div>

            <Accordion
                header={trans('containers.checkout.pricing.discountCode')}
                className="pricing__accordion"
                headingClassName="pricing__accordion-heading"
                contentClassName="pricing__accordion-content"
            >
                <CouponInput
                    label={trans('containers.checkout.pricing.discountCode')}
                    buttonLabel={trans('common.apply')}
                    placeholder={trans('containers.checkout.pricing.discountCode')}
                    tooltip={trans('containers.checkout.pricing.couponCodeTooltip')} // TODO: Change explanation when feature is available (L5W-250)
                    onChange={console.log} // TODO: Handle discountCode (L5W-250)
                    onSubmit={console.log} // TODO: Handle discountCode (L5W-250)
                    className="pricing__coupon-input"
                />
            </Accordion>

            <div className="pricing__price-item">
                <p>
                    {trans('containers.checkout.pricing.shippingCosts')}
                </p>

                <p className="pricing__price--is-sale">
                    {trans('common.free')}
                </p>
            </div>

            <div className="pricing__price-item">
                <p className="pricing__price-label-total">
                    {trans('containers.checkout.pricing.total')}
                </p>

                <Price
                    includeCurrencySymbol
                    amount={total}
                    className="pricing__price-total"
                />
            </div>
        </div>
    );
};
