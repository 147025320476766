import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Logo, Wrapper } from '../../../../components';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useDeviceWidth, useTrans } from '../../../../hooks';

import './FooterBottom.scss';

interface FooterBottomProps {
    className?: string;
}

export const FooterBottom: FC<FooterBottomProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const currentYear = new Date().getFullYear();

    return (
        <div className={`footer-bottom ${className}`}>
            <Wrapper className="footer-bottom__wrapper">

                <div className="footer-bottom__content footer-bottom__content-left">
                    {isMobile && (
                        <Link
                            title={trans('containers.footer.logoLabel')}
                            aria-label={trans('containers.footer.logoLabel')}
                            to={trans(appRoutes[AppRoute.home].path)}
                            className="footer-bottom__logo-wrapper"
                        >
                            <Logo className="footer-bottom__logo" />
                        </Link>
                    )}

                    <p className="footer-bottom__text footer-bottom__since">
                        {trans('containers.footer.since')}
                    </p>

                    <p className="footer-bottom__text footer-bottom__year">
                        {`© ${currentYear}`}
                    </p>
                </div>

                {!isMobile && (
                    <Link
                        title={trans('containers.footer.logoLabel')}
                        aria-label={trans('containers.footer.logoLabel')}
                        to={trans(appRoutes[AppRoute.home].path)}
                        className="footer-bottom__logo-wrapper"
                    >
                        <Logo className="footer-bottom__logo" />
                    </Link>
                )}

                <div className="footer-bottom__content footer-bottom__content-right">
                    <Link to={trans(appRoutes[AppRoute.legalPrivacy].path)} className="footer-bottom__link">
                        {trans(appRoutes[AppRoute.legalPrivacy].label)}
                    </Link>

                    <Link to={trans(appRoutes[AppRoute.legalDisclaimer].path)} className="footer-bottom__link">
                        {trans(appRoutes[AppRoute.legalDisclaimer].label)}
                    </Link>

                    <Link to={trans(appRoutes[AppRoute.legalCookies].path)} className="footer-bottom__link">
                        {trans(appRoutes[AppRoute.legalCookies].label)}
                    </Link>
                </div>
            </Wrapper>
        </div>
    );
};
