import { generateUuid } from '../../helpers/string';
import trans from '../../helpers/trans';
import { AddressResource } from '../@api/Sylius';
import {
    Address,
    AddressType,
    CustomerAddress,
    StrapiAddressResource,
} from './Address';

export const transformToAddressId = (fullAddressId: string): string => fullAddressId.replace('api/v2/shop/addresses/', '');

export const transformToAddress = (resource: AddressResource): Address => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const countryCode = resource.countryCode.toLowerCase();
    const country = trans(`entities.country.${countryCode}`);

    return {
        id,
        label: '', // TODO: Implement address label
        street: resource.street,
        postalCode: resource.postcode,
        houseNumber: resource.number,
        addition: resource.addition || '',
        city: resource.city,
        country,
    };
};

export const transformToCustomerAddress = (resource: AddressResource, type?: AddressType): CustomerAddress => {
    const address = transformToAddress(resource);

    return {
        ...address,
        type: type || AddressType.billing,
    };
};

export const transformStrapiAddressToAddress = (resource: StrapiAddressResource): Address => {
    const geoCoords = resource.latitude && resource.longitude
        ? {
            latitude: resource.latitude,
            longitude: resource.longitude,
        }
        : undefined;

    return {
        id: generateUuid(),
        label: '', // TODO: Implement address label
        street: resource.streetName,
        postalCode: resource.zipCode,
        houseNumber: resource.houseNumber,
        addition: '',
        city: resource.city,
        country: resource.country,
        geoCoords,
    };
};
