import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './OrderOverviewSkeletons.scss';

interface OrderOverviewSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const OrderOverviewSkeletons: FC<OrderOverviewSkeletonsProps> = memo(({
    numberOfSkeletons = 6,
    className = '',
}): ReactElement => {
    const orderCardSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <section className={`order-overview-skeletons ${className}`}>
            <ul className="order-overview-skeletons__grid">
                {orderCardSkeletons.map(order => {
                    const numberOfProductImageSkeletons = randomInBetweenValue(1, 4);
                    const productImageSkeletons = generateIdArray(numberOfProductImageSkeletons);

                    return (
                        <li key={order} className="order-overview-skeletons__card">
                            <div className="order-overview-skeletons__product-image-grid">
                                {productImageSkeletons.map(productImage => (
                                    <div key={productImage} className="order-overview-skeletons__product-image-item">
                                        <div className="order-overview-skeletons__product-image" />
                                    </div>
                                ))}
                            </div>

                            <div className="order-overview-skeletons__info-wrapper">
                                <Skeleton className="order-overview-skeletons__order-number" />
                                <Skeleton className="order-overview-skeletons__order-date" />
                                <Skeleton className="order-overview-skeletons__order-total" />

                                <div className="order-overview-skeletons__link-button" />
                            </div>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
});
