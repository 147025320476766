import { FC, ReactElement } from 'react';

import { Index } from 'react-instantsearch';

import { Page } from '../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet, ConnectedProductList } from '../../connectors';
import { MeilisearchAttributes } from '../../entities/@api/Sylius';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../entities/Routing/Routing';

import './SalePage.scss';

interface SalePageProps {
    className?: string;
}

export const SalePage: FC<SalePageProps> = ({
    className = '',
}): ReactElement => {
    const query = generateFilterQuery(MeilisearchAttributes.isSale, 'true');

    return (
        <Page className={`sale-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.sale} />

            <ConnectedBlockRenderer
                appRoute={AppRoute.sale}
                className="sale-page__block"
            />

            <Index indexName={MeilisearchIndex.variantsNl}>
                <ConnectedProductList
                    showFilterBar
                    filterQuery={query}
                    className="sale-page__block"
                />
            </Index>
        </Page>
    );
};
