import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchSpace } from '../../../redux/space/spaceActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductCategoryChildProps {
    ssrQuery?: string;
}

interface ConnectedSpaceDetailWrapperProps {
    children: (childProps: ConnectedProductCategoryChildProps) => ReactElement;
}

export const ConnectedSpaceDetailWrapper: FC<ConnectedSpaceDetailWrapperProps> = ({
    children,
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.spaceSlice.hasFetched);
    const ssrSpaceQuery = useTypedSelector(state => state.spaceSlice.ssrSpaceQuery);
    const space = useTypedSelector(state => state.spaceSlice.space);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchSpace(slug));
        }
    }, [slug]);

    if (hasFetched && !space) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    return (
        <div>
            {children({
                ssrQuery: ssrSpaceQuery,
            })}
        </div>
    );
};
