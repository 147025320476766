import {
    AddVariantToWishlistResponse,
    authorizedSyliusFetch,
    CreateWishlistResponse,
    DeleteVariantFromWishlistResponse,
    GetWishlistResponse,
    syliusCustomerOrGuestFetch,
    syliusFetch,
} from '../../entities/@api/Sylius';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { Wishlist, wishlistToken } from '../../entities/Wishlist/Wishlist';
import { transformToWishlist } from '../../entities/Wishlist/WishlistTransformers';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
    setWishlist,
} from './wishlistSlice';

export const addProductVariantToWishlist: ReduxFetchAction<string> = productVariantId => async (dispatch, getState) => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { wishlistSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { wishlist } = wishlistSlice;

        const body = { productVariantId: Number(productVariantId) };

        const addProductVariantToWishlistResponse = await syliusCustomerOrGuestFetch<AddVariantToWishlistResponse>(
            customer?.id,
            `/shop/wishlists/${wishlist.id}/variant`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify(body),
            },
        );

        if (!isFetchResultSuccessful(addProductVariantToWishlistResponse)) {
            dispatch(setError(addProductVariantToWishlistResponse.error));
            return;
        }

        const wishlistResource = addProductVariantToWishlistResponse.data;
        const transformedWishlist = transformToWishlist(wishlistResource);

        dispatch(setWishlist(transformedWishlist));
        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromWishlist: ReduxFetchAction<string> = productVariantId => async (dispatch, getState) => {
    dispatch(setIsSuccessfullyRemoved(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { wishlistSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { wishlist } = wishlistSlice;

        const removeProductVariantFromWishlistResponse = await syliusCustomerOrGuestFetch<DeleteVariantFromWishlistResponse>(
            customer?.id,
            `/shop/wishlists/${wishlist.id}/productVariants/${productVariantId}`,
            {
                method: 'DELETE',
            },
        );

        if (!isFetchResultSuccessful(removeProductVariantFromWishlistResponse)) {
            dispatch(setError(removeProductVariantFromWishlistResponse.error));
            return;
        }

        const transformedWishlist: Wishlist = {
            ...wishlist,
            productVariantIds: wishlist.productVariantIds.filter(item => item !== productVariantId),
        };

        dispatch(setWishlist(transformedWishlist));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromWishlist]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const createAndSetWishlist: ReduxFetchAction = () => async dispatch => {
    const createWishlistResponse = await syliusFetch<CreateWishlistResponse>('/shop/wishlists', {
        method: 'POST',
        body: JSON.stringify({ localeCode: 'nl' }),
    });

    if (!isFetchResultSuccessful(createWishlistResponse)) {
        dispatch(setError(createWishlistResponse.error));
        return;
    }

    const createWishlistResource = createWishlistResponse.data;
    const { token = '' } = createWishlistResource;

    localStorage.setItem(wishlistToken, JSON.stringify(token));

    const wishlist = transformToWishlist(createWishlistResource);

    dispatch(setWishlist(wishlist));
};

const getAndSetWishlist: ReduxFetchAction<string> = storedWishlistToken => async dispatch => {
    const wishlistTokenValue = JSON.parse(storedWishlistToken);

    const wishlistResponse = await syliusFetch<GetWishlistResponse>(`/shop/wishlists/${wishlistTokenValue}`);

    if (!isFetchResultSuccessful(wishlistResponse)) {
        dispatch(setError(wishlistResponse.error));
        return;
    }

    const wishlistResource = wishlistResponse.data;
    const wishlist = transformToWishlist(wishlistResource);

    dispatch(setWishlist(wishlist));
};

const handleCustomerWishlist: ReduxFetchAction = () => async (dispatch, getState) => {
    try {
        const { wishlistSlice } = getState();
        const { wishlist } = wishlistSlice;

        const productVariantIdsInLocalStorage = localStorage.getItem(wishlistToken)
            ? wishlist.productVariantIds
            : [];

        localStorage.removeItem(wishlistToken);

        const wishlistResponse = await authorizedSyliusFetch<CreateWishlistResponse>('/shop/wishlists', {
            method: 'POST',
            body: JSON.stringify({ localeCode: 'nl' }),
        });

        if (!isFetchResultSuccessful(wishlistResponse)) {
            dispatch(setError(wishlistResponse.error));
            return;
        }

        const wishlistResource = wishlistResponse.data;
        const transformedWishlist = transformToWishlist(wishlistResource);

        dispatch(setWishlist(transformedWishlist));

        if (productVariantIdsInLocalStorage.length) {
            // Merge local wishlist items in wishlist of customer
            productVariantIdsInLocalStorage.map(async productVariantId => dispatch(addProductVariantToWishlist(productVariantId)));
        }
    } catch (error) {
        console.error('[handleCustomerWishlist]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const handleGuestWishlist: ReduxFetchAction = () => async dispatch => {
    const storedWishlistToken = localStorage.getItem(wishlistToken);

    if (!storedWishlistToken) {
        await dispatch(createAndSetWishlist());
    } else {
        await dispatch(getAndSetWishlist(storedWishlistToken));
    }
};

export const getWishList: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        if (customer?.id) {
            await dispatch(handleCustomerWishlist());
        } else {
            await dispatch(handleGuestWishlist());
        }
    } catch (error) {
        console.error('[getWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
