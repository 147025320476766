import { FC, ReactElement } from 'react';

import { DepartmentDetailInterface } from '../../../entities/@departments/DepartmentDetail/DepartmentDetail';
import { BlockRenderer } from '../..';
import { DepartmentDetailHelmet } from './subcomponents';

import './DepartmentDetailRenderer.scss';

interface DepartmentDetailRendererProps {
    isLoading: boolean;
    departmentDetail?: DepartmentDetailInterface;
    className?: string;
}

export const DepartmentDetailRenderer: FC<DepartmentDetailRendererProps> = ({
    isLoading,
    departmentDetail,
    className = '',
}): ReactElement => (
    <article className={`department-detail-renderer ${className}`}>
        {departmentDetail && (
            <DepartmentDetailHelmet departmentDetail={departmentDetail} />
        )}

        <BlockRenderer
            isLoading={isLoading}
            blocks={departmentDetail?.blocks}
            className="department-detail-renderer__block-renderer"
        />
    </article>
);
