import trans from '../../helpers/trans';
import { MeilisearchAttributes } from '../@api/Sylius';

export interface ProductFilter {
    attribute: MeilisearchAttributes;
    label: string;
    operator: 'and' | 'or';
}

export const productDefaultFilters: ProductFilter[] = [
    {
        attribute: MeilisearchAttributes.categories,
        label: trans('entities.productFilters.category'),
        operator: 'or',
    },
];

const categoryFilter = productDefaultFilters.find(item => item.attribute === MeilisearchAttributes.categories);

export const highlightedProductFilters: ProductFilter[] = [
    ...categoryFilter ? [categoryFilter] : [],
    {
        attribute: MeilisearchAttributes.brand,
        label: trans('entities.productFilters.brand'),
        operator: 'or',
    },
    // TODO: Replace this filter with the color and material filter (when Meilisearch indexing issue is fixed (L5W-547))
];
