import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { Brand } from '../../../entities/Brand/Brand';
import { Link } from '../../../entities/Link/Link';
import { Product } from '../../../entities/Product/Product';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { ProductDetail } from '../../ProductDetail/ProductDetail';
import { ProductInformation } from '../../ProductInformation/ProductInformation';
import { ProductDetailHelmet } from './subcomponents';

import './ProductDetailRenderer.scss';

interface ProductDetailRendererProps {
    productIsLoading: boolean;
    cartIsLoading: boolean;
    wishlistIsLoading: boolean;
    breadcrumbs: Link[];
    product?: Product;
    brand?: Brand;
    className?: string;
}

export const ProductDetailRenderer: FC<ProductDetailRendererProps> = ({
    productIsLoading,
    cartIsLoading,
    wishlistIsLoading,
    breadcrumbs,
    product,
    brand,
    className = '',
}): ReactElement => (
    <div className={`product-detail-renderer ${className}`}>
        {product && (
            <ProductDetailHelmet product={product} />
        )}

        <Wrapper>
            <Breadcrumbs
                isLoading={productIsLoading}
                breadcrumbs={breadcrumbs}
                className="product-detail-renderer__breadcrumbs"
            />
        </Wrapper>

        <Wrapper className="product-detail-renderer__detail-wrapper">
            <ProductDetail
                productIsLoading={productIsLoading}
                cartIsLoading={cartIsLoading}
                wishlistIsLoading={wishlistIsLoading}
                product={product}
            />
        </Wrapper>

        <Wrapper className="product-detail-renderer__information-wrapper">
            <ProductInformation
                isLoading={productIsLoading}
                product={product}
                brand={brand}
            />
        </Wrapper>
    </div>
);
