import { Address } from '../../Address/Address';
import { Link } from '../../Link/Link';
import { Image } from '../../Media/Media';
import { Product } from '../../Product/Product';

export interface Order {
    id: string;
    totalPrice: number;
    orderNumber: string;
    orderDate: Date;
    deliveryDate: Date;
    products: Product[];
}

export enum OrderState {
    new = 'new',
}

export interface OrderBase {
    id: string;
    tokenValue: string;
    orderNumber: string;
    state: OrderState;
    priceTotal: number;
    priceSubTotal: number;
    channel: string;
}

export interface OrderProductVariant {
    id: string;
    variantId: string;
    productName: string;
    quantity: number;
    priceUnit: number;
    priceUnitOriginal: number;
    priceUnitDiscounted: number;
    priceSubTotal: number;
    priceTotal: number;
}

export interface EnrichedOrderProductVariant extends OrderProductVariant {
    variantSlug: string;
    image: Image;
    brand?: Link;
}

export enum OrderPaymentMethod {
    paynl = 'paynl',
}

export interface OrderPayment {
    id: string;
    method: OrderPaymentMethod;
}

export enum OrderShipmentMethod {
    ups = 'ups',
}

export interface OrderShipment {
    id: string;
    method: OrderShipmentMethod;
}

export enum CheckoutState {
    completed = 'completed',
}

export enum PaymentState {
    paid = 'paid',
}

export enum ShippingState {
    ready = 'ready',
}

export interface OrderDetailInterface {
    id: string;
    tokenValue: string;
    orderNumber: string;
    productVariants: OrderProductVariant[];
    currencyCode: string;
    localeCode: string;
    checkoutState: CheckoutState;
    paymentState: PaymentState;
    shippingState: ShippingState;
    priceItemsSubTotal: number;
    priceItemsTotal: number;
    priceTaxTotal: number;
    priceShippingTotal: number;
    priceShippingTaxTotal: number;
    priceTaxExcludedTotal: number;
    priceTaxIncludedTotal: number;
    priceOrderPromotionTotal: number;
    priceShippingPromotionTotal: number;
    priceTotal: number;
    shippingAddress?: Address;
    billingAddress?: Address;
    payments: OrderPayment[];
    shipments: OrderShipment[];
}

export interface EnrichedOrderDetailInterface extends Omit<OrderDetailInterface, 'productVariants'> {
    productVariants: EnrichedOrderProductVariant[];
}
