import { strapiFetch } from '../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { transformValuesToBreadcrumbs } from '../../entities/Link/LinkTransformers';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { SpaceItemResponse } from '../../entities/Space/Space';
import { generateSpaceQuery } from '../../entities/Space/SpaceRequests';
import { transformToSpace } from '../../entities/Space/SpaceTransformers';
import trans from '../../helpers/trans';
import { ReduxFetchAction } from '../defaults';
import {
    setBreadcrumbs,
    setCategories,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setSpace,
    setSpaces,
} from './spaceSlice';

export const fetchSpaces: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setSpaces([]));

    try {
        const query = generateSpaceQuery();

        const spacesResponse = await strapiFetch<SpaceItemResponse>(`/spaces?${query}`);

        if (!isFetchResultSuccessful(spacesResponse)) {
            dispatch(setError(spacesResponse.error));
            return;
        }

        const { data: spacesData } = spacesResponse.data;

        const spaces = spacesData
            ? spacesData.map(transformToSpace)
            : undefined;

        if (spaces) {
            dispatch(setSpaces(spaces));
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchSpaces]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};

export const fetchSpace: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setSpace(undefined));

    try {
        const query = generateSpaceQuery(slug);

        const spacesResponse = await strapiFetch<SpaceItemResponse>(`/spaces?${query}`);

        if (!isFetchResultSuccessful(spacesResponse)) {
            dispatch(setError(spacesResponse.error));
            return;
        }

        const { data: spaceData } = spacesResponse.data;

        const spaceResource = spaceData
            ? spaceData[0]
            : undefined;

        const space = spaceResource
            ? transformToSpace(spaceResource)
            : undefined;

        const routePaths = [
            trans(appRoutes[AppRoute.spacesOverview].label),
            slug,
        ];

        const breadcrumbs = transformValuesToBreadcrumbs(routePaths);

        dispatch(setBreadcrumbs(breadcrumbs));

        dispatch(setSpace(space));
        dispatch(setCategories(space?.categories || []));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchSpace]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
