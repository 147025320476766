import { FC, ReactElement } from 'react';

import { noDataLabel } from '../../../../../constants';
import { OrderDetailInterface } from '../../../../../entities/@account/OrderDetail/OrderDetail';
import { convertNumberToCurrency } from '../../../../../helpers/number';
import { useTrans } from '../../../../../hooks';

import './OrderDetailPriceTable.scss';

interface OrderDetailPriceTableProps {
    order: OrderDetailInterface;
    className?: string;
}

export const OrderDetailPriceTable: FC<OrderDetailPriceTableProps> = ({
    order,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <table className={`order-detail-price-table ${className}`}>
            <tbody className="order-detail-price-table__body">
                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceProductTotal', {
                            numberOfProducts: order.productVariants.length,
                        })}
                    </td>

                    <td className="order-detail-price-table__cell">
                        {convertNumberToCurrency(order.priceItemsSubTotal, true)}
                    </td>
                </tr>

                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceInvoiceTotal')}
                    </td>

                    <td className="order-detail-price-table__cell">
                        {convertNumberToCurrency(order.priceItemsTotal, true)}
                    </td>
                </tr>

                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceRefundTotal')}
                    </td>

                    <td className="order-detail-price-table__cell">
                        {noDataLabel}
                    </td>
                </tr>
            </tbody>

            <tfoot className="order-detail-price-table__footer">
                <tr className="order-detail-price-table__row">
                    <td className="order-detail-price-table__cell">
                        {trans('containers.orderDetailRenderer.order.priceTotal')}
                    </td>

                    <td className="order-detail-price-table__cell">
                        {convertNumberToCurrency(order.priceTotal, true)}
                    </td>
                </tr>
            </tfoot>
        </table>
    );
};
