import { CSSProperties, FC, ReactElement } from 'react';

import { Picture, SafeHtml } from '../../../components';
import { LinkIconButton, VideoTheatre } from '../../../compositions';
import { HeaderInterface } from '../../../entities/@blocks/Header/Header';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { Link } from '../../../entities/Link/Link';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';

import './Header.scss';

interface HeaderProps extends HeaderInterface {
    breadcrumbs?: Link[];
    backLink?: Link;
    className?: string;
}

export const Header: FC<HeaderProps> = ({
    breadcrumbs,
    backLink,
    accentColour,
    title,
    subTitle,
    introHtml,
    image,
    video,
    videoPlaysInline,
    className = '',
}): ReactElement => {
    const cssVariables = {
        '--c-accent': accentColour,
    } as CSSProperties;

    return (
        <header style={cssVariables} className={`header ${className}`}>
            <div className="header__wrapper">
                <div className="header__media-wrapper">
                    {!video && image && (
                        <Picture
                            {...image}
                            className="header__picture"
                            imageClassName="header__image"
                        />
                    )}

                    {video && (
                        <VideoTheatre
                            {...video}
                            playsInline={videoPlaysInline}
                            poster={image}
                            className="header__video-theatre"
                        />
                    )}
                </div>

                <div className="header__text-wrapper">
                    <div className="header__text-inner-wrapper">
                        {breadcrumbs && (
                            <Breadcrumbs
                                breadcrumbs={breadcrumbs}
                                className="header__breadcrumbs"
                            />
                        )}

                        {backLink && (
                            <LinkIconButton
                                to={backLink.href}
                                icon="chevron-left"
                                iconPos={HorizontalAlignment.left}
                                text={backLink.label}
                                target={backLink.target}
                                className="header__back-button"
                                labelClassName="header__back-button-label"
                                iconClassName="header__back-button-icon"
                            />
                        )}

                        <div className="header__title-wrapper">
                            <h1 className="header__title">
                                {title}
                            </h1>

                            {subTitle && (
                                <h2 className="header__sub-title">
                                    {subTitle}
                                </h2>
                            )}
                        </div>

                        {introHtml && (
                            <SafeHtml
                                html={introHtml}
                                className="header__intro"
                            />
                        )}
                    </div>
                </div>
            </div>
        </header>
    );
};
