import { FC, ReactElement } from 'react';

import './AccountLabelledValue.scss';

interface AccountLabelledValueProps {
    label: string;
    value: string;
    className?: string;
}

export const AccountLabelledValue: FC<AccountLabelledValueProps> = ({
    label,
    value,
    className = '',
}): ReactElement => (
    <div className={`account-labelled-value ${className}`}>
        <h3 className="account-labelled-value__label">
            {label}
        </h3>

        <p className="account-labelled-value__value">
            {value}
        </p>
    </div>
);
