import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../../../compositions';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { Store } from '../../../../../entities/Store/Store';
import { generateBreadcrumbStructuredData } from '../../../../../helpers/structuredData';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

interface StoreDetailHelmetProps {
    store: Store;
}

export const StoreDetailHelmet: FC<PropsWithChildren<StoreDetailHelmetProps>> = ({
    store,
    children,
}): ReactElement => {
    const trans = useTrans();

    const siteUrl = trans('company.url');

    const metaTitle = store.seo
        ? store.seo.title
        : store.title;

    const metaDescription = store.seo
        ? store.seo.description
        : store.description;

    const metaKeywords = store.seo
        ? store.seo.keywords
        : undefined;

    const metaImage = store.seo
        ? store.seo.image
        : store.image;

    const departmentDetailPath = trans(appRoutes[AppRoute.departmentDetail].path);
    const departmentDetailUrl = replaceUrlParamKeysWithValues(departmentDetailPath, {
        slug: store.slug,
    });

    const breadcrumbs = generateBreadcrumbStructuredData(siteUrl, [
        {
            label: trans(appRoutes[AppRoute.storesOverview].label),
            href: trans(appRoutes[AppRoute.storesOverview].path),
        },
        {
            label: trans(appRoutes[AppRoute.storesDepartments].label),
            href: trans(appRoutes[AppRoute.storesDepartments].path),
        },
        {
            label: store.title,
            href: departmentDetailUrl,
        },
    ]);

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            image={metaImage}
            canonical={store.seo?.canonicalURL}
        >

            <script type="application/ld+json">
                {breadcrumbs}
            </script>

            {children}
        </DefaultHelmet>
    );
};
