import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { NotFound } from '../../containers';

import './NotFoundPage.scss';

export const NotFoundPage: FC = (): ReactElement => (
    <Page className="not-found-page">
        <NotFound />
    </Page>
);
