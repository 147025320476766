import { AddressResource } from './Address';
import { BaseResource } from './BaseResource';
import { OrderItemResource } from './OrderItem';

export enum OrderPaymentMethodResource {
    paynl = 'paynl',
}

export interface OrderPaymentResource extends BaseResource {
    method: OrderPaymentMethodResource;
}

export enum OrderShipmentMethodResource {
    ups = 'ups',
}

export interface OrderShipmentResource extends BaseResource {
    method: OrderShipmentMethodResource;
}

export enum CheckoutStateResource {
    completed = 'completed',
}

export enum PaymentStateResource {
    paid = 'paid',
}

export enum ShippingStateResource {
    ready = 'ready',
}

export interface OrderBaseResource extends BaseResource {
    readonly channel: string;
    readonly itemsTotal: number;
    readonly itemsSubtotal: number;
    readonly number?: string | null;
    readonly state?: string | null;
    readonly tokenValue?: string | null;
}

export interface OrderResource extends OrderBaseResource {
    isWebshop: boolean;
    shippingAddress?: AddressResource | null;
    billingAddress?: AddressResource | null;
    readonly payments: OrderPaymentResource[];
    readonly shipments: OrderShipmentResource[];
    currencyCode: string;
    localeCode: string;
    checkoutState: CheckoutStateResource; // @TODO: Determine possible values
    readonly paymentState: PaymentStateResource; // @TODO: Determine possible values
    readonly shippingState: ShippingStateResource; // @TODO: Determine possible values
    items: OrderItemResource[];
    readonly total: number;
    readonly taxTotal: number;
    readonly shippingTaxTotal: number;
    readonly taxExcludedTotal: number;
    readonly taxIncludedTotal: number;
    readonly shippingTotal: number;
    readonly orderPromotionTotal: number;
    readonly shippingPromotionTotal: number;
}
