import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../compositions';
import { PageInterface } from '../../../entities/Page/Page';

interface PageHelmetProps {
    page: PageInterface;
}

export const PageHelmet: FC<PropsWithChildren<PageHelmetProps>> = ({
    page,
    children,
}): ReactElement => {
    const metaTitle = page.seo
        ? page.seo.title
        : page.title;

    const metaDescription = page.seo
        ? page.seo.description
        : page.description;

    const metaKeywords = page.seo
        ? page.seo.keywords
        : undefined;

    const metaImage = page.seo
        ? page.seo.image
        : undefined;

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            image={metaImage}
            canonical={page.seo?.canonicalURL}
        >
            {children}
        </DefaultHelmet>
    );
};
