import { strapiFetch } from '../../entities/@api/Strapi';
import { GetTaxonResponse, syliusFetch } from '../../entities/@api/Sylius';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { ProductCategoryParams, ProductCategoryResponse } from '../../entities/ProductCategory/ProductCategory';
import { generateProductCategoryQuery } from '../../entities/ProductCategory/ProductCategoryRequests';
import { generateProductCategoryBreadcrumbs, getProductCategoryReplacementUrl } from '../../entities/ProductCategory/ProductCategoryService';
import { transformTaxonToProductCategory, transformToProductCategoryPageData } from '../../entities/ProductCategory/ProductCategoryTransformers';
import { transformToSlug } from '../../helpers/string';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setMainProductCategory,
    setProductCategoryBreadcrumbs,
    setProductCategoryPageData,
    setProductCategoryReplacementUrl,
} from './productCategorySlice';

export const fetchMainProductCategory: ReduxFetchAction<string> = categorySlug => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setHasFetched(false));
    dispatch(setMainProductCategory(undefined));

    try {
        const slug = transformToSlug(categorySlug);

        const taxonResponse = await syliusFetch<GetTaxonResponse>(`/shop/taxons/${slug}`);

        if (!isFetchResultSuccessful(taxonResponse)) {
            dispatch(setError(taxonResponse.error));
            return;
        }

        const taxonResource = taxonResponse.data['hydra:member'];
        const category = transformTaxonToProductCategory(taxonResource);

        dispatch(setMainProductCategory(category));
    } catch (error) {
        console.error('[fetchMainProductCategory]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};

export const fetchProductCategoryPageData: ReduxFetchAction<ProductCategoryParams> = categoryParams => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setHasFetched(false));
    dispatch(setProductCategoryPageData(undefined));

    const { slug = '', subCategory, subSubCategory } = categoryParams;

    try {
        const query = generateProductCategoryQuery(subSubCategory || subCategory || slug);

        const productCategoryResponse = await strapiFetch<ProductCategoryResponse>(`/categories?${query}`);

        if (!isFetchResultSuccessful(productCategoryResponse)) {
            dispatch(setError(productCategoryResponse.error));
            return;
        }

        const breadcrumbs = generateProductCategoryBreadcrumbs(categoryParams);

        const { data: productCategoryData } = productCategoryResponse.data;

        const productCategoryResource = productCategoryData
            ? productCategoryData[0]
            : undefined;

        const productCategoryPageData = productCategoryResource
            ? transformToProductCategoryPageData(productCategoryResource)
            : undefined;

        const { productCategorySlice } = getState();
        const { mainProductCategory } = productCategorySlice;

        const replacementUrl = mainProductCategory
            ? getProductCategoryReplacementUrl(mainProductCategory, categoryParams)
            : undefined;

        dispatch(setProductCategoryReplacementUrl(replacementUrl));
        dispatch(setProductCategoryBreadcrumbs(breadcrumbs));
        dispatch(setProductCategoryPageData(productCategoryPageData));
    } catch (error) {
        console.error('[fetchProductCategoryPageData]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
