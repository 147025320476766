import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../components';
import { LinkIconButton } from '../../../compositions';
import { ConnectedOrderDetailRenderer } from '../../../connectors';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';

import './AccountOrderDetailPage.scss';

export const AccountOrderDetailPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="account-order-detail-page">
            <Wrapper className="account-order-detail-page__wrapper">
                <LinkIconButton
                    isSmall
                    to={trans(appRoutes[AppRoute.accountOrderOverview].path)}
                    icon="chevron-left"
                    iconPos={HorizontalAlignment.left}
                    text={trans('pages.accountOrderDetail.backButtonText')}
                    className="account-order-detail-page__back-button"
                    iconClassName="account-order-detail-page__back-button-icon"
                />

                <ConnectedOrderDetailRenderer className="account-order-detail-page__renderer" />
            </Wrapper>
        </Page>
    );
};
