import { FC, PropsWithChildren, ReactElement } from 'react';

import classNames from 'classnames';

import './AccountInfoBlock.scss';

interface AccountInfoBlockProps extends PropsWithChildren {
    hasBorder?: boolean;
    title: string;
    className?: string;
}

export const AccountInfoBlock: FC<AccountInfoBlockProps> = ({
    hasBorder,
    title,
    className = '',
    children,
}): ReactElement => {
    const accountInfoBlockClassNames = classNames('account-info-block', {
        'account-info-block--has-border': hasBorder,
    }, className);

    return (
        <div className={accountInfoBlockClassNames}>
            <h2 className="account-info-block__title">
                {title}
            </h2>

            {children}
        </div>
    );
};
