export interface RegistrationEmailFormData {
    email: string;
}

export interface RegistrationAccountDetailsFormData {
    firstName: string;
    lastName: string;
    password: string;
    countryCode: string;
    street: string;
    number: string;
    addition: string;
    city: string;
    postcode: string;
    confirmPassword?: string; // TODO: Unmapped
    subscribedToNewsletter: boolean;
    acceptTermsAndConditions?: boolean; // TODO: Unmapped
}

export type RegistrationFormData = RegistrationEmailFormData & RegistrationAccountDetailsFormData;

export enum RegistrationFormStep {
    email = 'email',
    accountDetails = 'accountDetails',
}
