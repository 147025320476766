import { FC, ReactElement } from 'react';

import { CartItem } from '../../../../compositions';
import { UspItem } from '../../../../entities/@blocks/UspSection/UspSection';
import { OrderInterface } from '../../../../entities/Order/Order';
import { useTrans } from '../../../../hooks';
import { PaymentMethods } from '../PaymentMethods/PaymentMethods';
import { Pricing } from '../Pricing/Pricing';

import './CartSection.scss';

interface CartSectionProps extends OrderInterface {
    isLoading: boolean;
    uspItems: UspItem[];
    className?: string;
}

export const CartSection: FC<CartSectionProps> = ({
    isLoading,
    items,
    subTotalPrice,
    totalPrice,
    discount,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`cart-section ${className}`}>
            <h2 className="cart-section__heading">
                {trans('containers.checkout.startStep.yourOrder')}
            </h2>

            <div className="cart-section__products-wrapper">
                {items.map(item => (
                    <CartItem
                        {...item}
                        key={item.id}
                        showBigRemoveButton
                        isLoading={isLoading}
                        imageClassName="cart-section__product-image"
                    />
                ))}
            </div>

            <Pricing
                subTotal={subTotalPrice}
                total={totalPrice}
                discount={discount}
                className="cart-section__prices-wrapper"
            />

            <PaymentMethods />
        </div>
    );
};
