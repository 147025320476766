import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { OrderOverview } from '../../../containers';
import { OrderOverviewInterface, OrderOverviewType } from '../../../entities/@account/OrderOverview/OrderOverview';
import { paginationPageParameter } from '../../../entities/Pagination/Pagination';
import { fetchOnlineOrderOverview } from '../../../redux/@account/orderOverview/orderOverviewActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrderOverviewProps {
    showPagination?: boolean;
    overviewType: OrderOverviewType;
    pageSize?: number;
    className?: string;
}

export const ConnectedOrderOverview: FC<ConnectedOrderOverviewProps> = ({
    showPagination,
    overviewType,
    pageSize = 12,
    className = '',
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useTypedDispatch();

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    const isLoading = useTypedSelector(state => state.orderOverviewSlice.isLoading);
    const onlineOrderOverview = useTypedSelector(state => state.orderOverviewSlice.onlineOrderOverview);
    const inStoreOrderOverview = useTypedSelector(state => state.orderOverviewSlice.inStoreOrderOverview);

    const initialActivePage = searchParams.get(paginationPageParameter) ? Number(searchParams.get(paginationPageParameter)) : 1;
    const [activePage, setActivePage] = useState<number>(initialActivePage);

    const orderOverview = useMemo((): OrderOverviewInterface | undefined => {
        if (overviewType === OrderOverviewType.inStore) {
            return inStoreOrderOverview;
        }

        return onlineOrderOverview;
    }, [overviewType, onlineOrderOverview, inStoreOrderOverview]);

    useEffect((): void => {
        if (!isAuthenticated) {
            return;
        }

        const paginationSearchParams: URLSearchParamsInit = activePage !== 1
            ? { [paginationPageParameter]: activePage.toString() }
            : {};

        setSearchParams({
            ...paginationSearchParams,
        }, {
            replace: true,
        });

        if (overviewType === OrderOverviewType.online) {
            dispatch(fetchOnlineOrderOverview({
                page: activePage,
                itemsPerPage: pageSize,
            }));
        }
    }, [activePage, overviewType, isAuthenticated]);

    return (
        <OrderOverview
            isLoading={isLoading}
            showPagination={showPagination}
            orderOverview={orderOverview}
            pageSize={pageSize}
            onPaginationChange={setActivePage}
            className={className}
        />
    );
};
