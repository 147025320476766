import { GetProductTaxonResponse, syliusFetch } from '../@api/Sylius';
import { isFetchResultSuccessful } from '../FetchResult/FetchResult';
import { TaxonEntry } from './Taxon';
import { transformTaxonToCategory, transformTaxonToEndpoint, transformTaxonToId } from './TaxonTransformers';

export const fetchTaxonEntry = async (taxon: string): Promise<TaxonEntry | undefined> => {
    const id = transformTaxonToId(taxon);
    const endpoint = transformTaxonToEndpoint(taxon);

    const taxonResponse = await syliusFetch<GetProductTaxonResponse>(endpoint);

    if (!isFetchResultSuccessful(taxonResponse)) {
        return undefined;
    }

    const taxonResource = taxonResponse.data;
    const category = transformTaxonToCategory(taxonResource.taxon);

    return [id, category];
};
