import { FC, ReactElement } from 'react';

import { Index } from 'react-instantsearch';

import { Page, Wrapper } from '../../components';
import {
    ConnectedBlockRenderer,
    ConnectedGiftcardBalanceForm,
    ConnectedPageHelmet,
    ConnectedProductList,
} from '../../connectors';
import { MeilisearchAttributes } from '../../entities/@api/Sylius';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../entities/Routing/Routing';

import './GiftcardBalancePage.scss';

interface GiftcardBalancePageProps {
    className?: string;
}

export const GiftcardBalancePage: FC<GiftcardBalancePageProps> = ({
    className = '',
}): ReactElement => {
    const query = generateFilterQuery(MeilisearchAttributes.categories, 'Cadeaukaart');

    return (
        <Page className={`giftcard-balance-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.giftcardBalance} />

            <div className="giftcard-balance-page__form-section">
                <Wrapper className="giftcard-balance-page__form-wrapper">
                    <ConnectedGiftcardBalanceForm className="giftcard-balance-page__form" />
                </Wrapper>
            </div>

            <Index indexName={MeilisearchIndex.variantsNl}>
                <ConnectedProductList
                    showFilterBar
                    filterQuery={query}
                    className="giftcard-balance-page__product-list"
                />
            </Index>

            <ConnectedBlockRenderer
                appRoute={AppRoute.giftcardBalance}
                className="giftcard-balance-page__block-renderer"
            />
        </Page>
    );
};
