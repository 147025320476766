import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ConnectedBlockRenderer, ConnectedForgotPasswordForm } from '../../connectors';
import { AppRoute } from '../../entities/Routing/Routing';

import './ForgotPasswordPage.scss';

interface ForgotPasswordPageProps {
    className?: string;
}

export const ForgotPasswordPage: FC<ForgotPasswordPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`forgot-password-page ${className}`}>
        <Wrapper className="forgot-password-page__wrapper">
            <ConnectedForgotPasswordForm
                className="forgot-password-page__forgot-password-form"
            />
        </Wrapper>

        <ConnectedBlockRenderer appRoute={AppRoute.forgotPassword} className="forgot-password-page__section" />
    </Page>
);
