import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './HeaderWithIntroSkeletons.scss';

interface HeaderWithIntroSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const HeaderWithIntroSkeletons: FC<HeaderWithIntroSkeletonsProps> = ({
    numberOfSkeletons = 3,
    className = '',
}): ReactElement => {
    const descriptionSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <Wrapper>
            <div className={`header-with-intro-skeletons ${className}`}>
                <Skeleton className="header-with-intro-skeletons__heading" />

                <div className="header-with-intro-skeletons__description">
                    {descriptionSkeletons.map(skeleton => (
                        <Skeleton key={skeleton} className="header-with-intro-skeletons__description-line" />
                    ))}
                </div>
            </div>
        </Wrapper>
    );
};
