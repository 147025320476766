import {
    AddressFormData,
    GetOrderPricesResponse,
    GetOrderResponse,
    PaymentMethodsResponse,
    StartPaymentFormData,
    StartPaymentResponse,
    syliusCustomerOrGuestFetch,
    syliusFetch,
    UpdateOrderFormData,
    UpdateOrderResponse,
} from '../../entities/@api/Sylius';
import { UpdateCartFormData } from '../../entities/@forms/GuestForm/GuestForm';
import { transformToCart } from '../../entities/Cart/CartTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { OrderInterface } from '../../entities/Order/Order';
import { transformToPaymentMethods } from '../../entities/PaymentMethod/PaymentMethodTransformers';
import trans from '../../helpers/trans';
import { ReduxFetchAction } from '../defaults';
import { addNegativeToast } from '../toast/toastActions';
import { setError, setIsLoading, setOrder } from './orderSlice';

export const updateOrderInfo: ReduxFetchAction<UpdateCartFormData> = formData => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { orderSlice, customerSlice } = getState();
        const { order } = orderSlice;
        const { customer } = customerSlice;

        const shippingAddressFirstName = formData.shippingAddress?.firstName?.length
            ? formData.shippingAddress.firstName
            : undefined;

        const addressFirstName = order.addresses?.length
            ? order.addresses[0].firstName
            : undefined;

        const firstName = shippingAddressFirstName || addressFirstName || customer?.firstName || '[firstName]';

        const shippingAddressLastName = formData.shippingAddress?.lastName?.length
            ? formData.shippingAddress.lastName
            : undefined;

        const addressLastName = order.addresses?.length
            ? order.addresses[0].lastName
            : undefined;

        const lastName = shippingAddressLastName || addressLastName || customer?.lastName || '[lastName]';

        const shippingAddress: AddressFormData = {
            firstName,
            lastName,
            phoneNumber: customer?.phoneNumber,
            countryCode: formData.shippingAddress.country,
            street: formData.shippingAddress.street,
            number: formData.shippingAddress.number,
            addition: formData.shippingAddress.addition,
            city: formData.shippingAddress.city,
            postcode: formData.shippingAddress.postalCode,
        };

        const billingAddress: AddressFormData = {
            firstName,
            lastName,
            phoneNumber: customer?.phoneNumber,
            countryCode: formData.billingAddress.country,
            street: formData.billingAddress.street,
            number: formData.billingAddress.number,
            addition: formData.billingAddress.addition,
            city: formData.billingAddress.city,
            postcode: formData.billingAddress.postalCode,
        };

        const body: UpdateOrderFormData = {
            email: formData.email,
            shippingAddress,
            billingAddress,
        };

        // Connects guest to cart and automatically creates a customer in Sylius
        const addInfoToOrderResponse = await syliusCustomerOrGuestFetch<UpdateOrderResponse>(customer?.id, `/shop/orders/${order.id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(addInfoToOrderResponse)) {
            dispatch(setError(addInfoToOrderResponse.error));
            return;
        }

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${order.id}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const addInfoToOrderResource = addInfoToOrderResponse.data;
        const pricesResource = pricesResponse.data;

        const updatedOrder = transformToCart(addInfoToOrderResource, pricesResource);

        const newOrder: OrderInterface = {
            ...updatedOrder,
            paymentMethods: order.paymentMethods,
            items: order.items,
        };

        dispatch(setOrder(newOrder));
    } catch (error) {
        console.error('[updateOrderInfo]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getPaymentMethods: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { orderSlice } = getState();
        const { order } = orderSlice;

        if (!order.id) {
            return;
        }

        const paymentMethodsResponse = await syliusFetch<PaymentMethodsResponse>(`/shop/orders/${order.id}/payment_methods`);

        if (!isFetchResultSuccessful(paymentMethodsResponse)) {
            dispatch(setError(paymentMethodsResponse.error));
            return;
        }

        const paymentMethodsResource = paymentMethodsResponse.data;

        const paymentMethods = paymentMethodsResource.methods.length
            ? paymentMethodsResource.methods.map(transformToPaymentMethods)
            : undefined;

        const updatedOrder: OrderInterface = {
            ...order,
            paymentMethods,
        };

        dispatch(setOrder(updatedOrder));
    } catch (error) {
        console.error('[getPaymentMethods]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const payOrder: ReduxFetchAction<StartPaymentFormData> = formData => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { orderSlice } = getState();
        const { order } = orderSlice;

        const payOrderResponse = await syliusFetch<StartPaymentResponse>(`/shop/orders/${order.id}/start_payment`, {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(payOrderResponse)) {
            dispatch(setError(payOrderResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: payOrderResponse.error,
            }));

            return;
        }

        const payOrderResource = payOrderResponse.data;
        const { url } = payOrderResource.payment;

        const updatedOrder: OrderInterface = {
            ...order,
            paymentEnvironmentUrl: url,
        };

        dispatch(setOrder(updatedOrder));
    } catch (error) {
        console.error('[payOrder]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const connectOrderToCustomer: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { orderSlice, customerSlice } = getState();
        const { order } = orderSlice;
        const { customer } = customerSlice;

        const connectOrderResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${order.id}`);

        if (!isFetchResultSuccessful(connectOrderResponse)) {
            dispatch(setError(connectOrderResponse.error));
        }
    } catch (error) {
        console.error('[connectOrderToCustomer]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
