import { generateUuid } from '../../helpers/string';
import { TaxonResource } from '../@api/Sylius';
import { transformToBlock } from '../Block/BlockTransformers';
import { transformToImage } from '../Media/MediaTransformers';
import { transformToProductListCallToAction } from '../ProductListCallToAction/ProductListCallToActionTransformers';
import { transformToSeo } from '../Seo/SeoTransformers';
import {
    defaultCategoryPageData,
    ProductCategory,
    ProductCategoryPageData,
    ProductCategoryResource,
} from './ProductCategory';

export const transformToProductCategory = (resource: ProductCategoryResource): ProductCategory => {
    const defaultProductCategoryPageData = defaultCategoryPageData();

    const image = resource.image
        ? transformToImage(resource.image)
        : defaultProductCategoryPageData.image;

    return {
        id: resource.sylius_id.toString(),
        name: resource.title,
        slug: resource.slug,
        image,
    };
};

export const transformTaxonToProductCategory = (resource: TaxonResource): ProductCategory => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const children = resource.children
        ? resource.children.map(transformTaxonToProductCategory)
        : [];

    const slug = resource.slug || resource.name?.toLowerCase() || '';

    return {
        id,
        slug,
        name: resource.name || '',
        children,
    };
};

export const transformToProductCategoryPageData = (resource: ProductCategoryResource): ProductCategoryPageData => {
    const defaultProductCategoryPageData = defaultCategoryPageData();

    const image = resource.image
        ? transformToImage(resource.image)
        : defaultProductCategoryPageData.image;

    const callToActions = resource.callToAction
        ? resource.callToAction.map(transformToProductListCallToAction)
        : defaultProductCategoryPageData.callToActions;

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : defaultProductCategoryPageData.blocks;

    const publishedBlocks = blocks.filter(block => block.isPublished);

    const seo = resource.seo
        ? transformToSeo(resource.seo)
        : undefined;

    return {
        id: resource.sylius_id.toString(),
        title: resource.title,
        slug: resource.slug,
        image,
        callToActions,
        blocks: publishedBlocks,
        seo,
    };
};
