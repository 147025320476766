import { FC, ReactElement } from 'react';

import { IFrameInterface } from '../../entities/@blocks/IFrame/IFrame';

import './IFrame.scss';

export interface IFrameProps extends IFrameInterface {
    className?: string;
}

export const IFrame: FC<IFrameProps> = ({
    id,
    title,
    src,
    className = '',
}): ReactElement => (
    <iframe
        id={`iframe-${id}`}
        title={title}
        src={src}
        className={`iframe ${className}`}
    />
);
