import { EnrichedOrderProductVariant } from '../../../entities/@account/OrderDetail/OrderDetail';
import { fetchEnrichedOrderProductVariant } from '../../../entities/@account/OrderDetail/OrderDetailService';
import { transformToOrderDetail, transformToOrderProductVariant } from '../../../entities/@account/OrderDetail/OrderDetailTransformers';
import { authorizedSyliusFetch, GetOrderResponse } from '../../../entities/@api/Sylius';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { ReduxFetchAction } from '../../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setOrderDetail,
} from './orderDetailSlice';

export const fetchOrderDetail: ReduxFetchAction<string> = id => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setOrderDetail(undefined));

    try {
        const orderDetailResponse = await authorizedSyliusFetch<GetOrderResponse>(`/shop/orders/${id}`);

        if (!isFetchResultSuccessful(orderDetailResponse)) {
            dispatch(setError(orderDetailResponse.error));
            return;
        }

        const orderDetailResource = orderDetailResponse.data;
        const orderDetail = transformToOrderDetail(orderDetailResource);

        const productVariants = orderDetailResource.items.map(transformToOrderProductVariant);
        const productVariantPromises = productVariants.map(fetchEnrichedOrderProductVariant);

        const resolvedProductVariantPromises = await Promise.all(productVariantPromises);
        const enrichedProductVariants = resolvedProductVariantPromises.filter(Boolean) as EnrichedOrderProductVariant[];

        const enrichedOrderDetail = {
            ...orderDetail,
            productVariants: enrichedProductVariants,
        };

        dispatch(setOrderDetail(enrichedOrderDetail));
    } catch (error) {
        console.error('[fetchOrderDetail]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
