import { FC, ReactElement } from 'react';

import { Header } from '../../../containers';
import { HeaderInterface } from '../../../entities/@blocks/Header/Header';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import { useTrans } from '../../../hooks';
import { useTypedSelector } from '../../../redux/store';

export interface ConnectedHeaderProps extends HeaderInterface {
    appRoute?: AppRoute;
    className?: string;
}

export const ConnectedHeader: FC<ConnectedHeaderProps> = ({
    appRoute,
    className = '',
    ...headerProps
}): ReactElement => {
    const trans = useTrans();

    const blogPost = useTypedSelector(state => state.blogPostSlice.blogPost);

    const blogCategoryPath = trans(appRoutes[AppRoute.blogOverviewCategory].path);
    const blogCategoryUrl = replaceUrlParamKeysWithValues(blogCategoryPath, {
        category: blogPost?.category.slug,
    });

    const backLink = blogPost && appRoute === AppRoute.blogPost
        ? {
            label: blogPost.category.title,
            href: blogCategoryUrl,
        }
        : undefined;

    return (
        <Header
            {...headerProps}
            backLink={backLink}
            className={className}
        />
    );
};
