import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BlogPost } from '../../../entities/@blog/BlogPost/BlogPost';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type BlogPostState = AsyncReduxState<{
    blogPost?: BlogPost;
}>;

const initialState: BlogPostState = {
    ...initialAsyncReduxState,
};

const blogPostSlice = createSlice({
    name: 'blogPostSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BlogPostState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): BlogPostState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BlogPostState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BlogPostState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlogPost(state, action: PayloadAction<BlogPost | undefined>): BlogPostState {
            return {
                ...state,
                blogPost: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlogPost,
} = blogPostSlice.actions;

export default blogPostSlice;
