import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Link } from '../../entities/Link/Link';
import { ProductCategory, ProductCategoryPageData } from '../../entities/ProductCategory/ProductCategory';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ProductCategoryState = AsyncReduxState<{
    mainProductCategory?: ProductCategory;
    ssrProductCategoryQuery?: string;
    productCategoryReplacementUrl?: string;
    breadcrumbs: Link[];
    pageData?: ProductCategoryPageData;
}>;

const initialState: ProductCategoryState = {
    ...initialAsyncReduxState,
    breadcrumbs: [],
};

const productCategorySlice = createSlice({
    name: 'productCategorySlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ProductCategoryState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): ProductCategoryState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ProductCategoryState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProductCategoryState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setMainProductCategory(state, action: PayloadAction<ProductCategory | undefined>): ProductCategoryState {
            return {
                ...state,
                mainProductCategory: action.payload,
            };
        },
        setSsrProductCategoryQuery(state, action: PayloadAction<string | undefined>): ProductCategoryState {
            return {
                ...state,
                ssrProductCategoryQuery: action.payload,
            };
        },
        setProductCategoryReplacementUrl(state, action: PayloadAction<string | undefined>): ProductCategoryState {
            return {
                ...state,
                productCategoryReplacementUrl: action.payload,
            };
        },
        setProductCategoryBreadcrumbs(state, action: PayloadAction<Link[]>): ProductCategoryState {
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        },
        setProductCategoryPageData(state, action: PayloadAction<ProductCategoryPageData | undefined>): ProductCategoryState {
            return {
                ...state,
                pageData: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setHasFetched,
    setMainProductCategory,
    setSsrProductCategoryQuery,
    setProductCategoryReplacementUrl,
    setProductCategoryBreadcrumbs,
    setProductCategoryPageData,
} = productCategorySlice.actions;

export default productCategorySlice;
