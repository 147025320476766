import { CSSProperties, FC, ReactElement } from 'react';

import { Picture } from '../../../components';
import { LinkIconButton, Video } from '../../../compositions';
import { MediaBannerInterface } from '../../../entities/@blocks/MediaBanner/MediaBanner';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { isSilentLoop } from '../../../entities/Media/Media';

import './MediaBanner.scss';

interface MediaBannerProps extends MediaBannerInterface {
    className?: string;
}

export const MediaBanner: FC<MediaBannerProps> = ({
    media,
    title,
    titleColor,
    subtitle,
    subtitleColor,
    link,
    linkBackgroundColor,
    className = '',
}): ReactElement => {
    const cssVariables = {
        '--c-media-banner-title': titleColor,
        '--c-media-banner-subtitle': subtitleColor,
        '--c-media-banner-button-background': linkBackgroundColor,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={`media-banner ${className}`}>
            <div className="media-banner__media-wrapper">
                {media.image && (
                    <Picture
                        {...media.image}
                        className="media-banner__picture"
                        imageClassName="media-banner__image"
                    />
                )}

                {media.video && (
                    <Video
                        {...media.video}
                        {...isSilentLoop}
                        className="media-banner__video"
                        videoClassName="media-banner__video-element"
                    />
                )}
            </div>

            <div className="media-banner__cta-wrapper">
                <div className="media-banner__cta-contents-wrapper">
                    {title && (
                        <h2 className="media-banner__title">
                            {title}
                        </h2>
                    )}

                    {subtitle && (
                        <p className="media-banner__subtitle">
                            {subtitle}
                        </p>
                    )}

                    {link && (
                        <LinkIconButton
                            hasAnimation
                            icon="arrow-right"
                            iconPos={HorizontalAlignment.right}
                            to={link.href}
                            text={link.label}
                            className="media-banner__link-button"
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
