import trans from '../../../helpers/trans';
import { FetchResult, FetchResultType } from '../../FetchResult/FetchResult';

export const strapiFetch = async <ResponseData>(endpoint: string): Promise<FetchResult<ResponseData, string>> => {
    let data: ResponseData | null;

    try {
        const apiUrl = process.env.REACT_APP_STRAPI_URL || '';

        const response = await fetch(apiUrl + endpoint, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Accept-Language': 'nl',
            },
        });

        data = await response.json();

        return {
            status: 200,
            type: FetchResultType.Success,
            data: data as ResponseData,
        };
    } catch (error: unknown) {
        console.error('[strapiFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    } finally {
        data = null;
    }
};

