import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { TabList } from '../../../../compositions';
import { ConnectedLoginForm } from '../../../../connectors';
import { CreateAccountDuringCheckoutFormData, UpdateCartFormData } from '../../../../entities/@forms/GuestForm/GuestForm';
import { CheckoutDetailsTab } from '../../../../entities/Checkout/Checkout';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';
import { GuestForm } from '../GuestForm/GuestForm';

import './DetailsStep.scss';

interface DetailsStepProps {
    isAuthenticated: boolean;
    onLoggedIn: () => void;
    onCreateAccount: (data: CreateAccountDuringCheckoutFormData) => void;
    onContinueAsGuest: (data: UpdateCartFormData) => void;
    className?: string;
}

export const DetailsStep: FC<DetailsStepProps> = ({
    isAuthenticated,
    onLoggedIn,
    onCreateAccount,
    onContinueAsGuest,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();

    const tabs = [
        { label: trans('containers.checkout.detailsStep.existingCustomer'), value: CheckoutDetailsTab.existingCustomer },
        { label: trans('containers.checkout.detailsStep.guest'), value: CheckoutDetailsTab.guest },
    ];

    const [activeTab, setActiveTab] = useState<CheckoutDetailsTab>(CheckoutDetailsTab.existingCustomer);

    useEffect((): void => {
        if (isAuthenticated) {
            navigate(trans(appRoutes[AppRoute.checkoutPayment].path));
        }
    }, [isAuthenticated]);

    const handleOrdersTabSelect = (value: string): void => {
        const selectedTab = value as CheckoutDetailsTab;

        setActiveTab(selectedTab);
    };

    return (
        <div className={`details-step ${className}`}>
            <h2 className="details-step__heading">
                {trans('containers.checkout.detailsStep.heading')}
            </h2>

            <TabList
                tabs={tabs}
                activeTab={activeTab}
                onTabSelect={handleOrdersTabSelect}
                className="details-step__tabs"
                tabItemLabelClassName="details-step__tabs-item-label"
            />

            {activeTab === CheckoutDetailsTab.existingCustomer && (
                <ConnectedLoginForm onLoggedIn={onLoggedIn} />
            )}

            {activeTab === CheckoutDetailsTab.guest && (
                <GuestForm
                    onSubmit={onCreateAccount}
                    onContinueAsGuest={onContinueAsGuest}
                />
            )}
        </div>
    );
};
