import { generateUuid } from '../../helpers/string';
import { ProductResource } from '../@api/Sylius';
import { defaultMediaItem, transformProductImageToMediaItem } from '../Media/MediaTransformers';
import { productVariantSeparator } from '../Product/Product';
import { ProductDefault } from './ProductDefault';

export const transformToProductDefault = (resource: ProductResource): ProductDefault => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const name = resource.name || '';

    const mediaItems = resource.images.length
        ? resource.images.map(image => transformProductImageToMediaItem(image, name))
        : [defaultMediaItem()];

    return {
        id,
        slug: resource.slug || '',
        name,
        shortDescription: resource.shortDescription || '',
        description: resource.description || '',
        mediaItems,
        variants: resource.variants.length,
        productCode: resource.code,
        variantSlug: resource.slug + productVariantSeparator + resource.code,
    };
};
