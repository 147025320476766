import qs, { ParsedQs } from 'qs';

import { isSSR } from '../../helpers';
import { strapiBlockPopulates } from '../@api/Strapi';
import { PageDraftConfig, PageType } from './Page';

export const getPageDraftParams = (queryParams?: ParsedQs): PageDraftConfig => {
    const clientSearchParams = !isSSR
        ? new URLSearchParams(window.location.search)
        : undefined;

    const status = isSSR
        ? queryParams?.status?.toString()
        : clientSearchParams?.get('status')?.toString();

    const previewKey = isSSR
        ? queryParams?.previewKey?.toString()
        : clientSearchParams?.get('previewKey')?.toString();

    return { status, previewKey };
};

export const generatePageQuery = (type: PageType, draftParams?: PageDraftConfig): string => {
    const filters = {
        fixedType: {
            $eq: type,
        },
    };

    const populate = {
        elements: strapiBlockPopulates,
        seo: { populate: '*' },
    };

    return qs.stringify({
        ...draftParams,
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};

export const generateCustomPageQuery = (slug: string, draftParams?: PageDraftConfig): string => {
    const filters = {
        slug: {
            $eq: slug.slice(1),
        },
    };

    const populate = {
        elements: strapiBlockPopulates,
        seo: { populate: '*' },
    };

    return qs.stringify({
        ...draftParams,
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};

export const generateCustomPagesQuery = (page: number, draftParams?: PageDraftConfig): string => {
    const pagination = {
        page,
        pageSize: 100,
    };

    return qs.stringify({
        ...draftParams,
        pagination,
    }, {
        encodeValuesOnly: true,
    });
};
