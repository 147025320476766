import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Wrapper } from '../../components';
import { BrandHeaderInterface } from '../../entities/@blocks/BrandHeader/BrandHeader';
import { useTrans } from '../../hooks';
import { BrandHeaderSkeletons } from './skeletons';

import './BrandHeader.scss';

export interface BrandHeaderProps extends BrandHeaderInterface {
    isLoading?: boolean;
    className?: string;
}

export const BrandHeader: FC<BrandHeaderProps> = ({
    isLoading,
    brand,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const descriptionClassNames = classNames('brand-header__description', {
        'brand-header__description--has-image': brand?.logo,
    }, className);

    return (
        <section className={`brand-header ${className}`}>
            {isLoading && (
                <BrandHeaderSkeletons />
            )}

            {!isLoading && brand && (
                <Wrapper className="brand-header__wrapper">
                    <h1 className="brand-header__heading">
                        {trans('pages.brands.exploreCollectionOf', {
                            brand: brand.name,
                        })}
                    </h1>

                    <div className="brand-header__content-wrapper">
                        {brand.logo && (
                            <Picture
                                {...brand.logo}
                                className="brand-header__logo"
                            />
                        )}

                        {brand.description && (
                            <p className={descriptionClassNames}>
                                {brand.description}
                            </p>
                        )}
                    </div>
                </Wrapper>
            )}
        </section>
    );
};
