import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { CreateAccountDuringCheckoutFormData, UpdateCartFormData } from '../../entities/@forms/GuestForm/GuestForm';
import { PaymentStepFormData } from '../../entities/@forms/PaymentForm/PaymentForm';
import { Address } from '../../entities/Address/Address';
import { CheckoutTab } from '../../entities/Checkout/Checkout';
import { ExtendedOrderInterface } from '../../entities/Order/Order';
import { PaymentMethod } from '../../entities/PaymentMethod/PaymentMethod';
import {
    CartSection,
    DetailsStep,
    PaymentStep,
    StartStep,
} from './subcomponents';

import './Checkout.scss';

interface CheckoutProps extends ExtendedOrderInterface {
    isLoading: boolean;
    tab?: CheckoutTab;
    isAuthenticated: boolean;
    addresses: Address[];
    paymentMethods: PaymentMethod[];
    uspItems: UspItem[];
    onLoggedIn: () => void;
    onContinueAsGuest: (data: UpdateCartFormData) => void;
    onCreateAccount: (data: CreateAccountDuringCheckoutFormData) => void;
    onGoToPay: (data: PaymentStepFormData) => void;
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onClearCart: () => void;
    className?: string;
}

export const Checkout: FC<CheckoutProps> = ({
    isLoading,
    tab,
    isAuthenticated,
    addresses,
    paymentMethods,
    uspItems,
    id,
    items,
    subTotalPrice,
    totalPrice,
    discount,
    onLoggedIn,
    onContinueAsGuest,
    onCreateAccount,
    onGoToPay,
    onUpdateCartItem,
    onRemoveItemFromCart,
    onClearCart,
    className = '',
}): ReactElement => {
    const activeCheckout = tab !== CheckoutTab.order;

    const checkoutClassNames = classNames('checkout', {
        'checkout-layout': activeCheckout,
    }, className);

    const wrapperClassNames = classNames('checkout__wrapper', {
        'checkout__wrapper--has-grid-layout': activeCheckout,
    });

    return (
        <div className={checkoutClassNames}>
            <div className={wrapperClassNames}>
                {tab === CheckoutTab.order && (
                    <StartStep
                        isAuthenticated={isAuthenticated}
                        isLoading={isLoading}
                        id={id}
                        paymentMethods={paymentMethods}
                        uspItems={uspItems}
                        items={items}
                        subTotalPrice={subTotalPrice}
                        totalPrice={totalPrice}
                        onRemoveItemFromCart={onRemoveItemFromCart}
                        onUpdateCartItem={onUpdateCartItem}
                        onClearCart={onClearCart}
                        discount={discount}
                    />
                )}

                {tab === CheckoutTab.details && (
                    <DetailsStep
                        onLoggedIn={onLoggedIn}
                        isAuthenticated={isAuthenticated}
                        onContinueAsGuest={onContinueAsGuest}
                        onCreateAccount={onCreateAccount}
                    />
                )}

                {tab === CheckoutTab.payment && (
                    <PaymentStep
                        addresses={addresses}
                        paymentMethods={paymentMethods}
                        onGoToPay={onGoToPay}
                    />
                )}

                {activeCheckout && (
                    <CartSection
                        isLoading={isLoading}
                        id={id}
                        paymentMethods={paymentMethods}
                        uspItems={uspItems}
                        items={items}
                        subTotalPrice={subTotalPrice}
                        totalPrice={totalPrice}
                        discount={discount}
                    />
                )}
            </div>
        </div>
    );
};
