import { PaymentMethodResource } from '../@api/Sylius';
import { transformInstantSearchImageToImage } from '../Media/MediaTransformers';
import { PaymentMethod } from './PaymentMethod';

export const transformToPaymentMethods = (resource: PaymentMethodResource): PaymentMethod => {
    const image = transformInstantSearchImageToImage({
        alt: resource.name,
        url: resource.imageUrl,
    });

    return ({
        value: resource.id,
        label: resource.name,
        image,
    });
};
