import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';

import { ErrorLabel } from '../../../components';
import { Button, RecaptchaDisclaimer, TextInput } from '../../../compositions';
import { ForgotPasswordFormData } from '../../../entities/@forms/ForgotPasswordForm/ForgotPasswordForm';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { ForgotPasswordFormErrors, validateForgotPasswordFormData } from './validations';

import './ForgotPasswordForm.scss';

interface ForgotPasswordFormProps extends FormProps<ForgotPasswordFormData> {
    className?: string;
}

export const ForgotPasswordForm: FC<ForgotPasswordFormProps> = ({
    isLoading,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<ForgotPasswordFormErrors>({});
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const [username, setUsername] = useState<string>('');

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: ForgotPasswordFormData = { username };

        const [errors, hasErrors] = validateForgotPasswordFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`forgot-password-form ${className}`}
        >
            <h2 className="forgot-password-form__heading">
                {trans('containers.forgotPasswordForm.heading')}
            </h2>

            <p className="forgot-password-form__instructions">
                {trans('containers.forgotPasswordForm.instructions')}
            </p>

            <TextInput
                type="email"
                autoComplete="email"
                label={trans('containers.forgotPasswordForm.usernameLabel')}
                placeholder={trans('containers.forgotPasswordForm.usernamePlaceholder')}
                value={username}
                error={formErrors.username}
                disabled={isLoading}
                onChange={setUsername}
                className="forgot-password-form__username-input"
            />

            <Button
                hasAnimation
                type="submit"
                text={trans('containers.forgotPasswordForm.submitButton')}
                className="forgot-password-form__submit-button"
            />

            <RecaptchaDisclaimer className="forgot-password-form__recaptcha-disclaimer" />

            {botDetected && (
                <ErrorLabel
                    text={trans('errors.unknownError')}
                    className="forgot-password-form__error-label"
                />
            )}

            {error && (
                <ErrorLabel
                    text={error}
                    className="forgot-password-form__error-label"
                />
            )}

            <Link
                to={trans(appRoutes[AppRoute.login].path)}
                className="forgot-password-form__back-to-login-button"
            >
                {trans('containers.forgotPasswordForm.backToLoginButton')}
            </Link>
        </form>
    );
};
