import { Address } from '../Address/Address';
import { Image } from '../Media/Media';

export const cartToken = 'cartToken';

export interface CartItem {
    id: string;
    variantId: string;
    variantCode: string;
    slug: string;
    name: string;
    quantity: number;
    image?: Image;
    pricePerUnit: number;
    totalPrice: number;
    // TODO: Add discount
}

export interface CartInterface {
    id: string
    items: CartItem[];
    addresses?: Address[];
    subTotalPrice: number;
    totalPrice: number;
    discount: number;
}
