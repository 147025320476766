import {
    FormErrors,
    FormValidation,
    validateDutchPostalCode,
    validateForm,
    validateRequiredString,
} from '../../../../../entities/@forms/FormValidation/FormValidationService';
import { GuestFormData } from '../../../../../entities/@forms/GuestForm/GuestForm';

export type GuestFormErrors = FormErrors<GuestFormData>;

export const validateGuestFormData = (formData: GuestFormData): FormValidation<GuestFormErrors> => {
    const optionalBillingAddressErrors: GuestFormErrors = {};

    if (!formData.useShippingAddressForBilling) {
        optionalBillingAddressErrors.billingAddressStreet = validateRequiredString('street', formData.billingAddressStreet);
        optionalBillingAddressErrors.billingAddressNumber = validateRequiredString('houseNumber', formData.billingAddressNumber);
        optionalBillingAddressErrors.billingAddressCity = validateRequiredString('city', formData.billingAddressCity);
        optionalBillingAddressErrors.billingAddressPostalCode = validateDutchPostalCode(formData.billingAddressPostalCode!);
    }

    const errors: GuestFormErrors = {
        firstName: validateRequiredString('firstName', formData.firstName),
        lastName: validateRequiredString('lastName', formData.lastName),
        email: validateRequiredString('email', formData.email),
        shippingAddressStreet: validateRequiredString('street', formData.shippingAddressStreet),
        shippingAddressNumber: validateRequiredString('houseNumber', formData.shippingAddressNumber),
        shippingAddressPostalCode: validateDutchPostalCode(formData.shippingAddressPostalCode),
        shippingAddressCity: validateRequiredString('city', formData.shippingAddressCity),
        ...optionalBillingAddressErrors,
    };

    return validateForm<GuestFormErrors>(errors);
};
