import {
    FC,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Wrapper } from '../../components';
import { IconButton } from '../../compositions';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { Link } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { SearchProductResult, searchTermQueryKey } from '../../entities/Search/Search';
import { Store } from '../../entities/Store/Store';
import { useDeviceWidth, useHandleClickOutside, useTrans } from '../../hooks';
import {
    GlobalSearch,
    LinkSuggestions,
    ProductSuggestions,
    StoreSuggestions,
    Suggestions,
} from './subcomponents';

import './SearchDrawer.scss';

interface SearchDrawerProps {
    searchTerm: string;
    pageResults: Link[];
    productResults: SearchProductResult[];
    stores: Store[];
    pageSuggestions: Link[];
    recentSearches?: string[];
    onSetStoreTermInRecentSearches: (term: string) => void;
    onSetSearchTerm: (term: string) => void;
    className?: string;
}

export const SearchDrawer: FC<SearchDrawerProps> = ({
    searchTerm,
    pageResults,
    productResults,
    stores,
    pageSuggestions,
    recentSearches,
    onSetStoreTermInRecentSearches,
    onSetSearchTerm,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const { isMobile } = useDeviceWidth();

    const [isOpen, toggleIsOpen] = useToggle(false);
    const [hasStartedTyping, setHasStartedTyping] = useState<boolean>(false);

    const itemRef = useRef<HTMLDivElement>(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const closeDrawer = (): void => {
        toggleIsOpen(false);
    };

    useHandleClickOutside([itemRef], closeDrawer);

    useEffect((): void => {
        if (isOpen && searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, [isOpen]);

    const navigateToSearchResults = (term: string): void => {
        navigate({
            pathname: trans(appRoutes[AppRoute.search].path),
            search: createSearchParams({ [searchTermQueryKey]: term }).toString(),
        });
    };

    const handleClickRecentSearch = (suggestion: string): void => {
        closeDrawer();
        onSetSearchTerm(suggestion);
        navigateToSearchResults(suggestion);
    };

    const handleShowAllResults = (): void => {
        closeDrawer();
        onSetStoreTermInRecentSearches(searchTerm);
        navigateToSearchResults(searchTerm);
    };

    const drawerWrapperClassNames = classNames('search-drawer__wrapper', {
        'search-drawer__wrapper--is-open': isOpen,
    });

    return (
        <section ref={itemRef} className={`search-drawer ${className}`}>
            <IconButton
                hideLabel
                icon={(isOpen && isMobile) ? 'cross' : 'search'}
                text={trans('common.search')}
                onClick={toggleIsOpen}
                className="search-drawer__link"
                iconClassName="search-drawer__link-icon"
            />

            <div
                // @ts-ignore
                inert={!isOpen ? 'true' : undefined}
                className={drawerWrapperClassNames}
            >
                <Wrapper>
                    <GlobalSearch
                        ref={searchInputRef}
                        onIsTyping={setHasStartedTyping}
                        onSubmit={handleShowAllResults}
                        newValue={onSetSearchTerm}
                    />

                    <div className="search-drawer__results-wrapper">
                        {!searchTerm.length && (
                            <>
                                <LinkSuggestions
                                    label={trans('containers.searchDrawer.pageSuggestions')}
                                    links={pageSuggestions}
                                    onClickLink={closeDrawer}
                                />

                                {stores.length > 0 && (
                                    <StoreSuggestions
                                        stores={stores}
                                        onLinkClick={closeDrawer}
                                    />
                                )}
                            </>
                        )}

                        {searchTerm.length > 0 && (
                            <>
                                {recentSearches && (
                                    <Suggestions
                                        label={trans('containers.searchDrawer.recentSearches')}
                                        suggestions={recentSearches}
                                        onSuggestionClick={handleClickRecentSearch}
                                    />
                                )}

                                {productResults.length > 0 && (
                                    <ProductSuggestions
                                        products={productResults}
                                        onProductClick={closeDrawer}
                                    />
                                )}

                                {pageResults.length > 0 && (
                                    <LinkSuggestions
                                        label={trans('containers.searchDrawer.pages')}
                                        links={pageResults}
                                        onClickLink={closeDrawer}
                                    />
                                )}
                            </>
                        )}
                    </div>

                    {hasStartedTyping && (
                        <IconButton
                            hasAnimation
                            isSmall
                            icon="arrow-right"
                            text={trans('containers.searchDrawer.showAllResults')}
                            iconPos={HorizontalAlignment.right}
                            onClick={handleShowAllResults}
                            className="search-drawer__show-all-results-button"
                        />
                    )}
                </Wrapper>
            </div>
        </section>
    );
};
