import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Index } from 'react-instantsearch';
import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import {
    ConnectedBlockRenderer,
    ConnectedBrandDetailRenderer,
    ConnectedBrandDetailWrapper,
    ConnectedProductList,
} from '../../connectors';
import { MeilisearchAttributes } from '../../entities/@api/Sylius';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';

import './BrandDetailPage.scss';

interface BrandDetailProps {
    className?: string;
}

export const BrandDetailPage: FC<BrandDetailProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const trans = useTrans();

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect((): void => {
        if (!slug) {
            return;
        }

        const query = generateFilterQuery(MeilisearchAttributes.brand, slug);

        setProductsQuery(query);
    }, [slug]);

    return (
        <ConnectedBrandDetailWrapper>
            {({ ssrQuery }) => (
                <Page className={`brand-detail-page ${className}`}>
                    <ConnectedBrandDetailRenderer />

                    <Index indexName={MeilisearchIndex.variantsNl}>
                        <ConnectedProductList
                            showFilterBar
                            title={trans('pages.brands.shopProducts')}
                            filterQuery={ssrQuery || productsQuery}
                            className="brand-detail-page__block"
                        />
                    </Index>

                    <ConnectedBlockRenderer appRoute={AppRoute.brandDetail} className="brand-detail-page__block" />
                </Page>
            )}
        </ConnectedBrandDetailWrapper>
    );
};
