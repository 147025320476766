import { generateUuid, transformToSlug } from '../../helpers/string';
import {
    OrderItemResource,
    OrderLines,
    OrderResource,
    PricesResource,
} from '../@api/Sylius';
import { Address, AddressType } from '../Address/Address';
import { transformToCustomerAddress } from '../Address/AddressTransformers';
import { productVariantSeparator } from '../Product/Product';
import { transformToVariantCode } from '../Product/ProductTransformers';
import { CartInterface, CartItem } from './Cart';

export const transformToCartItem = (resource: OrderItemResource, lines: OrderLines[]): CartItem => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const variantCode = transformToVariantCode(resource.variant);

    const pricing = lines.find(line => line.variant === variantCode);

    const formattedName = resource.productName
        ? transformToSlug(resource.productName)
        : '';

    const slug = formattedName + productVariantSeparator + variantCode;

    // TODO: Discount calculation

    return {
        id,
        variantId: resource.variant,
        variantCode,
        slug,
        name: resource.productName ?? '',
        quantity: resource.quantity,
        pricePerUnit: (resource.originalUnitPrice || 0) / 100,
        totalPrice: (pricing?.original || 0) / 100,
    };
};

export const transformToCart = (resource: OrderResource, prices?: PricesResource): CartInterface => {
    const items = resource.items && prices?.lines
        ? resource.items.map(item => transformToCartItem(item, prices?.lines))
        : undefined;

    const addresses: Address[] = [];

    if (resource.shippingAddress) {
        const address = transformToCustomerAddress(resource.shippingAddress, AddressType.shipping);
        addresses.push(address);
    }

    if (resource.billingAddress) {
        const address = transformToCustomerAddress(resource.billingAddress, AddressType.billing);
        addresses.push(address);
    }

    return {
        id: resource.tokenValue || generateUuid(),
        items: items || [],
        subTotalPrice: (prices?.price?.original || 0) / 100,
        totalPrice: (prices?.price?.original || 0) / 100,
        discount: (prices?.price?.discount || 0) / 100,
        addresses,
    };
};
