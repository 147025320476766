import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { AutoplayMediaOption, MediaItemResource } from '../../Media/Media';
import { transformToMediaItem } from '../../Media/MediaTransformers';
import { MediaBannerInterface, MediaBannerResource } from './MediaBanner';

export const transformToMediaBanner = (resource: MediaBannerResource): MediaBannerInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const mediaItemResource: MediaItemResource = {
        playOption: AutoplayMediaOption.autoPlay,
        media: resource.media,
    };

    const media = transformToMediaItem(mediaItemResource);

    return {
        ...defaultBlock,
        type: BlockType.mediaBanner,
        title: resource.title,
        titleColor: resource.titleColor || '',
        subtitle: resource.subtitle || '',
        subtitleColor: resource.subtitleColor || '',
        link: resource.link || undefined,
        linkBackgroundColor: resource.linkBackgroundColor,
        media,
    };
};
