import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedProductDetailRenderer } from '../../connectors';

import './ProductDetailPage.scss';

export const ProductDetailPage: FC = (): ReactElement => (
    <Page className="product-detail-page">
        <ConnectedProductDetailRenderer />
    </Page>
);
