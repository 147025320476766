import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { Store } from '../../../entities/Store/Store';
import { useTrans } from '../../../hooks';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { BlockRenderer } from '../BlockRenderer/BlockRenderer';
import { StoreDetailHeader, StoreDetailHelmet } from './subcomponents';

import './StoreDetailRenderer.scss';

interface StoreDetailRendererProps {
    isLoading?: boolean;
    store?: Store;
    className?: string;
}

export const StoreDetailRenderer: FC<StoreDetailRendererProps> = ({
    isLoading,
    store,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const breadcrumbs = [
        {
            label: trans(appRoutes[AppRoute.storesOverview].label),
            href: trans(appRoutes[AppRoute.storesOverview].path),
        },
        { label: store?.title || '', href: '' },
    ];

    return (
        <Wrapper className={`store-detail-renderer ${className}`}>
            {store && (
                <StoreDetailHelmet store={store} />
            )}

            <Breadcrumbs
                isLoading={isLoading}
                breadcrumbs={breadcrumbs}
            />

            <StoreDetailHeader
                isLoading={isLoading}
                store={store}
                className="store-detail-renderer__header"
            />

            {store?.description && (
                <p className="store-detail-renderer__description">
                    {store.description}
                </p>
            )}

            <BlockRenderer
                isLoading={isLoading}
                appRoute={AppRoute.storeDetail}
                blocks={store?.blocks}
                className="store-detail-renderer__block-renderer"
            />
        </Wrapper>
    );
};
