import { BaseErrorResponse } from '../@api/Sylius';
import { FetchResultClientError, FetchResultError, FetchResultStartPaymentError } from '../FetchResult/FetchResult';

export const transformBaseErrorResponseToString = (resource: BaseErrorResponse): string => {
    const title = resource['hydra:title'];
    const description = resource['hydra:description'];

    return `[${title}] ${description}`;
};

export const transformFetchResultErrorToString = (resource: FetchResultError): string => {
    const { title, detail } = resource;

    return `[${title}] ${detail}`;
};

export const transformFetchResultClientErrorToString = (resource: FetchResultClientError): string => {
    const { code, message } = resource;

    return `[${code}] ${message}`;
};

export const transformFetchResultStartPaymentErrorToString = (resource: FetchResultStartPaymentError): string => {
    const { type, message } = resource.error;

    return `[${type}] ${message}`;
};
