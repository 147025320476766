import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import {
    ConnectedBlockRenderer,
    ConnectedBrandOverviewRenderer,
    ConnectedBrandOverviewWrapper,
    ConnectedPageHelmet,
} from '../../connectors';
import { BlockType } from '../../entities/Block/Block';
import { AppRoute } from '../../entities/Routing/Routing';

import './BrandOverviewPage.scss';

interface BrandOverviewPageProps {
    className?: string;
}

export const BrandOverviewPage: FC<BrandOverviewPageProps> = ({
    className = '',
}): ReactElement => (
    <ConnectedBrandOverviewWrapper>
        <Page className={`brand-overview-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.brandOverview} />

            <ConnectedBrandOverviewRenderer className="brand-overview-page__brand-overview">
                <ConnectedBlockRenderer
                    appRoute={AppRoute.brandOverview}
                    blocksToShow={[BlockType.brandCarousel]}
                    className="brand-overview-page__top-block-renderer"
                />
            </ConnectedBrandOverviewRenderer>

            <ConnectedBlockRenderer
                preventFetch
                appRoute={AppRoute.brandOverview}
                blocksToIgnore={[BlockType.brandCarousel]}
                className="brand-overview-page__bottom-block-renderer"
            />
        </Page>
    </ConnectedBrandOverviewWrapper>
);
