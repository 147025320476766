import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../../../../components';
import { generateIdArray } from '../../../../../../../helpers/array';

import './BrandNavigationSkeletons.scss';

interface BrandNavigationSkeletonsProps {
    numberOfSkeletons?: number;
}

export const BrandNavigationSkeletons: FC<BrandNavigationSkeletonsProps> = ({
    numberOfSkeletons = 15,
}): ReactElement => {
    const itemsSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <div className="brand-navigation-skeletons">
            <Wrapper>
                <Skeleton className="brand-navigation-skeletons__navigation" />

                <ul className="brand-navigation-skeletons__columns">
                    {itemsSkeletons.map(skeleton => (
                        <li key={skeleton} className="brand-navigation-skeletons__item">
                            <Skeleton className="brand-navigation-skeletons__description-text" />
                        </li>
                    ))}
                </ul>
            </Wrapper>
        </div>
    );
};
