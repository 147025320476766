import { FC, ReactElement } from 'react';

import { SpaceDetailRenderer } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedSpaceDetailRendererProps {
    title: string;
    className?: string;
}

export const ConnectedSpaceDetailRenderer: FC<ConnectedSpaceDetailRendererProps> = ({
    title,
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.spaceSlice.isLoading);
    const categories = useTypedSelector(state => state.spaceSlice.categories);
    const breadcrumbs = useTypedSelector(state => state.spaceSlice.breadcrumbs);

    return (
        <SpaceDetailRenderer
            showSlider={categories.length > 0}
            isLoading={isLoading}
            breadcrumbs={breadcrumbs}
            title={title}
            categories={categories}
            className={className}
        />
    );
};
