export interface SearchProductResult {
    id: string;
    name?: string;
    brand: string;
    price: number;
    slug: string;
}

export const recentSearchesKey = 'recentSearches';

export const searchTermQueryKey = 'q';
