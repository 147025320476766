import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { Link } from '../../../entities/Link/Link';
import { ProductCategoryPageData } from '../../../entities/ProductCategory/ProductCategory';
import { BlockRenderer } from '../..';
import { CategoryHeader } from '../../@blocks/CategoryHeader/CategoryHeader';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { ProductCategoryHelmet } from './subcomponents';

import './ProductCategoryRenderer.scss';

interface ProductCategoryRendererProps {
    isLoading: boolean;
    breadcrumbs: Link[];
    pageData?: ProductCategoryPageData;
    className?: string;
}

export const ProductCategoryRenderer: FC<ProductCategoryRendererProps> = ({
    isLoading,
    breadcrumbs,
    pageData,
    className = '',
}): ReactElement => (
    <div className={`product-category-renderer ${className}`}>
        {pageData && (
            <ProductCategoryHelmet pageData={pageData} />
        )}

        <Wrapper>
            <Breadcrumbs
                isLoading={isLoading}
                breadcrumbs={breadcrumbs}
                className="product-category-renderer__breadcrumbs"
            />
        </Wrapper>

        <CategoryHeader
            isLoading={isLoading}
            title={pageData?.title || ''}
            image={pageData?.image}
            className="product-category-renderer__header"
        />

        <BlockRenderer
            isLoading={isLoading}
            blocks={pageData?.blocks || []}
            className="product-category-renderer__block-renderer"
        />
    </div>
);
