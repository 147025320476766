import { strapiFetch } from '../../../entities/@api/Strapi';
import { ContactServiceOptionResponse } from '../../../entities/@blocks/ServiceSection/ServiceSection';
import { generateServiceSectionQuery } from '../../../entities/@blocks/ServiceSection/ServiceSectionRequest';
import { transformToServiceContactDetails } from '../../../entities/@blocks/ServiceSection/ServiceSectionTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { TypedDispatch } from '../../store';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setServiceSectionItems,
} from './serviceSectionSlice';

export const fetchServiceSection = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setServiceSectionItems(undefined));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const queryString = generateServiceSectionQuery();

        const serviceContactDetailsResponse = await strapiFetch<ContactServiceOptionResponse>(
            `/contact-service-openingtime?${queryString}`,
        );

        if (!isFetchResultSuccessful(serviceContactDetailsResponse)) {
            dispatch(setError(serviceContactDetailsResponse.error));
            return;
        }

        const { data } = serviceContactDetailsResponse;

        const serviceSectionItems = data ? transformToServiceContactDetails(data) : undefined;

        dispatch(setServiceSectionItems(serviceSectionItems));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchServiceSection]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
