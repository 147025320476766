import { StrapiEntity } from '../../@api/Strapi';
import { DefaultBlock, DefaultBlockResource } from '../../Block/Block';
import { Link } from '../../Link/Link';
import { StoreResource } from '../../Store/Store';

export type TopNavbarResponse = StrapiEntity<TopNavbarDetailsResource>;

export interface TopNavbarDetailsResource extends DefaultBlockResource {
    usps: TopNavbarUsp[];
    links: Link[];
    shop: StoreResource | null;
}

export interface TopNavbarInterface extends DefaultBlock {
    usps: TopNavbarUsp[];
    links: Link[];
}

export enum TopNabvarUspType {
    text = 'text',
    rating = 'rating',
}

export interface TopNavbarUsp {
    id: string;
    type: TopNabvarUspType;
    title?: string;
    data: string | TopNavbarRating;
}

export interface TopNavbarRating {
    url: string;
    stars: number;
}
