import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Product } from '../../entities/Product/Product';
import { useTrans } from '../../hooks';
import { addProductVariantToCart } from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { addProductVariantToWishlist, removeProductVariantFromWishlist } from '../../redux/wishlist/wishlistActions';
import { Button } from '../@buttons/Button/Button';
import { WishlistButton } from '../@buttons/WishlistButton/WishlistButton';

import './ProductCallToAction.scss';

interface ProductCallToActionProps {
    cartIsLoading: boolean;
    wishlistIsLoading: boolean;
    product: Product;
    className?: string;
}

export const ProductCallToAction: FC<ProductCallToActionProps> = ({
    cartIsLoading,
    wishlistIsLoading,
    product,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const [isInWishlist, setIsInWishlist] = useState<boolean>(false);

    useEffect((): void => {
        const isProductVariantInWishlist = wishlist.productVariantIds.includes(product.variantId);

        setIsInWishlist(isProductVariantInWishlist);
    }, [wishlist]);

    const handleWishlistButtonClick = async (): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishlist(product.variantId));
            return;
        }

        await dispatch(addProductVariantToWishlist(product.variantId));
    };

    const handleAddToCart = async (): Promise<void> => {
        await dispatch(addProductVariantToCart(product.variantCode));
    };

    return (
        <div className={`product-call-to-action ${className}`}>
            <Button
                hasAnimation
                disabled={!product.stock || cartIsLoading}
                text={product.stock ? trans('common.addToCart') : trans('common.soldOut')}
                onClick={handleAddToCart}
                className="product-call-to-action__cart-button"
            />

            <WishlistButton
                isActive={isInWishlist}
                disabled={wishlistIsLoading}
                text={isInWishlist ? trans('common.removeFromWishlist') : trans('common.addToWishlist')}
                onClick={handleWishlistButtonClick}
                className="product-call-to-action__wishlist-button"
            />
        </div>
    );
};
