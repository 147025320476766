import { FC, ReactElement } from 'react';

import { BlogPost } from '../../../entities/@blog/BlogPost/BlogPost';
import { AppRoute } from '../../../entities/Routing/Routing';
import { BlockRenderer } from '../..';
import { BlogPostHelmet } from './subcomponents';

import './BlogPostRenderer.scss';

interface BlogPostRendererProps {
    isLoading: boolean;
    blogPost?: BlogPost;
    className?: string;
}

export const BlogPostRenderer: FC<BlogPostRendererProps> = ({
    isLoading,
    blogPost,
    className = '',
}): ReactElement => (
    <article className={`blog-post-renderer ${className}`}>
        {blogPost && (
            <BlogPostHelmet blogPost={blogPost} />
        )}

        <BlockRenderer
            isLoading={isLoading}
            appRoute={AppRoute.blogPost}
            blocks={blogPost?.blocks}
            className="blog-post-renderer__block-renderer"
        />
    </article>
);
