import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Picture, Wrapper } from '../../../components';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { Image } from '../../../entities/Media/Media';
import { CategoryHeaderSkeletons } from './skeletons';

import './CategoryHeader.scss';

interface CategoryHeaderProps {
    isLoading?: boolean;
    title: string;
    image?: Image;
    imagePosition?: HorizontalAlignment;
    className?: string;
    wrapperClassName?: string;
}

export const CategoryHeader: FC<CategoryHeaderProps> = ({
    isLoading,
    title,
    image,
    imagePosition = HorizontalAlignment.right,
    className = '',
    wrapperClassName = '',
}): ReactElement => {
    if (isLoading) {
        return (
            <CategoryHeaderSkeletons className={`category-header ${className}`} />
        );
    }

    const imageTextBlockWrapperClassNames = classNames('category-header__wrapper', {
        [`category-header__wrapper--image-${imagePosition}`]: imagePosition,
    }, wrapperClassName);

    return (
        <div className={`category-header ${className}`}>
            <Wrapper className={imageTextBlockWrapperClassNames}>
                <div className="category-header__title-wrapper">
                    <h2 className="category-header__title">
                        {title}
                    </h2>
                </div>

                <div className="category-header__image-wrapper">
                    <Picture
                        {...image}
                        className="category-header__picture"
                        imageClassName="category-header__image"
                    />
                </div>
            </Wrapper>
        </div>
    );
};
