export interface PaymentUrl {
    url: string;
}

export interface PaymentResource {
    success: boolean;
    payment: PaymentUrl;
}

export enum PaymentStatus {
    success = 'success',
    pending = 'pending',
    error = 'error',
}

export const orderParamKey = 'order';
export const stateParamKey = 'state';

export interface StartPaymentFormData {
    expectedPriceInCents?: number;
    paymentMethodId?: number;
    paymentBankId?: number;
    giftCardNumber?: string;
    giftCardPin?: string;
    notes?: string;
}
