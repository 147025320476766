import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { Tab } from '../../../../entities/Tab/Tab';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { useTrans } from '../../../../hooks';

import './SsrBlogCategories.scss';

interface SsrBlogCategoriesProps {
    categories: Tab[];
    className?: string;
}

export const SsrBlogCategories: FC<SsrBlogCategoriesProps> = ({
    categories,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const navigationLabel = trans('containers.blogCategories.navigationLabel');
    const blogOverviewPath = trans(appRoutes[AppRoute.blogOverviewCategory].path);

    return (
        <div className={`ssr-blog-categories ${className}`}>
            <nav aria-label={navigationLabel}>
                <ul className="ssr-blog-categories__list">
                    {categories.map(tab => {
                        const blogOverviewUrl = replaceUrlParamKeysWithValues(blogOverviewPath, {
                            category: tab.value,
                        });

                        return (
                            <li key={tab.value} className="ssr-blog-categories__item">
                                <Link to={blogOverviewUrl}>
                                    {tab.label}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};
