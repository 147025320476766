import {
    FC,
    ReactElement,
    useEffect,
    useRef,
} from 'react';

import classNames from 'classnames';
import { useParams } from 'react-router-dom';

import { TabList } from '../../compositions';
import { BlogCategoriesInterface } from '../../entities/@blog/BlogCategories/BlogCategories';
import { isSSR } from '../../helpers';
import { scrollIntoView } from '../../helpers/scroll';
import { useElementHasScroll, useTrans } from '../../hooks';
import { BlogCategoriesSkeletons } from './skeletons';
import { SsrBlogCategories } from './subcomponents';

import './BlogCategories.scss';

interface BlogCategoriesProps extends BlogCategoriesInterface {
    isLoading: boolean;
    activeCategory: string;
    onCategorySelect: (value: string) => void;
    className?: string;
}

export const BlogCategories: FC<BlogCategoriesProps> = ({
    isLoading,
    categories,
    activeCategory,
    onCategorySelect,
    className = '',
}): ReactElement => {
    const { category } = useParams();

    const trans = useTrans();

    const tabListRef = useRef<HTMLUListElement>(null);
    const tabListHasScroll = useElementHasScroll(tabListRef);

    const allNews = {
        label: trans('entities.blogCategory.all.label'),
        value: trans('entities.blogCategory.all.value'),
    };

    useEffect((): void => {
        scrollIntoView(tabListRef, {
            block: 'center',
        });
    }, [category]);

    const tabListClassNames = classNames('blog-categories__tabs', {
        'blog-categories__tabs--has-scroll-bar': tabListHasScroll,
    });

    if (isSSR) {
        return (
            <SsrBlogCategories
                categories={[allNews, ...categories]}
                className={className}
            />
        );
    }

    return (
        <section className={`blog-categories ${className}`}>
            {isLoading && (
                <BlogCategoriesSkeletons
                    numberOfSkeletons={6}
                    className="blog-categories__skeletons"
                />
            )}

            {!isLoading && (
                <TabList
                    ref={tabListRef}
                    tabs={[allNews, ...categories]}
                    activeTab={activeCategory}
                    onTabSelect={onCategorySelect}
                    className={tabListClassNames}
                />
            )}
        </section>
    );
};
