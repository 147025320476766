import { FC, memo, ReactElement } from 'react';

import classNames from 'classnames';

import { Skeleton, Wrapper } from '../../../../../components';
import { BlockType } from '../../../../../entities/Block/Block';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './BlockRendererSkeletons.scss';

interface BlockRendererSkeletonsProps {
    className?: string;
}

export const BlockRendererSkeletons: FC<BlockRendererSkeletonsProps> = memo(({
    className = '',
}): ReactElement => {
    const sections = [BlockType.header, BlockType.contentColumns, BlockType.mediaGrid];

    const numberOfSections = randomInBetweenValue(4, 6);
    const sectionSkeletons = generateIdArray(numberOfSections).map(id => {
        const randomIndex = Math.floor(Math.random() * sections.length);
        const section = sections[randomIndex];

        return `${id}-${section}`;
    });

    return (
        <div className={`block-renderer-skeletons ${className}`}>
            <Wrapper className="block-renderer-skeletons__wrapper">
                {sectionSkeletons.map(section => {
                    const sectionType = section.slice(2);

                    if (sectionType === BlockType.header) {
                        const numberOfLines = randomInBetweenValue(4, 6);
                        const lineSkeletons = generateIdArray(numberOfLines);

                        return (
                            <div key={section} className="block-renderer-skeletons__section block-renderer-skeletons__text-image">
                                <div className="block-renderer-skeletons__text-image-text-wrapper">
                                    <Skeleton className="block-renderer-skeletons__text-image-title" />

                                    <div className="block-renderer-skeletons__paragraph">
                                        {lineSkeletons.map(line => (
                                            <Skeleton key={line} className="block-renderer-skeletons__paragraph-line" />
                                        ))}
                                    </div>
                                </div>

                                <div className="block-renderer-skeletons__text-image-image" />
                            </div>
                        );
                    }

                    if (sectionType === BlockType.mediaGrid) {
                        const numberOfItems = randomInBetweenValue(2, 3);
                        const itemSkeletons = generateIdArray(numberOfItems);

                        const mediaGridClassNames = classNames('block-renderer-skeletons__section', 'block-renderer-skeletons__media-grid', {
                            [`block-renderer-skeletons__media-grid--layout-${numberOfItems}`]: numberOfItems,
                        });

                        return (
                            <div key={section} className={mediaGridClassNames}>
                                {itemSkeletons.map(item => (
                                    <div key={item} className="block-renderer-skeletons__media-item-wrapper">
                                        <div className="block-renderer-skeletons__media-item" />
                                    </div>
                                ))}
                            </div>
                        );
                    }

                    const numberOfColumns = randomInBetweenValue(1, 3);
                    const columnSkeletons = generateIdArray(numberOfColumns);

                    const contentColumnsClassNames = classNames('block-renderer-skeletons__section', 'block-renderer-skeletons__content-columns', {
                        [`block-renderer-skeletons__content-columns--layout-${numberOfColumns}`]: numberOfColumns,
                    });

                    return (
                        <div key={section} className={contentColumnsClassNames}>
                            {columnSkeletons.map(column => {
                                const numberOfParagraphs = randomInBetweenValue(1, 3);
                                const paragraphSkeletons = generateIdArray(numberOfParagraphs);

                                return (
                                    <div key={column} className="block-renderer-skeletons__content-columns-column">
                                        {paragraphSkeletons.map(paragraph => {
                                            const numberOfLines = randomInBetweenValue(4, 6);
                                            const lineSkeletons = generateIdArray(numberOfLines);

                                            return (
                                                <div key={paragraph} className="block-renderer-skeletons__paragraph">
                                                    {lineSkeletons.map(line => (
                                                        <Skeleton key={line} className="block-renderer-skeletons__paragraph-line" />
                                                    ))}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </Wrapper>
        </div>
    );
});
