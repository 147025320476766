import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Icon, Wrapper } from '../../../components';
import {
    Button,
    Checkbox,
    RadioList,
    TextInput,
} from '../../../compositions';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { MakeAppointmentFormData } from '../../../entities/@forms/MakeAppointmentForm/MakeAppointmentForm';
import { useTrans } from '../../../hooks';

import './MakeAppointmentSection.scss';

interface MakeAppointmentFormProps extends FormProps<MakeAppointmentFormData> {
    className?: string;
}

// TODO: Connect with API (needs to be determined)
export const MakeAppointmentSection: FC<MakeAppointmentFormProps> = ({
    isLoading,
    isSuccessful,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [firstName, setFirstName] = useState('');
    const [insertion, setInsertion] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [location, setLocation] = useState<string>('');
    const [newsletterSubscription, setNewsletterSubscription] = useState<boolean>(false);
    const locationOptions = [
        { label: 'Loods 5 Zaandam', value: 'zaandam' },
        { label: 'Loods 5 Duiven', value: 'duiven' },
    ];

    useEffect((): void => {
        if (isSuccessful) {
            setFirstName('');
            setInsertion('');
            setLastName('');
            setCity('');
            setEmail('');
            setPhoneNumber('');
            setLocation('');
            setNewsletterSubscription(false);
        }
    }, [isSuccessful]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData: MakeAppointmentFormData = {
            firstName,
            insertion,
            lastName,
            city,
            email,
            phoneNumber,
            newsletterSubscription,
            location,
        };

        const [errors, hasErrors] = 'TODO: validate form data';

        if (hasErrors) {
            console.error(errors);
            return;
        }

        onSubmit(formData);
    };

    return (
        <Wrapper>
            <div className={`make-appointment-section ${className}`}>
                <div className="make-appointment-section__info">
                    <h2 className="make-appointment-section__info-title">{trans('containers.makeAppointmentForm.title')}</h2>
                    <p className="make-appointment-section__info-description">
                        {trans('containers.makeAppointmentForm.description')}
                    </p>

                    <p className="make-appointment-section__info-sub-title">
                        {trans('containers.makeAppointmentForm.contactUs')}
                    </p>

                    <div className="make-appointment-section__info__contact-info">
                        <Icon name="location" className="make-appointment-section__info__contact-info-icon" />
                        <div>
                            <p className="make-appointment-section__info__contact-info-name">Loods 5 Zaandam</p>
                            <p className="make-appointment-section__info__contact-info-address">
                                Pieter Ghijsenlaan 14b, 1506 PV Zaandam
                            </p>
                            <p className="make-appointment-section__info__contact-info-opening-time">Nu geopend ⋅ Vrijdag 10:00 - 18:00 uur</p>
                        </div>
                    </div>
                    <div className="make-appointment-section__info__contact-info">
                        <Icon name="location" className="make-appointment-section__info__contact-info-icon" />
                        <div>
                            <p className="make-appointment-section__info__contact-info-name">Loods 5 Duiven</p>
                            <p className="make-appointment-section__info__contact-info-address">
                                Cartograaf 14, 6921 EZ
                                Duiven
                            </p>
                            <p className="make-appointment-section__info__contact-info-opening-time">Nu geopend ⋅ Vrijdag 10:00 - 18:00 uur</p>
                        </div>
                    </div>
                </div>

                <div>
                    <form
                        onSubmit={handleSubmit}
                        className="make-appointment-section__form"
                    >
                        <p className="make-appointment-section__form-title">{trans('containers.makeAppointmentForm.contactUs')}</p>

                        <div className="make-appointment-section__form-row">
                            <TextInput
                                label={trans('form.firstName')}
                                value={firstName}
                                disabled={isLoading}
                                onChange={setFirstName}
                                className="make-appointment-section__form-item"
                            />
                            <TextInput
                                label={trans('form.insertion')}
                                value={insertion}
                                disabled={isLoading}
                                onChange={setInsertion}
                                className="make-appointment-section__form-item"
                            />
                            <TextInput
                                label={trans('form.lastName')}
                                value={lastName}
                                disabled={isLoading}
                                onChange={setLastName}
                                className="make-appointment-section__form-item"
                            />
                        </div>

                        <TextInput
                            label={trans('form.city')}
                            value={city}
                            disabled={isLoading}
                            onChange={setCity}
                            className="make-appointment-section__form-item"
                        />
                        <TextInput
                            label={trans('form.phoneNumber')}
                            value={phoneNumber}
                            disabled={isLoading}
                            onChange={setPhoneNumber}
                            className="make-appointment-section__form-item"
                        />
                        <TextInput
                            label={trans('form.email')}
                            value={email}
                            disabled={isLoading}
                            onChange={setEmail}
                            className="make-appointment-section__form-item"
                        />

                        <p className="make-appointment-section__form-title">
                            {trans('form.storeLocation')}
                        </p>

                        <RadioList
                            hideLabel
                            label={trans('form.storeLocation')}
                            value={location}
                            name="radio-list"
                            disabled={isLoading}
                            options={locationOptions}
                            onChange={setLocation}
                        />

                        <Checkbox
                            label={trans('form.newsLetterSubscription')}
                            disabled={isLoading}
                            name="checkbox-list"
                            checked={newsletterSubscription}
                            onChange={setNewsletterSubscription}
                        />

                        <Button
                            isSmall
                            hasAnimation
                            type="submit"
                            text={trans('form.request')}
                        />
                    </form>
                </div>
            </div>
        </Wrapper>
    );
};
