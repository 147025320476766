import { RefObject, useEffect, useState } from 'react';

import { elementHasScroll } from '../helpers/scroll';

export const useElementHasScroll = (ref: RefObject<HTMLElement>, deps = [] as unknown[]): boolean => {
    const [hasScroll, setHasScroll] = useState<boolean>(false);

    useEffect((): () => void => {
        const checkHasScroll = (): void => {
            const refHasScroll = elementHasScroll(ref);

            setHasScroll(refHasScroll);
        };

        checkHasScroll();

        window.addEventListener('resize', checkHasScroll);

        return (): void => {
            window.removeEventListener('resize', checkHasScroll);
        };
    }, deps ? [ref, ...deps] : [ref]);

    return hasScroll;
};
