import { FC, PropsWithChildren, ReactElement } from 'react';

import { BrandOverviewRenderer } from '../../../containers/@renderers/BrandOverviewRenderer/BrandOverviewRenderer';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTypedSelector } from '../../../redux/store';

interface ConnectedBrandOverviewRendererProps extends PropsWithChildren {
    className?: string;
}

export const ConnectedBrandOverviewRenderer: FC<ConnectedBrandOverviewRendererProps> = ({
    className = '',
    children,
}): ReactElement => {
    const pages = useTypedSelector(state => state.pagesSlice.pages);
    const pageData = pages[AppRoute.brandOverview];

    const isLoading = useTypedSelector(state => state.brandOverviewSlice.isLoading);
    const navigation = useTypedSelector(state => state.brandOverviewSlice.navigation);

    return (
        <BrandOverviewRenderer
            isLoading={isLoading}
            navigation={navigation}
            intro={pageData?.description || ''}
            className={className}
        >
            {children}
        </BrandOverviewRenderer>
    );
};
