import { FC, ReactElement, useState } from 'react';

import { Index } from 'react-instantsearch';
import { useSearchParams } from 'react-router-dom';

import { Page } from '../../components';
import { ResultsCountForSearchTerm } from '../../compositions';
import { ConnectedInstantSearchPageResultsList, ConnectedProductList, ConnectedSearchWrapper } from '../../connectors';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';

import './SearchPage.scss';

export const SearchPage: FC = (): ReactElement => {
    const [queryParams] = useSearchParams();
    const searchTermParam = queryParams.get('q') || '';

    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalProducts, setTotalProducts] = useState<number>(0);

    const totalResults = totalPages + totalProducts;

    return (
        <ConnectedSearchWrapper>
            {({ ssrTotalHits, ssrQuery }) => (
                <Page className="search-page">
                    <ResultsCountForSearchTerm
                        resultsCount={ssrTotalHits || totalResults}
                        query={searchTermParam}
                    />

                    <Index indexName={MeilisearchIndex.variantsNl}>
                        <ConnectedProductList
                            showProductsCount
                            showFilterBar
                            query={ssrQuery || searchTermParam}
                            onTotalResults={setTotalProducts}
                        />
                    </Index>

                    <Index indexName={MeilisearchIndex.pages}>
                        <ConnectedInstantSearchPageResultsList
                            query={ssrQuery || searchTermParam}
                            onTotalResults={setTotalPages}
                            className="search-page__pages-list"
                        />
                    </Index>
                </Page>
            )}
        </ConnectedSearchWrapper>
    );
};
