import { FC, ReactElement } from 'react';

import { Wrapper } from '../../components';
import { HeaderWithIntroSkeletons } from './skeletons';

import './HeaderWithIntro.scss';

interface HeaderWithIntroProps {
    isLoading?: boolean;
    intro: string;
    linkToPath: string;
}

export const HeaderWithIntro: FC<HeaderWithIntroProps> = ({
    isLoading,
    intro,
    linkToPath,
}): ReactElement => {
    if (isLoading) {
        return (
            <HeaderWithIntroSkeletons className="header-with-intro" />
        );
    }

    return (
        <header className="header-with-intro">
            <Wrapper className="header-with-intro__wrapper">
                <h1 className="header-with-intro__heading">
                    {linkToPath}
                </h1>

                <p className="header-with-intro__intro">
                    {intro}
                </p>
            </Wrapper>
        </header>
    );
};
