import { FC, useEffect } from 'react';

import { useHits, useSearchBox } from 'react-instantsearch';

import { InstantSearchProductResource } from '../../../entities/@api/Sylius';
import { transformInstantSearchProductToSearchProductResult } from '../../../entities/Product/ProductTransformers';
import { SearchProductResult } from '../../../entities/Search/Search';

interface ConnectedProductHitsHandlerProps {
    query: string;
    onNewItems: (hits: SearchProductResult[]) => void;
}

export const ConnectedProductHitsHandler: FC<ConnectedProductHitsHandlerProps> = ({
    query,
    onNewItems,
}): null => {
    const { refine } = useSearchBox();
    const { items } = useHits<InstantSearchProductResource>();

    useEffect((): void => {
        const trimmedQuery = query.trim();

        if (trimmedQuery.length > 2) {
            refine(trimmedQuery);
        }
    }, [query, refine]);

    useEffect((): void => {
        const transformedHits = items
            .map(transformInstantSearchProductToSearchProductResult)
            .slice(0, 5);

        onNewItems(transformedHits);
    }, [items, onNewItems]);

    return null;
};
