import {
    FC,
    ReactElement,
    useRef,
    useState,
} from 'react';

import classNames from 'classnames';
import { useRefinementList } from 'react-instantsearch';

import { IconButton, TextInput } from '../../../../../compositions';
import { HorizontalAlignment } from '../../../../../entities/Alignment/Alignment';
import { ProductFilter } from '../../../../../entities/ProductFilters/ProductFilters';
import { generateChunkedRefinementListItems } from '../../../../../entities/ProductFilters/ProductFiltersTransformer';
import { useHandleClickOutside } from '../../../../../hooks';
import { InstantSearchRefinementList } from '../../../../index';

import './HighlightedFilter.scss';

interface HighlightedFilterProps {
    isExpanded: boolean;
    showSearchInput?: boolean;
    filter: ProductFilter;
    searchInputLabel: string;
    onToggle: () => void;
    className?: string;
}

export const HighlightedFilter: FC<HighlightedFilterProps> = ({
    isExpanded,
    showSearchInput,
    filter,
    searchInputLabel,
    onToggle,
    className = '',
}): ReactElement | null => {
    const toggleButtonRef = useRef<HTMLButtonElement>(null);
    const filterDrawerRef = useRef<HTMLDivElement>(null);

    useHandleClickOutside([toggleButtonRef, filterDrawerRef], (): void => {
        if (isExpanded) {
            onToggle();
        }
    });

    const { canRefine, items, refine } = useRefinementList({
        attribute: filter.attribute,
        operator: filter.operator,
        sortBy: ['name:asc'],
        limit: 50,
    });

    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredItems = showSearchInput
        ? items.filter(item => item.label.toLowerCase().includes(searchQuery.toLowerCase()))
        : items;

    const chunkedFilterItems = generateChunkedRefinementListItems(filteredItems, 8);

    const filterButtonClassNames = classNames('highlighted-filter__filter-button', {
        'highlighted-filter__filter-button--is-active': isExpanded,
    });

    const filterDrawerClassNames = classNames('highlighted-filter__filter-drawer', {
        'highlighted-filter__filter-drawer--is-open': isExpanded,
    });

    if (!canRefine) {
        return null;
    }

    return (
        <div className={`highlighted-filter ${className}`}>
            <IconButton
                ref={toggleButtonRef}
                isSmall
                icon={isExpanded ? 'chevron-up' : 'chevron-down'}
                iconPos={HorizontalAlignment.right}
                text={filter.label}
                onClick={onToggle}
                className={filterButtonClassNames}
                labelClassName="highlighted-filter__filter-button-label"
            />

            <div ref={filterDrawerRef} className={filterDrawerClassNames}>
                <div className="highlighted-filter__label-and-search-input-wrapper">
                    <h6 className="highlighted-filter__label">
                        {filter.label}
                    </h6>

                    {showSearchInput && (
                        <TextInput
                            hideLabel
                            hasUnderline
                            type="search"
                            icon="search"
                            label={searchInputLabel}
                            placeholder={searchInputLabel}
                            value={searchQuery}
                            onChange={setSearchQuery}
                            className="highlighted-filter__search-input"
                        />
                    )}
                </div>

                {chunkedFilterItems.map(item => (
                    <InstantSearchRefinementList
                        key={item[0].value}
                        label={filter.label}
                        items={item}
                        onToggle={refine}
                    />
                ))}
            </div>
        </div>
    );
};
