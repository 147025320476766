import { FC, PropsWithChildren, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { HeaderWithIntro } from '../../../compositions';
import { BrandNavigation as BrandNavigationInterface } from '../../../entities/Brand/Brand';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { isEmptyObject } from '../../../helpers/object';
import { useTrans } from '../../../hooks';
import { BrandNavigation } from './subcomponents';

import './BrandOverviewRenderer.scss';

interface BrandOverviewRendererProps extends PropsWithChildren {
    isLoading?: boolean;
    intro: string;
    navigation?: BrandNavigationInterface;
    className?: string;
}

export const BrandOverviewRenderer: FC<BrandOverviewRendererProps> = ({
    isLoading,
    intro,
    navigation,
    className = '',
    children,
}): ReactElement => {
    const trans = useTrans();

    const isNotEmptyBrandNavigation = navigation && !isEmptyObject(navigation);

    return (
        <div className={`brand-overview-renderer ${className}`}>
            <HeaderWithIntro
                isLoading={isLoading}
                intro={intro}
                linkToPath={trans(appRoutes[AppRoute.brandOverview].label)}
            />

            <div className="brand-overview-renderer__child-wrapper">
                {children}
            </div>

            {isNotEmptyBrandNavigation && (
                <Wrapper>
                    <BrandNavigation
                        isLoading={isLoading}
                        navigation={navigation}
                    />
                </Wrapper>
            )}
        </div>
    );
};
