import { RefinementListItem } from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

import { MeilisearchAttributes } from '../@api/Sylius';
import { ProductFilter } from './ProductFilters';

const productSpecificPrefix = 'attributes.';

export const getProductSpecificAttributes = (filterableAttributes: string[]): MeilisearchAttributes[] => (
    filterableAttributes.filter(item => item.startsWith(productSpecificPrefix)) as MeilisearchAttributes[]
);

export const transformAttributeToProductFilter = (attribute: MeilisearchAttributes): ProductFilter => {
    const label = attribute.replace(productSpecificPrefix, '');

    return {
        attribute,
        label,
        operator: 'or',
    };
};

export const generateChunkedRefinementListItems = (items: RefinementListItem[], chunkSize: number): RefinementListItem[][] => {
    const chunkCount = Math.ceil(items.length / chunkSize);
    const chunks = [];

    for (let index = 0; index < chunkCount; index += 1) {
        const chunk = items.slice(index * chunkSize, (index + 1) * chunkSize);
        chunks.push(chunk);
    }

    return chunks;
};
