import { FC, ReactElement } from 'react';

import { InstantSearch } from 'react-instantsearch';
import { useLocation } from 'react-router-dom';

import {
    ConnectedAppWrapper,
    ConnectedCacheBuster,
    ConnectedFooter,
    ConnectedMenu,
    ConnectedToastList,
    ConnectedTopNavbar,
    ConnectedUrgentBanner,
} from './connectors';
import { initializeMeilisearchClient } from './entities/Meilisearch/MeilisearchService';
import { AppRoute, appRoutes } from './entities/Routing/Routing';
import { useTrans } from './hooks';
import { LocalizedRoutes } from './LocalizedRoutes';

export const { searchClient } = initializeMeilisearchClient();

export const App: FC = (): ReactElement => {
    const trans = useTrans();
    const { pathname } = useLocation();

    const checkoutDetailsPath = trans(appRoutes[AppRoute.checkoutDetails].path);
    const checkoutPaymentPath = trans(appRoutes[AppRoute.checkoutPayment].path);

    const isCheckoutPage = pathname.includes(checkoutDetailsPath) || pathname.includes(checkoutPaymentPath);

    return (
        <InstantSearch searchClient={searchClient} future={{ preserveSharedStateOnUnmount: true }}>
            <ConnectedAppWrapper>
                {!isCheckoutPage && (
                    <>
                        <ConnectedTopNavbar />
                        <ConnectedMenu />
                        <ConnectedUrgentBanner />
                    </>
                )}

                <LocalizedRoutes />

                <ConnectedFooter showTopFooter={!isCheckoutPage} />

                <ConnectedToastList />
                <ConnectedCacheBuster />
            </ConnectedAppWrapper>
        </InstantSearch>
    );
};
