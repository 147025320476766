import {
    ChangeEvent,
    FC,
    ReactElement,
    TextareaHTMLAttributes,
} from 'react';

import classNames from 'classnames';

import { Icon } from '../../../components';
import { InputLabelProps, InputLabelWrapper } from '../..';

import './Textarea.scss';

interface TextareaProps extends InputLabelProps, Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
    error?: string;
    onChange: (value: string) => void;
    fieldClassName?: string;
}

export const Textarea: FC<TextareaProps> = ({
    label,
    hideLabel,
    required,
    tooltip,
    error,
    onChange,
    className = '',
    fieldClassName = '',
    ...textareaProps
}): ReactElement => {
    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange(event.currentTarget.value);
    };

    const textareaFieldClassNames = classNames('textarea__field', {
        'textarea__field--has-error': error,
    }, fieldClassName);

    return (
        <label aria-label={label} className={`textarea ${className}`}>
            <InputLabelWrapper
                hideLabel={hideLabel}
                label={label}
                required={required}
                tooltip={tooltip}
                error={error}
            >
                <div className="textarea__field-wrapper">
                    <textarea
                        {...textareaProps}
                        required={required}
                        onChange={handleChange}
                        className={textareaFieldClassNames}
                    />

                    {error && (
                        <Icon name="box-warning" className="textarea__error-icon" />
                    )}
                </div>
            </InputLabelWrapper>
        </label>
    );
};
