export enum MeilisearchAttributes {
    type = 'type',
    brand = 'attributes.Brand',
    categories = 'categories',
    code = 'code',
    color = 'attributes.Color',
    highestDiscount = 'price.LOODS_5.discount',
    id = 'id',
    isNew = 'is_new',
    isSale = 'is_sale',
    lists = 'lists',
    material = 'attributes.Material',
    price = 'price.LOODS_5.price',
    ranking = 'ranking',
    stock = 'stock',
}
