import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { DepartmentDetailRenderer, NotFound } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchDepartmentDetail } from '../../../redux/@departments/departmentDetail/departmentDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedDepartmentDetailRendererProps {
    className?: string;
}

export const ConnectedDepartmentDetailRenderer: FC<ConnectedDepartmentDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.departmentDetailSlice.hasFetched);
    const isLoading = useTypedSelector(state => state.departmentDetailSlice.isLoading);
    const departmentDetail = useTypedSelector(state => state.departmentDetailSlice.departmentDetail);

    useClientEffect((): void => {
        dispatch(fetchDepartmentDetail(slug));
    }, [slug]);

    if (hasFetched && !departmentDetail) {
        return (
            <NotFound />
        );
    }

    return (
        <DepartmentDetailRenderer
            isLoading={isLoading}
            departmentDetail={departmentDetail}
            className={className}
        />
    );
};
