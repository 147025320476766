import { RefObject, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useHandleClickOutside = (refs: RefObject<any>[], handler: (event: UIEvent) => void): void => {
    useEffect((): () => void => {
        const listener = (event: UIEvent): void => {
            for (let i = 0; i < refs.length; i += 1) {
                const ref = refs[i];

                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
            }


            handler(event);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return (): void => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [refs, handler]);
};
