import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../../../compositions';
import { DepartmentDetailInterface } from '../../../../../entities/@departments/DepartmentDetail/DepartmentDetail';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { generateBreadcrumbStructuredData } from '../../../../../helpers/structuredData';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

interface DepartmentDetailHelmetProps {
    departmentDetail: DepartmentDetailInterface;
}

export const DepartmentDetailHelmet: FC<PropsWithChildren<DepartmentDetailHelmetProps>> = ({
    departmentDetail,
    children,
}): ReactElement => {
    const trans = useTrans();

    const siteUrl = trans('company.url');

    const metaTitle = departmentDetail.seo
        ? departmentDetail.seo.title
        : departmentDetail.title;

    const metaDescription = departmentDetail.seo
        ? departmentDetail.seo.description
        : departmentDetail.intro;

    const metaKeywords = departmentDetail.seo
        ? departmentDetail.seo.keywords
        : undefined;

    const metaImage = departmentDetail.seo
        ? departmentDetail.seo.image
        : departmentDetail.image;

    const departmentDetailPath = trans(appRoutes[AppRoute.departmentDetail].path);
    const departmentDetailUrl = replaceUrlParamKeysWithValues(departmentDetailPath, {
        slug: departmentDetail.slug,
    });

    const breadcrumbs = generateBreadcrumbStructuredData(siteUrl, [
        {
            label: trans(appRoutes[AppRoute.storesOverview].label),
            href: trans(appRoutes[AppRoute.storesOverview].path),
        },
        {
            label: trans(appRoutes[AppRoute.storesDepartments].label),
            href: trans(appRoutes[AppRoute.storesDepartments].path),
        },
        {
            label: departmentDetail.title,
            href: departmentDetailUrl,
        },
    ]);

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            image={metaImage}
            canonical={departmentDetail.seo?.canonicalURL}
        >

            <script type="application/ld+json">
                {breadcrumbs}
            </script>

            {children}
        </DefaultHelmet>
    );
};
