import { FC, ReactElement } from 'react';

import { BrandHeader } from '../../../compositions';
import { Brand } from '../../../entities/Brand/Brand';
import { BlockRenderer } from '../..';
import { BrandDetailHelmet } from './subcomponents';

import './BrandDetailRenderer.scss';

interface BrandDetailRendererProps {
    isLoading?: boolean;
    brand?: Brand;
    className?: string;
}

export const BrandDetailRenderer: FC<BrandDetailRendererProps> = ({
    isLoading,
    brand,
    className = '',
}): ReactElement => (
    <div className={`brand-detail-renderer ${className}`}>
        {brand && (
            <BrandDetailHelmet brand={brand} />
        )}

        <BrandHeader
            isLoading={isLoading}
            brand={brand}
            className="brand-detail-renderer__header"
        />

        <BlockRenderer
            isLoading={isLoading}
            blocks={brand?.blocks}
            className="brand-detail-renderer__block-renderer"
        />
    </div>
);
