import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Link } from '../../entities/Link/Link';
import { ProductCategory } from '../../entities/ProductCategory/ProductCategory';
import { Space } from '../../entities/Space/Space';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SpaceState = AsyncReduxState<{
    ssrSpaceQuery?: string;
    spaces: Space[];
    space?: Space;
    categories: ProductCategory[];
    breadcrumbs: Link[];
}>;

const initialState: SpaceState = {
    ...initialAsyncReduxState,
    spaces: [],
    categories: [],
    breadcrumbs: [],
};

const spaceSlice = createSlice({
    name: 'spaceSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): SpaceState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): SpaceState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SpaceState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SpaceState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSpaces(state, action: PayloadAction<Space[]>): SpaceState {
            return {
                ...state,
                spaces: action.payload,
            };
        },
        setSpace(state, action: PayloadAction<Space | undefined>): SpaceState {
            return {
                ...state,
                space: action.payload,
            };
        },
        setCategories(state, action: PayloadAction<ProductCategory[]>): SpaceState {
            return {
                ...state,
                categories: action.payload,
            };
        },
        setBreadcrumbs(state, action: PayloadAction<Link[]>): SpaceState {
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        },
        setSsrSpaceQuery(state, action: PayloadAction<string | undefined>): SpaceState {
            return {
                ...state,
                ssrSpaceQuery: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setSpaces,
    setSpace,
    setCategories,
    setSsrSpaceQuery,
    setBreadcrumbs,
} = spaceSlice.actions;

export default spaceSlice;
