import { FC, ReactElement, useMemo } from 'react';

import { Wrapper } from '../../../components';
import { SectionFooter } from '../../../compositions';
import { BrandCarouselInterface, BrandLogo } from '../../../entities/@blocks/BrandCarousel/BrandCarousel';
import { Link } from '../../../entities/Link/Link';
import { BrandCarouselItems } from './subcomponents';

import './BrandCarousel.scss';

interface BrandCarouselProps extends BrandCarouselInterface {
    link?: Link;
    className?: string;
}

export const BrandCarousel: FC<BrandCarouselProps> = ({
    link,
    logos,
    title,
    className = '',
}): ReactElement => {
    // Ensures there are enough logos to fill the carousel by duplicating them as necessary
    const duplicatedLogos = useMemo((): BrandLogo[] => {
        const minimumLogosToFillCarousel = 6;

        if (logos.length === 0 || logos.length >= minimumLogosToFillCarousel) {
            return logos;
        }

        const timesToDuplicate = Math.ceil(minimumLogosToFillCarousel / logos.length);

        return Array(timesToDuplicate)
            .fill(logos)
            .flat()
            .map((logo, index) => ({
                ...logo,
                id: `${index}-${logo.id}`,
            }));
    }, [logos]);

    return (
        <section className={`brand-carousel ${className}`}>
            {title && (
                <Wrapper>
                    <h2 className="brand-carousel__title">
                        {title}
                    </h2>
                </Wrapper>
            )}

            <div className="brand-carousel__wrapper">
                <BrandCarouselItems logos={duplicatedLogos} className="brand-carousel__items-wrapper" />
                <BrandCarouselItems logos={duplicatedLogos} className="brand-carousel__items-wrapper" />
            </div>

            {link && (
                <Wrapper className="brand-carousel__footer-wrapper">
                    <SectionFooter link={link} />
                </Wrapper>
            )}
        </section>
    );
};
