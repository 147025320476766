import { strapiFetch } from '../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { NavigationRequestConfig, NavigationResponse } from '../../entities/Navigation/Navigation';
import { transformToNavigation } from '../../entities/Navigation/NavigationTransformers';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setNavigations,
} from './navigationsSlice';

export const fetchNavigation: ReduxFetchAction<NavigationRequestConfig> = config => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const { key, slug } = config;
        const navigationResponse = await strapiFetch<NavigationResponse>(`/navigation/render/${slug}?type=TREE`);

        if (!isFetchResultSuccessful(navigationResponse)) {
            dispatch(setError(navigationResponse.error));
            return;
        }

        const navigationData = navigationResponse.data;

        const navigationResource = navigationData
            ? navigationData[0]
            : undefined;

        const navigation = navigationResource
            ? transformToNavigation(navigationResource)
            : undefined;

        const state = getState();
        const { navigations } = state.navigationsSlice;

        if (navigation) {
            const updatedNavigations = {
                ...navigations,
                [key]: navigation,
            };

            dispatch(setNavigations(updatedNavigations));
            dispatch(setIsSuccessful(true));
        }
    } catch (error) {
        console.error('[fetchNavigation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
