import { MediaBannerInterface } from '../../entities/@blocks/MediaBanner/MediaBanner';
import { LinkTarget } from '../../entities/Link/Link';
import { MOCK_VIDEO } from './media';

export const MOCK_MEDIA_BANNER: MediaBannerInterface = {
    media: {
        id: '1',
        video: MOCK_VIDEO,
    },
    title: 'Collector’s home',
    titleColor: '#937D1D',
    subtitle: 'Verzamel je stijl',
    subtitleColor: '#FFF',
    link: {
        label: 'Bekijk trend',
        href: '/',
        target: LinkTarget.self,
    },
    linkBackgroundColor: '#828474',
};
