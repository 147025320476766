import { OpenStatus, OptionalWorkingHours } from '../entities/@blocks/ServiceSection/ServiceSection';
import { OpenStatusInfo } from '../entities/Store/Store';
import trans from './trans';

export const getOpenStatus = (workingHours: OptionalWorkingHours[]): OpenStatus => {
    // No working hours > Open 24/7
    if (!workingHours.length) {
        return {
            isOpen: true,
            openUntil: '24/7',
        };
    }

    const now = new Date();
    const day = now.getDay();
    const todayWorkingHours = workingHours.find((item) => item.day === day);

    const closed: OpenStatus = {
        isOpen: false,
        openUntil: todayWorkingHours?.to || undefined,
    };

    // No working hours for this day > Closed / offline
    if (!todayWorkingHours || todayWorkingHours.closed || !todayWorkingHours.from || !todayWorkingHours.to) {
        return closed;
    }

    // Check if the current time is within the working hours
    const [startHour, startMinute] = todayWorkingHours.from.split(':').map(Number);
    const [endHour, endMinute] = todayWorkingHours.to.split(':').map(Number);

    const start = new Date();
    const end = new Date();

    start.setHours(startHour, startMinute, 0, 0);
    end.setHours(endHour, endMinute, 0, 0);

    if (now < start || now > end) {
        return closed;
    }

    return {
        isOpen: true,
        openUntil: todayWorkingHours.to,
    };
};

export const formatTimeString = (timeString: string): string => {
    if (!timeString) {
        return '';
    }

    const [hours, minutes] = timeString.split(':');

    return `${hours}:${minutes}`;
};

export const getOpenStatusInfo = (
    workingHours: OptionalWorkingHours[],
    showShort: boolean = false,
): OpenStatusInfo => {
    const openStatus = getOpenStatus(workingHours);
    const isAlwaysOpen = openStatus.openUntil === '24/7';

    const shortOpenStatus = openStatus.isOpen ? trans('common.open') : trans('common.closed');
    let openStatusText = shortOpenStatus;

    if (openStatus.isOpen) {
        if (isAlwaysOpen) {
            openStatusText = trans('entities.workingHours.alwaysOpen');
        } else if (openStatus.openUntil) {
            // Format time
            const formattedTime = formatTimeString(openStatus.openUntil);
            openStatusText = showShort
                ? trans('entities.workingHours.nowOpenUntil', { time: formattedTime })
                : trans('entities.workingHours.openUntil', { time: formattedTime });
        }
    }

    return {
        shortOpenStatus,
        openStatus: openStatusText,
    };
};
