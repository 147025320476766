import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Index } from 'react-instantsearch';
import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedProductCategoryRenderer, ConnectedProductCategoryWrapper, ConnectedProductList } from '../../connectors';
import { MeilisearchAttributes } from '../../entities/@api/Sylius';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { ProductCategoryParams } from '../../entities/ProductCategory/ProductCategory';

import './ProductCategoryPage.scss';

export const ProductCategoryPage: FC = (): ReactElement => {
    const { slug = '', subCategory, subSubCategory } = useParams<ProductCategoryParams>();

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect((): void => {
        if (!slug) {
            return;
        }

        const value = subSubCategory || subCategory || slug;
        const query = generateFilterQuery(MeilisearchAttributes.categories, value);

        setProductsQuery(query);
    }, [slug, subCategory, subSubCategory]);

    return (
        <ConnectedProductCategoryWrapper>
            {({ ssrQuery, callToActions }) => (
                <Page className="product-category-page">
                    <ConnectedProductCategoryRenderer />

                    <Index indexName={MeilisearchIndex.variantsNl}>
                        <ConnectedProductList
                            showFilterBar
                            filterQuery={ssrQuery || productsQuery}
                            callToActions={callToActions}
                        />
                    </Index>
                </Page>
            )}
        </ConnectedProductCategoryWrapper>
    );
};
