import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import {
    Icon,
    Page,
    Picture,
    Wrapper,
} from '../../components';
import { ConnectedBlockRenderer } from '../../connectors';
import { LinkTarget } from '../../entities/Link/Link';
import { AppRoute } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { MOCK_IMAGE_1, MOCK_SOCIAL_MEDIA_NAVIGATION } from '../../mock/mock-data';

import './CheckoutThankYouPage.scss';

interface CheckoutThankYouPageProps {
    page?: string;
}

export const CheckoutThankYouPage: FC<CheckoutThankYouPageProps> = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="checkout-thank-you-page">
            <Wrapper className="checkout-thank-you-page__wrapper">
                <div className="checkout-thank-you-page__title-wrapper">
                    <h1 className="checkout-thank-you-page__heading">
                        {trans('pages.checkoutThankYouPage.heading')}
                    </h1>

                    <p className="checkout-thank-you-page__text checkout-thank-you-page__intro">
                        {trans('pages.checkoutThankYouPage.intro')}
                    </p>

                    <p className="checkout-thank-you-page__text checkout-thank-you-page__body">
                        {trans('pages.checkoutThankYouPage.body')}
                    </p>

                    <p className="checkout-thank-you-page__text checkout-thank-you-page__postscript">
                        {trans('pages.checkoutThankYouPage.postscript')}
                    </p>

                    <div className="checkout-thank-you-page__social-wrapper">
                        {MOCK_SOCIAL_MEDIA_NAVIGATION.groups.map(group => (
                            <ul key={group.id} className="checkout-thank-you-page__social-media-group">
                                {group.items.map(item => (
                                    <li key={item.id}>
                                        <Link
                                            aria-label={item.label}
                                            to={item.to}
                                            target={LinkTarget.blank}
                                            className="checkout-thank-you-page__social-media-link"
                                        >
                                            {item.icon && (
                                                <Icon
                                                    name={item.icon}
                                                    className="checkout-thank-you-page__social-media-link-icon"
                                                />
                                            )}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        ))}
                    </div>
                </div>

                <div className="checkout-thank-you-page__image-wrapper">
                    <Picture
                        {...MOCK_IMAGE_1}
                        className="checkout-thank-you-page__picture"
                        imageClassName="checkout-thank-you-page__image"
                    />
                </div>
            </Wrapper>

            <ConnectedBlockRenderer
                appRoute={AppRoute.checkoutThankYou}
                className="checkout-thank-you-page__block-renderer"
            />
        </Page>
    );
};
