import { GetProductResponse, GetProductVariantResponse, syliusFetch } from '../../entities/@api/Sylius';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { transformValuesToBreadcrumbs } from '../../entities/Link/LinkTransformers';
import { transformSlugToVariantId, transformToProduct } from '../../entities/Product/ProductTransformers';
import { transformToProductDefault } from '../../entities/ProductDefault/ProductDefaultTransformers';
import { transformToProductVariant } from '../../entities/ProductVariant/ProductVariantTransformers';
import { TaxonEntry } from '../../entities/Taxon/Taxon';
import { fetchTaxonEntry } from '../../entities/Taxon/TaxonService';
import { ReduxFetchAction } from '../defaults';
import {
    setBreadcrumbs,
    setError,
    setHasFetched,
    setIsLoading,
    setProduct,
} from './productSlice';

export const fetchProductFromVariantSlug: ReduxFetchAction<string> = variantSlug => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setHasFetched(false));
    dispatch(setProduct(undefined));

    try {
        const variantId = transformSlugToVariantId(variantSlug);
        const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${variantId}`);

        if (!isFetchResultSuccessful(productVariantResponse)) {
            dispatch(setError(productVariantResponse.error));
            return;
        }

        const productVariantResource = productVariantResponse.data;
        const productVariant = transformToProductVariant(productVariantResource);

        const productDefaultResponse = await syliusFetch<GetProductResponse>(`/shop/products/${productVariant.productCode}`);

        if (!isFetchResultSuccessful(productDefaultResponse)) {
            dispatch(setError(productDefaultResponse.error));
            return;
        }

        const productDefaultResource = productDefaultResponse.data;

        const taxonList = productDefaultResource.productTaxons;
        const taxonPromises = taxonList.map(fetchTaxonEntry);

        const resolvedTaxonEntries = await Promise.all(taxonPromises);
        const foundTaxonEntries = resolvedTaxonEntries.filter(Boolean) as TaxonEntry[];

        const productCategories = Object.values(Object.fromEntries(foundTaxonEntries));

        const breadcrumbs = transformValuesToBreadcrumbs(productCategories.reverse());

        dispatch(setBreadcrumbs(breadcrumbs));

        const productDefault = transformToProductDefault(productDefaultResource);

        const product = transformToProduct(productDefault, productVariant, productCategories);

        dispatch(setProduct(product));
    } catch (error) {
        console.error('[fetchProductFromVariantSlug]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
