import {
    FormErrors,
    FormValidation,
    validateDutchPostalCode,
    validateEmailAddress,
    validateForm,
    validateRequiredBoolean,
    validateRequiredString,
    validateStringMatches,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import {
    RegistrationAccountDetailsFormData,
    RegistrationEmailFormData,
    RegistrationFormData,
} from '../../../../entities/@forms/RegistrationForm/RegistrationForm';

export type RegistrationFormErrors = FormErrors<RegistrationFormData>;

export const validateRegistrationEmailFormData = (formData: RegistrationEmailFormData): FormValidation<RegistrationFormErrors> => {
    const errors: RegistrationFormErrors = {
        email: validateEmailAddress(formData.email),
    };

    return validateForm<RegistrationFormErrors>(errors);
};

export const validateRegistrationAccountDetailsFormData = (formData: RegistrationAccountDetailsFormData): FormValidation<RegistrationFormErrors> => {
    const errors: RegistrationFormErrors = {
        firstName: validateRequiredString('firstName', formData.firstName),
        lastName: validateRequiredString('lastName', formData.lastName),
        street: validateRequiredString('street', formData.street),
        number: validateRequiredString('houseNumber', formData.number),
        city: validateRequiredString('city', formData.city),
        postcode: validateDutchPostalCode(formData.postcode),
        countryCode: validateRequiredString('country', formData.countryCode),
        password: validateRequiredString('password', formData.password),
        confirmPassword: validateStringMatches('confirmPassword', formData.password, formData.confirmPassword),
        acceptTermsAndConditions: validateRequiredBoolean('acceptTermsAndConditions', formData.acceptTermsAndConditions),
    };

    return validateForm<RegistrationFormErrors>(errors);
};
