import { FC, ReactElement, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { ProductCategoryParams } from '../../../entities/ProductCategory/ProductCategory';
import { ProductListCallToActionInterface } from '../../../entities/ProductListCallToAction/ProductListCallToAction';
import { useClientEffect } from '../../../hooks';
import { fetchMainProductCategory } from '../../../redux/productCategory/productCategoryActions';
import { setProductCategoryReplacementUrl } from '../../../redux/productCategory/productCategorySlice';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductCategoryChildProps {
    ssrQuery?: string;
    callToActions: ProductListCallToActionInterface[];
}

interface ConnectedProductCategoryWrapperProps {
    children: (childProps: ConnectedProductCategoryChildProps) => ReactElement;
}

export const ConnectedProductCategoryWrapper: FC<ConnectedProductCategoryWrapperProps> = ({
    children,
}): ReactElement => {
    const { slug = '' } = useParams<ProductCategoryParams>();
    const navigate = useNavigate();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.productCategorySlice.hasFetched);
    const ssrProductCategoryQuery = useTypedSelector(state => state.productCategorySlice.ssrProductCategoryQuery);
    const productCategoryReplacementUrl = useTypedSelector(state => state.productCategorySlice.productCategoryReplacementUrl);
    const pageData = useTypedSelector(state => state.productCategorySlice.pageData);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchMainProductCategory(slug));
        }
    }, [slug]);

    useEffect((): void => {
        if (productCategoryReplacementUrl) {
            dispatch(setProductCategoryReplacementUrl(undefined));

            navigate(productCategoryReplacementUrl, { replace: true });
        }
    }, [productCategoryReplacementUrl]);

    if (hasFetched && !pageData) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    return (
        <div>
            {children({
                ssrQuery: ssrProductCategoryQuery,
                callToActions: pageData?.callToActions || [],
            })}
        </div>
    );
};
