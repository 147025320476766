import { MeilisearchAttributes } from '../@api/Sylius';

export const generateFilterQuery = (attribute: MeilisearchAttributes, value: string): string => {
    const formattedValue = value.trim();

    return `${attribute} = '${formattedValue}'`;
};

export const generateItemsByVariantIdQuery = (items: string[]): string => items
    .map(id => `${MeilisearchAttributes.id} = ${id}`)
    .join(' OR ');
