import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BrandNavigation } from '../../entities/Brand/Brand';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type BrandOverviewState = AsyncReduxState<{
    ssrBrandQuery?: string;
    navigation?: BrandNavigation;
}>;

const initialState: BrandOverviewState = {
    ...initialAsyncReduxState,
};

const brandOverviewSlice = createSlice({
    name: 'brandOverviewSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): BrandOverviewState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): BrandOverviewState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BrandOverviewState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BrandOverviewState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setNavigation(state, action: PayloadAction<BrandNavigation | undefined>): BrandOverviewState {
            return {
                ...state,
                navigation: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setNavigation,
} = brandOverviewSlice.actions;

export default brandOverviewSlice;
