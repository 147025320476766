import { FC, ReactElement } from 'react';

import { useRefinementList } from 'react-instantsearch';

import { Accordion } from '../../../../../compositions';
import { ProductFilter } from '../../../../../entities/ProductFilters/ProductFilters';
import { InstantSearchRefinementList } from '../../../InstantSearchRefinementList/InstantSearchRefinementList';

import './FilterAccordion.scss';

interface FilterAccordionProps extends ProductFilter {
    className?: string;
}

export const FilterAccordion: FC<FilterAccordionProps> = ({
    label,
    attribute,
    operator,
    className = '',
}): ReactElement | null => {
    const { canRefine, items, refine } = useRefinementList({
        attribute,
        operator,
        sortBy: ['name:asc'],
        limit: 50,
    });

    if (!canRefine) {
        return null;
    }

    return (
        <Accordion
            hasBorder
            key={attribute}
            header={label}
            className={`filter-accordion ${className}`}
            headingClassName="filter-accordion__heading"
        >
            <InstantSearchRefinementList
                rendersInitially
                label={label}
                items={items}
                onToggle={refine}
            />
        </Accordion>
    );
};
