import { FC, ReactElement } from 'react';

import { Address } from '../../../components';
import { AccountInfoBlock, AccountLabelledValue } from '../../../compositions';
import { noDataLabel } from '../../../constants';
import { EnrichedOrderDetailInterface } from '../../../entities/@account/OrderDetail/OrderDetail';
import { useTrans } from '../../../hooks';
import { OrderDetailRendererSkeletons } from './skeletons';
import { OrderDetailPriceTable, OrderDetailProductVariant } from './subcomponents';

import './OrderDetailRenderer.scss';

interface OrderDetailRendererProps {
    isLoading?: boolean;
    order?: EnrichedOrderDetailInterface;
    className?: string;
}

export const OrderDetailRenderer: FC<OrderDetailRendererProps> = ({
    isLoading,
    order,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    if (isLoading || !order) {
        return (
            <OrderDetailRendererSkeletons className={`order-detail-renderer ${className}`} />
        );
    }

    return (
        <div className={`order-detail-renderer ${className}`}>
            <h1 className="order-detail-renderer__title">
                {trans('containers.orderDetailRenderer.title', {
                    orderNumber: order.orderNumber,
                })}
            </h1>

            <div className="order-detail-renderer__wrapper">
                <div className="order-detail-renderer__product-variant-wrapper">
                    <ul className="order-detail-renderer__product-variant-list">
                        {order.productVariants.map(productVariant => (
                            <OrderDetailProductVariant
                                key={productVariant.id}
                                productVariant={productVariant}
                                className="order-detail-renderer__product-variant"
                            />
                        ))}
                    </ul>
                </div>

                <div className="order-detail-renderer__detail-wrapper">
                    <AccountInfoBlock
                        title={trans('containers.orderDetailRenderer.order.title')}
                        className="order-detail-renderer__info-block"
                    >
                        <AccountLabelledValue
                            label={trans('containers.orderDetailRenderer.order.orderNumber')}
                            value={order.orderNumber}
                            className="order-detail-renderer__labelled-value"
                        />

                        <AccountLabelledValue
                            label={trans('containers.orderDetailRenderer.order.date')}
                            value={noDataLabel}
                            className="order-detail-renderer__labelled-value"
                        />

                        <OrderDetailPriceTable
                            order={order}
                            className="order-detail-renderer__price-table"
                        />
                    </AccountInfoBlock>

                    <AccountInfoBlock
                        hasBorder
                        title={trans('containers.orderDetailRenderer.status.title')}
                        className="order-detail-renderer__info-block"
                    >
                        {noDataLabel}
                    </AccountInfoBlock>

                    <AccountInfoBlock
                        hasBorder
                        title={trans('containers.orderDetailRenderer.paymentMethod.title')}
                        className="order-detail-renderer__info-block"
                    >
                        {noDataLabel}
                    </AccountInfoBlock>

                    <AccountInfoBlock
                        title={trans('containers.orderDetailRenderer.cancelled.title')}
                        className="order-detail-renderer__info-block"
                    >
                        {noDataLabel}
                    </AccountInfoBlock>

                    {order.shippingAddress && (
                        <AccountInfoBlock
                            title={trans('containers.orderDetailRenderer.shippingAddress.title')}
                            className="order-detail-renderer__info-block"
                        >
                            <Address
                                address={order.shippingAddress}
                                className="order-detail-renderer__address"
                            />
                        </AccountInfoBlock>
                    )}

                    {order.billingAddress && (
                        <AccountInfoBlock
                            title={trans('containers.orderDetailRenderer.billingAddress.title')}
                            className="order-detail-renderer__info-block"
                        >
                            <Address
                                address={order.billingAddress}
                                className="order-detail-renderer__address"
                            />
                        </AccountInfoBlock>
                    )}
                </div>
            </div>
        </div>
    );
};
