import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { useLocation } from 'react-router-dom';

import { scrollToTop } from '../../../helpers/scroll';
import { useClientEffect } from '../../../hooks';
import { setAllowScrollReset } from '../../../redux/app/appSlice';
import { fetchCustomer } from '../../../redux/customer/customerActions';
import { fetchCustomPageSlugs } from '../../../redux/customPages/customPagesActions';
import { fetchProductCategorySlugs } from '../../../redux/productCategories/productCategoriesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

export const ConnectedAppWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const { pathname, hash } = useLocation();

    const dispatch = useTypedDispatch();

    const allowScrollReset = useTypedSelector(state => state.appSlice.allowScrollReset);

    useClientEffect((): void => {
        dispatch(fetchCustomPageSlugs());
        dispatch(fetchProductCategorySlugs());
    }, []);

    useEffect((): void => {
        dispatch(fetchCustomer({ fetchCartAndWishlist: true }));
    }, []);

    useEffect((): void => {
        if (allowScrollReset && !hash) {
            scrollToTop('auto');
        }
    }, [pathname]);

    // Reset scroll lock boolean
    useEffect((): void => {
        if (!allowScrollReset) {
            dispatch(setAllowScrollReset(true));
        }
    }, [allowScrollReset]);

    return <div>{children}</div>;
};
