import {
    FC,
    FocusEvent,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ErrorLabel } from '../../../components';
import { Button, RecaptchaDisclaimer, TextInput } from '../../../compositions';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { GiftcardBalanceFormData } from '../../../entities/@forms/GiftcardBalanceForm/GiftcardBalanceForm';
import { convertNumberToCurrency } from '../../../helpers/number';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { GiftcardBalanceFormErrors, validateGiftcardBalanceFormData } from './validations';

import './GiftcardBalanceForm.scss';

interface GiftcardBalanceFormProps extends FormProps<GiftcardBalanceFormData> {
    balance?: number
    className?: string;
}

export const GiftcardBalanceForm: FC<GiftcardBalanceFormProps> = ({
    isLoading,
    balance,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);

    const [formErrors, setFormErrors] = useState<GiftcardBalanceFormErrors>({});

    const cardNumberBRef = useRef<HTMLInputElement>(null);
    const cardNumberCRef = useRef<HTMLInputElement>(null);
    const cardNumberDRef = useRef<HTMLInputElement>(null);
    const cardNumberERef = useRef<HTMLInputElement>(null);
    const scratchCodeRef = useRef<HTMLInputElement>(null);

    const [cardNumberA, setCardNumberA] = useState<string>('');
    const [cardNumberB, setCardNumberB] = useState<string>('');
    const [cardNumberC, setCardNumberC] = useState<string>('');
    const [cardNumberD, setCardNumberD] = useState<string>('');
    const [cardNumberE, setCardNumberE] = useState<string>('');

    const [scratchCode, setScratchCode] = useState<string>('');

    const [botDetected, setBotDetected] = useState<boolean>(false);

    const handleChangeCardNumberA = (value: string): void => {
        setCardNumberA(value);

        if (value.length === 4 && cardNumberBRef.current) {
            cardNumberBRef.current.focus();
        }
    };

    const handleChangeCardNumberB = (value: string): void => {
        setCardNumberB(value);

        if (value.length === 4 && cardNumberCRef.current) {
            cardNumberCRef.current.focus();
        }
    };

    const handleChangeCardNumberC = (value: string): void => {
        setCardNumberC(value);

        if (value.length === 4 && cardNumberDRef.current) {
            cardNumberDRef.current.focus();
        }
    };

    const handleChangeCardNumberD = (value: string): void => {
        setCardNumberD(value);

        if (value.length === 4 && cardNumberERef.current) {
            cardNumberERef.current.focus();
        }
    };

    const handleChangeCardNumberE = (value: string): void => {
        setCardNumberE(value);

        if (value.length === 3 && scratchCodeRef.current) {
            scratchCodeRef.current.focus();
        }
    };

    const handleFocus = (event: FocusEvent<HTMLInputElement>): void => {
        event.currentTarget.select();
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const formData: GiftcardBalanceFormData = {
            cardNumber: [cardNumberA, cardNumberB, cardNumberC, cardNumberD, cardNumberE].join('-'),
            pin: scratchCode,
        };

        const [errors, hasErrors] = validateGiftcardBalanceFormData(formData);
        const token = await executeRecaptcha();

        setBotDetected(!token);
        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <div className={`giftcard-balance-form ${className}`}>
            <div className="giftcard-balance-form__header">
                <h6 className="giftcard-balance-form__title">
                    {trans('containers.giftcardBalanceForm.title')}
                </h6>
            </div>

            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="giftcard-balance-form__form"
            >
                <div className="giftcard-balance-form__card-wrapper">
                    <legend className="giftcard-balance-form__form-legend">
                        {trans('containers.giftcardBalanceForm.cardNumber')}
                    </legend>

                    <div className="giftcard-balance-form__card-number-wrapper">
                        <TextInput
                            hideLabel
                            label={trans('containers.giftcardBalanceForm.cardNumberStart')}
                            value={cardNumberA}
                            placeholder="0000"
                            maxLength={4}
                            disabled={isLoading}
                            onChange={handleChangeCardNumberA}
                            onFocus={handleFocus}
                            className="giftcard-balance-form__card-number-input"
                            fieldClassName="giftcard-balance-form__card-number-field"
                        />

                        <TextInput
                            ref={cardNumberBRef}
                            hideLabel
                            label={trans('containers.giftcardBalanceForm.cardNumberProgress')}
                            value={cardNumberB}
                            placeholder="0000"
                            maxLength={4}
                            disabled={isLoading}
                            onChange={handleChangeCardNumberB}
                            onFocus={handleFocus}
                            className="giftcard-balance-form__card-number-input"
                            fieldClassName="giftcard-balance-form__card-number-field"
                        />

                        <TextInput
                            ref={cardNumberCRef}
                            hideLabel
                            label={trans('containers.giftcardBalanceForm.cardNumberProgress')}
                            value={cardNumberC}
                            placeholder="0000"
                            maxLength={4}
                            disabled={isLoading}
                            onChange={handleChangeCardNumberC}
                            onFocus={handleFocus}
                            className="giftcard-balance-form__card-number-input"
                            fieldClassName="giftcard-balance-form__card-number-field"
                        />

                        <TextInput
                            ref={cardNumberDRef}
                            hideLabel
                            label={trans('containers.giftcardBalanceForm.cardNumberProgress')}
                            value={cardNumberD}
                            placeholder="0000"
                            maxLength={4}
                            disabled={isLoading}
                            onChange={handleChangeCardNumberD}
                            onFocus={handleFocus}
                            className="giftcard-balance-form__card-number-input"
                            fieldClassName="giftcard-balance-form__card-number-field"
                        />

                        <TextInput
                            ref={cardNumberERef}
                            hideLabel
                            label={trans('containers.giftcardBalanceForm.cardNumberEnd')}
                            value={cardNumberE}
                            placeholder="000"
                            maxLength={3}
                            disabled={isLoading}
                            onChange={handleChangeCardNumberE}
                            onFocus={handleFocus}
                            className="giftcard-balance-form__card-number-input"
                            fieldClassName="giftcard-balance-form__card-number-field"
                        />
                    </div>

                    {formErrors.cardNumber && (
                        <ErrorLabel
                            text={formErrors.cardNumber}
                            className="giftcard-balance-form__error-label"
                        />
                    )}

                    <TextInput
                        ref={scratchCodeRef}
                        label={trans('containers.giftcardBalanceForm.scratchCode')}
                        value={scratchCode}
                        maxLength={6}
                        placeholder={trans('containers.giftcardBalanceForm.scratchCodePlaceholder')}
                        disabled={isLoading}
                        error={formErrors.pin}
                        onChange={setScratchCode}
                        onFocus={handleFocus}
                        className="giftcard-balance-form__scratch-code-input"
                    />

                    <RecaptchaDisclaimer className="giftcard-balance-form__recaptcha-disclaimer" />

                    {balance && (
                        <p className="giftcard-balance-form__giftcard-balance">
                            {trans('containers.giftcardBalanceForm.balance', {
                                balance: convertNumberToCurrency(balance, true),
                            })}
                        </p>
                    )}
                </div>

                {(error || botDetected) && (
                    <ErrorLabel
                        text={trans('containers.giftcardBalanceForm.genericError')}
                        className="giftcard-balance-form__error-label"
                    />
                )}

                <Button
                    isSmall
                    type="submit"
                    text={trans('containers.giftcardBalanceForm.submit')}
                    className="giftcard-balance-form__submit-button"
                />
            </form>
        </div>
    );
};
