import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Picture, Price } from '../../components';
import { Button, ColorSwatches, WishlistButton } from '../../compositions';
import { Product } from '../../entities/Product/Product';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';

import './ProductCard.scss';

interface ProductCardProps {
    cartIsLoading: boolean;
    wishlistIsLoading: boolean;
    product: Product;
    isInWishlist: boolean;
    onAddToCart: () => void;
    onClickWishlistButton: () => void;
    className?: string;
    imageClassname?: string;
    pictureClassname?: string;
}

export const ProductCard: FC<ProductCardProps> = ({
    cartIsLoading,
    wishlistIsLoading,
    product,
    isInWishlist,
    onAddToCart,
    onClickWishlistButton,
    className = '',
    imageClassname = '',
    pictureClassname = '',
}): ReactElement => {
    const trans = useTrans();

    const productImages = product.mediaItems.filter(mediaItem => mediaItem.image);

    const productPath = trans(appRoutes[AppRoute.productDetail].path);
    const productUrl = replaceUrlParamKeysWithValues(productPath, {
        slug: product.variantSlug,
    });

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: product.brand?.href,
    });

    const pictureClassNames = classNames('product-card__image-default', {
        'product-card__image-default--is-sold-out': productImages.length === 1 && !product.stock,
        'product-card__image-switch-primary': productImages.length > 1,
        'product-card__image-switch-primary--is-sold-out': productImages.length > 1 && !product.stock,
    }, pictureClassname);

    const secondaryPictureClassNames = classNames('product-card__image-switch-secondary', {
        'product-card__image-switch-secondary--is-sold-out': !product.stock,
    }, pictureClassname);

    return (
        <div className={`product-card ${className}`}>
            <div className="product-card__image-wrapper">
                <div className="product-card__labels">
                    {!product.stock && (
                        <p className="product-card__label product-card__label--is-sold-out">
                            {trans('containers.productCard.soldOut')}
                        </p>
                    )}

                    {/* TODO: Check if product is only online available (isOnlineOnly) (L5W-389) */}
                    <p className="product-card__label product-card__label--is-online-only">
                        {trans('containers.productCard.onlyOnline')}
                    </p>
                </div>

                <Link to={productUrl} className="product-card__image-wrapper">
                    <Picture
                        {...productImages[0]?.image}
                        className={pictureClassNames}
                        imageClassName={imageClassname}
                    />

                    {productImages.length > 1 && (
                        <Picture
                            {...productImages[1].image}
                            className={secondaryPictureClassNames}
                            imageClassName={imageClassname}
                        />
                    )}
                </Link>

                <div className="product-card__buttons">
                    <Button
                        hasAnimation
                        disabled={!product.stock || cartIsLoading}
                        text={product.stock ? trans('containers.productCard.add') : trans('containers.productCard.soldOut')}
                        onClick={onAddToCart}
                        className="product-card__cart-button"
                    />

                    <WishlistButton
                        isActive={isInWishlist}
                        disabled={wishlistIsLoading}
                        text={isInWishlist ? trans('common.removeFromWishlist') : trans('common.addToWishlist')}
                        onClick={onClickWishlistButton}
                        className="product-card__wishlist-button"
                    />
                </div>
            </div>

            <div className="product-card__info">
                {product.brand && (
                    <Link to={brandDetailUrl} className="product-card__brand-link">
                        {product.brand.label}
                    </Link>
                )}

                <Link to={productUrl} className="product-card__product-link">
                    {product.name}
                </Link>

                <Price amount={product.price} className="product-card__price" />

                {product.variants === 1 && product.colors.length > 1 && (
                    <ColorSwatches
                        max={5}
                        colors={product.colors}
                        className="product-card__color-swatches"
                    />
                )}

                {product.variants > 1 && (
                    <p className="product-card__variants">
                        <span>
                            {trans('containers.productCard.availableIn')}
                        </span>
                        <span className="product-card__variants-amount">
                            {trans('containers.productCard.variants', { amount: product.variants })}
                        </span>
                    </p>
                )}
            </div>
        </div>
    );
};
