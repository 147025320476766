import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { Picture, Price } from '../../components';
import { CartItem as CartItemInterface } from '../../entities/Cart/Cart';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';
import { IconButton, NumberInputWithControls, WishlistButton } from '../index';

import './CartItem.scss';

interface CartItemProps extends CartItemInterface {
    showWishlistButton?: boolean;
    showBigRemoveButton?: boolean;
    isEditMode?: boolean;
    isLoading?: boolean;
    wishlistItems?: string[];
    onRemoveItemFromCart?: (cartItemId: string) => void;
    onUpdateCartItem?: (cartItemId: string, quantity: number) => void;
    onUpdateCartItemIsInWishlist?: (productId: string, isInWishlist: boolean) => void;
    className?: string;
    imageClassName?: string;
}

export const CartItem: FC<CartItemProps> = ({
    showWishlistButton,
    showBigRemoveButton,
    isEditMode,
    isLoading,
    wishlistItems,
    id,
    image,
    name,
    quantity,
    slug,
    totalPrice,
    variantId,
    onRemoveItemFromCart,
    onUpdateCartItem,
    onUpdateCartItemIsInWishlist,
    className = '',
    imageClassName = '',
}): ReactElement => {
    const trans = useTrans();

    const [newQuantity, setNewQuantity] = useState<number>(quantity);
    const [isInWishlist, setIsInWishlist] = useState<boolean>(false);

    useEffect((): void => {
        if (wishlistItems?.length === 0) {
            return;
        }

        const isProductVariantInWishlist = wishlistItems?.includes(variantId);

        setIsInWishlist(!!isProductVariantInWishlist);
    }, [wishlistItems]);

    useDebounce((): void => {
        if (newQuantity && quantity !== newQuantity) {
            onUpdateCartItem?.(id, newQuantity);
        }
    }, 450, [newQuantity]);

    const handleRemoveFromCart = (): void => {
        onRemoveItemFromCart?.(id);
    };

    const handleUpdateWishlistStatus = (): void => {
        setIsInWishlist(!isInWishlist);

        onUpdateCartItemIsInWishlist?.(variantId, isInWishlist);
    };

    const productPath = trans(appRoutes[AppRoute.productDetail].path);
    const productUrl = replaceUrlParamKeysWithValues(productPath, { slug });

    return (
        <div className={`cart-item ${className}`}>
            {isEditMode && showBigRemoveButton && (
                <IconButton
                    hideLabel
                    text={trans('common.delete')}
                    icon="cross"
                    disabled={isLoading}
                    onClick={handleRemoveFromCart}
                    className="cart-item__button cart-item__button--close"
                />
            )}

            <div className="cart-item__info-wrapper">
                {image && (
                    <Picture
                        {...image}
                        className={`cart-item__image ${imageClassName}`}
                    />
                )}

                <div className="cart-item__content-wrapper">
                    <Link to={productUrl} className="cart-item__name">
                        {name}
                    </Link>

                    <div className="cart-item__price-wrapper">
                        {!isEditMode && (
                            <p className="cart-item__quantity">
                                {quantity}
                                x
                            </p>
                        )}

                        <Price
                            includeCurrencySymbol
                            amount={totalPrice}
                            className="cart-item__price"
                        />
                    </div>

                    <div className="cart-item__buttons-wrapper">
                        {isEditMode && (
                        // TODO: When product quantity has updated, the newQuantity is not updated. This is an issue in the NumberInputWithControls (<Input />)
                            <NumberInputWithControls
                                hideLabel
                                label={trans('containers.cartItem.changeQuantity')}
                                value={newQuantity}
                                min={1}
                                max={99}
                                disabled={isLoading}
                                onChange={setNewQuantity}
                                className="cart-item__quantity-input"
                                buttonClassName="cart-item__quantity-button"
                            />
                        )}

                        {showWishlistButton && (
                            <WishlistButton
                                isActive={isInWishlist}
                                text={isInWishlist ? trans('common.removeFromWishlist') : trans('common.addToWishlist')}
                                disabled={isLoading}
                                onClick={handleUpdateWishlistStatus}
                                className="cart-item__icon-button"
                            />
                        )}

                        {isEditMode && !showBigRemoveButton && (
                            <IconButton
                                hideLabel
                                text={trans('common.delete')}
                                icon="cross"
                                disabled={isLoading}
                                onClick={handleRemoveFromCart}
                                className="cart-item__icon-button"
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
