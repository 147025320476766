import { FC, memo, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './OrderDetailRendererSkeletons.scss';

interface OrderDetailRendererSkeletonsProps {
    className?: string;
}

export const OrderDetailRendererSkeletons: FC<OrderDetailRendererSkeletonsProps> = memo(({
    className = '',
}): ReactElement => {
    const numberOfProductSkeletons = randomInBetweenValue(2, 5);
    const productSkeletons = generateIdArray(numberOfProductSkeletons);

    const numberOfPriceTableRows = 3;
    const priceTableRowSkeletons = generateIdArray(numberOfPriceTableRows);

    const numberOfExtraInfoBlocks = randomInBetweenValue(3, 5);
    const extraInfoBlockSkeletons = generateIdArray(numberOfExtraInfoBlocks);

    return (
        <div className={`order-detail-renderer-skeletons ${className}`}>
            <div className="order-detail-renderer-skeletons__title-wrapper">
                <Skeleton className="order-detail-renderer-skeletons__title" />
            </div>

            <div className="order-detail-renderer-skeletons__wrapper">
                <div className="order-detail-renderer-skeletons__product-wrapper">
                    {productSkeletons.map(productSkeleton => (
                        <div key={productSkeleton} />
                    ))}
                </div>

                <div className="order-detail-renderer-skeletons__detail-wrapper">
                    <div className="order-detail-renderer-skeletons__block">
                        <div className="order-detail-renderer-skeletons__block-header">
                            <div className="order-detail-renderer-skeletons__block-label-wrapper">
                                <Skeleton className="order-detail-renderer-skeletons__block-label" />
                            </div>
                        </div>

                        <div className="order-detail-renderer-skeletons__labelled-value">
                            <div className="order-detail-renderer-skeletons__label-wrapper">
                                <Skeleton className="order-detail-renderer-skeletons__label" />
                            </div>

                            <Skeleton className="order-detail-renderer-skeletons__value" />
                        </div>

                        <div className="order-detail-renderer-skeletons__labelled-value">
                            <div className="order-detail-renderer-skeletons__label-wrapper">
                                <Skeleton className="order-detail-renderer-skeletons__label" />
                            </div>

                            <Skeleton className="order-detail-renderer-skeletons__value" />
                        </div>

                        <div className="order-detail-renderer-skeletons__price-table">
                            {priceTableRowSkeletons.map(priceTableRowSkeleton => (
                                <div key={priceTableRowSkeleton} className="order-detail-renderer-skeletons__price-table-row">
                                    <div className="order-detail-renderer-skeletons__price-table-cell">
                                        <Skeleton className="order-detail-renderer-skeletons__price-label" />
                                    </div>

                                    <div className="order-detail-renderer-skeletons__price-table-cell">
                                        <Skeleton className="order-detail-renderer-skeletons__price" />
                                    </div>
                                </div>
                            ))}

                            <div className="order-detail-renderer-skeletons__price-table-footer">
                                <div className="order-detail-renderer-skeletons__price-table-cell">
                                    <Skeleton className="order-detail-renderer-skeletons__price-label" />
                                </div>

                                <div className="order-detail-renderer-skeletons__price-table-cell">
                                    <Skeleton className="order-detail-renderer-skeletons__price" />
                                </div>
                            </div>
                        </div>
                    </div>

                    {extraInfoBlockSkeletons.map(extraInfoBlockSkeleton => (
                        <div key={extraInfoBlockSkeleton} className="order-detail-renderer-skeletons__block">
                            <div className="order-detail-renderer-skeletons__block-header">
                                <div className="order-detail-renderer-skeletons__block-label-wrapper">
                                    <Skeleton className="order-detail-renderer-skeletons__block-label" />
                                </div>
                            </div>

                            <div className="order-detail-renderer-skeletons__labelled-value">
                                <div className="order-detail-renderer-skeletons__label-wrapper">
                                    <Skeleton className="order-detail-renderer-skeletons__label" />
                                </div>

                                <Skeleton className="order-detail-renderer-skeletons__value" />
                            </div>

                            <div className="order-detail-renderer-skeletons__labelled-value">
                                <div className="order-detail-renderer-skeletons__label-wrapper">
                                    <Skeleton className="order-detail-renderer-skeletons__label" />
                                </div>

                                <Skeleton className="order-detail-renderer-skeletons__value" />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});
