import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { WishlistItems } from '../../containers';
import { generateItemsByVariantIdQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { useTypedSelector } from '../../redux/store';

interface ConnectedWishlistItemsProps {
    className?: string;
}

export const ConnectedWishlistItems: FC<ConnectedWishlistItemsProps> = ({
    className = '',
}): ReactElement => {
    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect((): void => {
        if (!wishlist.productVariantIds) {
            return;
        }

        const query = generateItemsByVariantIdQuery(wishlist.productVariantIds);

        setProductsQuery(query);
    }, [wishlist]);

    return (
        <WishlistItems
            productsQuery={productsQuery}
            resultsCount={wishlist.productVariantIds.length}
            className={className}
        />
    );
};
