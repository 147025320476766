import { FC, PropsWithChildren, ReactElement } from 'react';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchSpaces } from '../../../redux/space/spaceActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

type ConnectedSpacesOverviewWrapperProps = PropsWithChildren;

export const ConnectedSpacesOverviewWrapper: FC<ConnectedSpacesOverviewWrapperProps> = ({
    children,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.spaceSlice.hasFetched);
    const spaces = useTypedSelector(state => state.spaceSlice.spaces);

    useClientEffect((): void => {
        dispatch(fetchSpaces());
    }, []);

    if (hasFetched && !spaces) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    return (
        <div>
            {children}
        </div>
    );
};
