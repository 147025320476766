import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../../components';
import { NotFound } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchBrand } from '../../../redux/brand/brandActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductCategoryChildProps {
    ssrQuery?: string;
}

interface ConnectedBrandDetailWrapperProps {
    children: (childProps: ConnectedProductCategoryChildProps) => ReactElement;
}

export const ConnectedBrandDetailWrapper: FC<ConnectedBrandDetailWrapperProps> = ({
    children,
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.brandSlice.hasFetched);
    const ssrBrandQuery = useTypedSelector(state => state.brandSlice.ssrBrandQuery);
    const brand = useTypedSelector(state => state.brandSlice.brand);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchBrand(slug));
        }
    }, [slug]);

    if (hasFetched && !brand) {
        return (
            <Page>
                <NotFound />
            </Page>
        );
    }

    return (
        <div>
            {children({
                ssrQuery: ssrBrandQuery,
            })}
        </div>
    );
};
