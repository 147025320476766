import qs from 'qs';

import { strapiBlockPopulates } from '../@api/Strapi';

export const generateStoresQuery = (): string => {
    const populate = {
        openingHours: {
            populate: '*',
        },
        adjustedOpeningHours: {
            populate: '*',
        },
        address: '*',
    };

    return qs.stringify({ populate }, { encode: true });
};

export const generateStoreQuery = (slug: string): string => {
    const filters = {
        slug: { $eq: slug },
    };

    const populate = {
        openingHours: {
            populate: '*',
        },
        adjustedOpeningHours: {
            populate: '*',
        },
        address: '*',
        image: {
            fields: ['*'],
        },
        elements: strapiBlockPopulates,
        seo: { populate: '*' },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
