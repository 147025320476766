import { generateUuid } from '../../../helpers/string';
import trans from '../../../helpers/trans';
import { StrapiEntityData } from '../../@api/Strapi';
import { DateIsoString } from '../../Date/Date';

export interface BlogCategoryResource extends StrapiEntityData {
    title: string;
    locale: unknown;
    order?: number;
    createdAt: DateIsoString;
    publishedAt: DateIsoString;
    updatedAt: DateIsoString;
}

export interface BlogCategory {
    id: string;
    title: string;
    slug: string;
    order: number;
}

export const defaultBlogCategory = (): BlogCategory => ({
    id: generateUuid(),
    title: trans('entities.blogCategory.all.label'),
    slug: trans('entities.blogCategory.all.value'),
    order: 0,
});
