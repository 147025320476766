import { FC, ReactElement } from 'react';

import { Address } from '../../../components';
import { LinkIconButton } from '../../../compositions';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { Store } from '../../../entities/Store/Store';
import { getStoreOpeningStatus } from '../../../helpers/storeDayOpeningStatus';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import { useTrans } from '../../../hooks';
import { StoreOverviewSkeletons } from './skeletons';

import './StoreOverview.scss';

interface StoreOverviewProps {
    isLoading?: boolean;
    stores: Store[];
    className?: string;
}

export const StoreOverview: FC<StoreOverviewProps> = ({
    isLoading,
    stores,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const storePath = trans(appRoutes[AppRoute.storeDetail].path);

    if (isLoading) {
        return (
            <StoreOverviewSkeletons className={`store-overview ${className}`} />
        );
    }

    return (
        <ul className={`store-overview ${className}`}>
            {stores.map(store => {
                const storeUrl = replaceUrlParamKeysWithValues(storePath, {
                    slug: store.slug,
                });

                return (
                    <li key={store.title} className="store-overview__item">
                        <h3 className="store-overview__title">
                            {store.title}
                        </h3>

                        <Address
                            address={store.address}
                            className="store-overview__address"
                        />

                        <p className="store-overview__opening-hours">
                            {getStoreOpeningStatus(
                                store.openingHours,
                                store.adjustedOpeningHours,
                            )}
                        </p>

                        <LinkIconButton
                            isSmall
                            hasAnimation
                            to={storeUrl}
                            iconPos={HorizontalAlignment.right}
                            icon="arrow-right"
                            text={trans('common.moreInfo')}
                            className="store-overview__link-button"
                        />
                    </li>
                );
            })}
        </ul>
    );
};
