import { FC, ReactElement } from 'react';

import { StoreOverview } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedStoreOverviewProps {
    className?: string;
}

export const ConnectedStoreOverview: FC<ConnectedStoreOverviewProps> = ({
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.storesSlice.isLoading);
    const stores = useTypedSelector(state => state.storesSlice.stores);

    return (
        <StoreOverview
            isLoading={isLoading}
            stores={stores}
            className={className}
        />
    );
};
