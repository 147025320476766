import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { Checkout } from '../../containers';
import { StartPaymentFormData } from '../../entities/@api/Sylius';
import { CreateAccountDuringCheckoutFormData, UpdateCartFormData } from '../../entities/@forms/GuestForm/GuestForm';
import { NewsletterFormData } from '../../entities/@forms/NewsletterForm/NewsletterForm';
import { PaymentStepFormData } from '../../entities/@forms/PaymentForm/PaymentForm';
import { Address } from '../../entities/Address/Address';
import { CheckoutTab } from '../../entities/Checkout/Checkout';
import { LinkTarget } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { isSSR } from '../../helpers';
import { convertPriceInEurosToCents } from '../../helpers/number';
import { useTrans } from '../../hooks';
import { MOCK_USPS } from '../../mock/mock-data/usp';
import { signUpForNewsletter } from '../../redux/@forms/newsletterForm/newsletterFormActions';
import { clearCart, removeProductVariantFromCart, updateProductVariantInCart } from '../../redux/cart/cartActions';
import { fetchCustomer } from '../../redux/customer/customerActions';
import {
    connectOrderToCustomer,
    getPaymentMethods,
    payOrder,
    updateOrderInfo,
} from '../../redux/order/orderActions';
import { registerUserDuringCheckout } from '../../redux/registration/registrationActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedCheckoutProps {
    tab?: CheckoutTab;
}

export const ConnectedCheckout: FC<ConnectedCheckoutProps> = ({
    tab,
}): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    const customer = useTypedSelector(state => state.customerSlice.customer);

    const cartIsLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const orderIsLoading = useTypedSelector(state => state.orderSlice.isLoading);
    const order = useTypedSelector(state => state.orderSlice.order);

    const isLoading = cartIsLoading || orderIsLoading;

    const [addresses, setAddresses] = useState<Address[]>([]);

    useEffect((): void => {
        if (order.addresses?.length) {
            setAddresses(order.addresses);
            return;
        }

        if (customer?.addresses) {
            setAddresses(customer.addresses);
        }
    }, [customer, order]);

    useEffect((): void => {
        if (tab === CheckoutTab.payment && order.id && order.items.length && !order.paymentMethods?.length) {
            dispatch(getPaymentMethods());
        }
    }, [order, tab]);

    useEffect((): void => {
        if (order.paymentEnvironmentUrl) {
            if (!isSSR) {
                window.open(order.paymentEnvironmentUrl, LinkTarget.self);
            }
        }
    }, [order.paymentEnvironmentUrl]);

    useEffect((): void => {
        if (order.items.length === 0) {
            navigate(trans(appRoutes[AppRoute.checkoutOrder].path));
        }
    }, [tab, order]);

    const handleAfterLogin = async (): Promise<void> => {
        await dispatch(fetchCustomer());

        await dispatch(connectOrderToCustomer());
    };

    const handleContinueAsGuest = async (formData: UpdateCartFormData): Promise<void> => {
        await dispatch(updateOrderInfo(formData));

        if (formData.subscribedToNewsletter) {
            const body: NewsletterFormData = {
                email: formData.email || '',
            };

            await dispatch(signUpForNewsletter(body));
        }

        navigate(trans(appRoutes[AppRoute.checkoutPayment].path));
    };

    const handleCreateAccount = async (formData: CreateAccountDuringCheckoutFormData): Promise<void> => {
        await dispatch(registerUserDuringCheckout(formData));

        if (customer) {
            const body: UpdateCartFormData = {
                email: formData.email,
                shippingAddress: formData.shippingAddress,
                billingAddress: formData.billingAddress,
            };

            await dispatch(updateOrderInfo(body));
        }
    };

    const handleSelectPayment = async (data: StartPaymentFormData): Promise<void> => {
        const body: StartPaymentFormData = {
            ...data,
            expectedPriceInCents: convertPriceInEurosToCents(order.totalPrice),
        };

        dispatch(payOrder(body));
    };

    const handleLastStep = async (data: PaymentStepFormData): Promise<void> => {
        const body: UpdateCartFormData = {
            email: customer?.email,
            shippingAddress: data.shippingAddress,
            billingAddress: data.billingAddress,
        };

        await dispatch(updateOrderInfo(body));

        await handleSelectPayment(data);
    };

    const handleUpdateCartItemQuantity = async (id: string, quantity: number): Promise<void> => {
        await dispatch(updateProductVariantInCart(id, quantity));
    };

    const handleRemoveItemFromCart = async (id: string): Promise<void> => {
        await dispatch(removeProductVariantFromCart(id));
    };

    const handleClearCart = async (): Promise<void> => {
        await dispatch(clearCart());
    };

    return (
        <Checkout
            {...order}
            isLoading={isLoading}
            isAuthenticated={isAuthenticated}
            tab={tab}
            addresses={addresses}
            paymentMethods={order?.paymentMethods || []}
            uspItems={MOCK_USPS} // TODO: Replace with data from Strapi
            onLoggedIn={handleAfterLogin}
            onContinueAsGuest={handleContinueAsGuest}
            onCreateAccount={handleCreateAccount}
            onGoToPay={handleLastStep}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onClearCart={handleClearCart}
        />
    );
};
