import {
    authorizedSyliusFetch,
    CreateAddressResponse,
    GetAddressesResponse,
    GetCustomerResponse,
    Token,
    tokenKey,
    UpdateAddressResponse,
    UpdateCustomerResponse,
} from '../../entities/@api/Sylius';
import { Address, AddressType } from '../../entities/Address/Address';
import { transformToAddressId, transformToCustomerAddress } from '../../entities/Address/AddressTransformers';
import { Customer } from '../../entities/Customer/Customer';
import { transformAddressToAddressFormData, transformToCustomer } from '../../entities/Customer/CustomerTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import trans from '../../helpers/trans';
import { setIsAuthenticated } from '../authentication/authenticationSlice';
import { getCart } from '../cart/cartActions';
import { ReduxFetchAction } from '../defaults';
import { addPositiveToast } from '../toast/toastActions';
import { getWishList } from '../wishlist/wishlistActions';
import {
    setCustomer,
    setError,
    setHasFetched,
    setIsLoading,
} from './customerSlice';

export const getAddresses = async (defaultAddressId: string): Promise<Address[]> => {
    try {
        const addressesResponse = await authorizedSyliusFetch<GetAddressesResponse>('/shop/addresses');

        if (!isFetchResultSuccessful(addressesResponse)) {
            return [];
        }

        const addressesResource = addressesResponse.data['hydra:member'];

        return addressesResource.map(item => {
            const type = item.id === Number(defaultAddressId)
                ? AddressType.shipping
                : AddressType.billing;

            return transformToCustomerAddress(item, type);
        });
    } catch (error) {
        console.error('[getAddresses]', error);
        return [];
    }
};

interface FetchCustomerOptions {
    fetchCartAndWishlist?: boolean;
}

export const fetchCustomer: ReduxFetchAction<FetchCustomerOptions| undefined> = (options = {}) => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
        const tokenData = storedToken
            ? JSON.parse(storedToken) as Token
            : undefined;

        const customerId = tokenData?.customerId || '';

        if (!customerId) {
            if (options?.fetchCartAndWishlist) {
                dispatch(getCart());
                dispatch(getWishList());
            }
            dispatch(setIsAuthenticated(false));
            return;
        }

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customerId}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const customerResource = customerResponse.data;
        const shippingAddressId = customerResource.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const customer = transformToCustomer(customerResource, customerId, addresses);
        dispatch(setCustomer(customer));
        dispatch(setIsAuthenticated(true));

        if (options?.fetchCartAndWishlist) {
            dispatch(getCart());
            dispatch(getWishList());
        }
    } catch (error) {
        console.error('[fetchCustomer]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};

export const updateCustomer: ReduxFetchAction<Customer> = newCustomer => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const customerId = customer?.id || '';

        const customerResponse = await authorizedSyliusFetch<UpdateCustomerResponse>(`/shop/customers/${customerId}`, {
            method: 'PUT',
            body: JSON.stringify(newCustomer),
        });

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const updatedCustomer = transformToCustomer(customerResponse.data, customerId, newCustomer.addresses);
        dispatch(setCustomer(updatedCustomer));

        dispatch(addPositiveToast({
            title: trans('redux.customer.updateProfileSuccessMessageTitle'),
            description: trans('redux.customer.updateProfileSuccessMessageDescription'),
        }));
    } catch (error) {
        console.error('[updateCustomer]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateCustomerAddress: ReduxFetchAction<Address> = address => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        if (!customer) {
            console.error('[updateCustomerAddress] No customer found');
            return;
        }

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customer.id}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const addressId = transformToAddressId(address.id);
        const body = transformAddressToAddressFormData(address, customer);

        const updateAddressResponse = await authorizedSyliusFetch<UpdateAddressResponse>(`/shop/addresses/${addressId}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateAddressResponse)) {
            dispatch(setError(updateAddressResponse.error));
            return;
        }

        const customerResource = customerResponse.data;
        const shippingAddressId = transformToAddressId(customerResource.defaultAddress || '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const updatedCustomer = transformToCustomer(customerResource, customer.id, addresses);

        dispatch(setCustomer(updatedCustomer));

        dispatch(addPositiveToast({
            title: trans('redux.customer.updateProfileSuccessMessageTitle'),
            description: trans('redux.customer.updateProfileSuccessMessageDescription'),
        }));
    } catch (error) {
        console.error('[updateAddress]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addAddress: ReduxFetchAction<Address> = address => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const body = transformAddressToAddressFormData(address, customer);

        const addAddressResponse = await authorizedSyliusFetch<CreateAddressResponse>('/shop/addresses', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(addAddressResponse)) {
            dispatch(setError(addAddressResponse.error));
            return;
        }

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customer?.id}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const customerResource = customerResponse.data;
        const shippingAddressId = customerResource.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const updatedCustomer = transformToCustomer(customerResource, customer?.id || '', addresses);

        dispatch(setCustomer(updatedCustomer));
    } catch (error) {
        console.error('[addAddress]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateDefaultAddress: ReduxFetchAction<string> = addressId => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const body = { defaultAddress: `/api/v2/shop/addresses/${addressId}` };

        const updateDefaultAddressResponse = await authorizedSyliusFetch<UpdateCustomerResponse>(`/shop/customers/${customer?.id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateDefaultAddressResponse)) {
            dispatch(setError(updateDefaultAddressResponse.error));
            return;
        }

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customer?.id}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const customerResource = customerResponse.data;
        const shippingAddressId = customerResource.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const updatedCustomer = transformToCustomer(customerResource, customer?.id || '', addresses);
        dispatch(setCustomer(updatedCustomer));

        dispatch(addPositiveToast({
            title: trans('redux.customer.updateProfileSuccessMessageTitle'),
            description: trans('redux.customer.updateProfileSuccessMessageDescription'),
        }));
    } catch (error) {
        console.error('[updateDefaultAddress]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
