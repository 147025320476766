import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { ConnectedBlockRenderer, ConnectedRegistrationForm } from '../../connectors';
import { AppRoute } from '../../entities/Routing/Routing';

import './RegistrationPage.scss';

interface RegistrationPageProps {
    className?: string;
}

export const RegistrationPage: FC<RegistrationPageProps> = ({
    className = '',
}): ReactElement => (
    <Page className={`registration-page ${className}`}>
        <Wrapper className="registration-page__wrapper">
            <ConnectedRegistrationForm className="registration-page__registration-form" />
        </Wrapper>

        <ConnectedBlockRenderer appRoute={AppRoute.registration} className="registration-page__section" />
    </Page>
);
