import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
} from 'react';

import { useConfigure, useHits, useInstantSearch } from 'react-instantsearch';

import { InstantSearchPagesList } from '../../../containers';
import { InstantSearchPage } from '../../../entities/@api/Sylius';
import { InstantSearchStatus } from '../../../entities/Meilisearch/Meilisearch';
import { PageSearchResult } from '../../../entities/PageSearchResult/PageSearchResult';
import { transformPageHitToPageSearchResult } from '../../../entities/PageSearchResult/PageSearchResultTransformers';

interface ConnectedInstantSearchPageResultsListProps {
    query: string;
    onTotalResults?: (totalResults: number) => void;
    className?: string;
}

export const ConnectedInstantSearchPageResultsList: FC<ConnectedInstantSearchPageResultsListProps> = ({
    query,
    onTotalResults,
    className = '',
}): ReactElement => {
    const { items } = useHits<InstantSearchPage>();
    const { status } = useInstantSearch();
    const { refine: configureRefine } = useConfigure({
        query,
    });

    const isLoading = status === InstantSearchStatus.loading;

    const pages = useMemo((): PageSearchResult[] => (
        items.map(transformPageHitToPageSearchResult)
    ), [items]);

    useEffect((): void => {
        configureRefine({ query });
    }, []);

    useEffect((): void => {
        if (onTotalResults) {
            onTotalResults(items.length);
        }
    }, [items, onTotalResults]);

    return (
        <InstantSearchPagesList
            isLoading={isLoading}
            pages={pages}
            className={className}
        />
    );
};
