import trans from '../../helpers/trans';
import {
    InstantSearchBlogPostHitResource,
    InstantSearchBrandHitResource,
    InstantSearchCategoryHitResource,
    InstantSearchDepartmentHitResource,
    InstantSearchPage,
    InstantSearchPageHitResource,
    InstantSearchPageId,
    InstantSearchPageType,
    InstantSearchPageTypeResource,
    InstantSearchStoreHitResource,
} from '../@api/Sylius';
import { Link } from '../Link/Link';
import { transformInstantSearchPageLinkToLink } from '../Link/LinkTransformers';
import { PageSearchResult } from './PageSearchResult';

const transformToPageSearchResultTypeLabel = (resource: InstantSearchPage, type: InstantSearchPageType): string => {
    if (type === InstantSearchPageType.page) {
        return (resource as InstantSearchPageHitResource).fixedType || '';
    }

    if (type === InstantSearchPageType.blog) {
        return (resource as InstantSearchBlogPostHitResource).blogCategory?.title || '';
    }

    if (type === InstantSearchPageType.brand) {
        return trans(`entities.pageType.${InstantSearchPageType.brand}`);
    }

    if (type === InstantSearchPageType.department) {
        return trans(`entities.pageType.${InstantSearchPageType.department}`);
    }

    if (type === InstantSearchPageType.category) {
        return trans(`entities.pageType.${InstantSearchPageType.category}`);
    }

    if (type === InstantSearchPageType.store) {
        return trans(`entities.pageType.${InstantSearchPageType.store}`);
    }

    return '';
};

const transformToPageSearchResultDescription = (resource: InstantSearchPage, type: InstantSearchPageType): string => {
    if (type === InstantSearchPageType.page) {
        return (resource as InstantSearchPageHitResource).description;
    }

    if (type === InstantSearchPageType.blog) {
        return (resource as InstantSearchBlogPostHitResource).intro;
    }

    if (type === InstantSearchPageType.brand) {
        return (resource as InstantSearchBrandHitResource).description;
    }

    if (type === InstantSearchPageType.department) {
        return (resource as InstantSearchDepartmentHitResource).intro;
    }

    if (type === InstantSearchPageType.store) {
        return (resource as InstantSearchStoreHitResource).description;
    }

    return '';
};

export const transformToPageSearchResultTitle = (resource: InstantSearchPage, type: InstantSearchPageType): string => {
    if (type === InstantSearchPageType.page) {
        return (resource as InstantSearchPage).title;
    }

    if (type === InstantSearchPageType.blog) {
        return (resource as InstantSearchBlogPostHitResource).title;
    }

    if (type === InstantSearchPageType.brand) {
        return (resource as InstantSearchBrandHitResource).name;
    }

    if (type === InstantSearchPageType.department) {
        return (resource as InstantSearchDepartmentHitResource).title;
    }

    if (type === InstantSearchPageType.category) {
        return (resource as InstantSearchCategoryHitResource).title;
    }

    if (type === InstantSearchPageType.store) {
        return (resource as InstantSearchStoreHitResource).title;
    }

    return '';
};

const getType = (resource: InstantSearchPageId): InstantSearchPageType => {
    if (resource.includes(InstantSearchPageTypeResource.blog)) {
        return InstantSearchPageType.blog;
    }

    if (resource.includes(InstantSearchPageTypeResource.brand)) {
        return InstantSearchPageType.brand;
    }

    if (resource.includes(InstantSearchPageTypeResource.department)) {
        return InstantSearchPageType.department;
    }

    if (resource.includes(InstantSearchPageTypeResource.category)) {
        return InstantSearchPageType.category;
    }

    if (resource.includes(InstantSearchPageTypeResource.store)) {
        return InstantSearchPageType.store;
    }

    return InstantSearchPageType.page;
};

export const transformPageHitToPageSearchResult = (resource: InstantSearchPage): PageSearchResult => {
    const type = getType(resource._meilisearch_id);
    const typeLabel = transformToPageSearchResultTypeLabel(resource, type);

    const title = transformToPageSearchResultTitle(resource, type);
    const link = transformInstantSearchPageLinkToLink(resource, type);
    const shortDescription = transformToPageSearchResultDescription(resource, type);

    return {
        id: resource.id.toString(),
        type: typeLabel,
        title,
        link,
        shortDescription,
    };
};

export const transformPageHitToSearchPopupPageItem = (resource: InstantSearchPage): Link => {
    const type = getType(resource._meilisearch_id);

    return transformInstantSearchPageLinkToLink(resource, type);
};
