import {
    CreateOrderItemResponse,
    CreateOrderResponse,
    DeleteOrderItemResponse,
    DeleteOrderResponse,
    GetOrderPricesResponse,
    GetOrderResponse,
    GetProductResponse,
    GetProductVariantResponse,
    PricesResource,
    SetOrderItemResponse,
    syliusCustomerOrGuestFetch,
    syliusFetch,
} from '../../entities/@api/Sylius';
import { CartInterface, CartItem, cartToken } from '../../entities/Cart/Cart';
import { transformToCart } from '../../entities/Cart/CartTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { Image } from '../../entities/Media/Media';
import { defaultImage, transformInstantSearchImageToImage } from '../../entities/Media/MediaTransformers';
import { transformToProductCode } from '../../entities/Product/ProductTransformers';
import { ReduxFetchAction } from '../defaults';
import { setOrder } from '../order/orderSlice';
import { SliceGetter, TypedDispatch } from '../store';
import {
    defaultCart,
    setCart,
    setError,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
} from './cartSlice';

const getCartItem = async (cart: CartInterface, dispatch: TypedDispatch): Promise<CartItem[]> => Promise.all(cart.items.map(async item => {
    const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${item.variantCode}`);

    if (!isFetchResultSuccessful(productVariantResponse)) {
        dispatch(setError(productVariantResponse.error));
        return item;
    }

    const productVariantResource = productVariantResponse.data;
    const { id, product } = productVariantResource;

    const productCode = transformToProductCode(product);

    const productResponse = await syliusFetch<GetProductResponse>(`/shop/products/${productCode}`);

    if (!isFetchResultSuccessful(productResponse)) {
        dispatch(setError(productResponse.error));
        return item;
    }

    const productResource = productResponse.data;
    let image: Image = defaultImage();

    if (productResource.images.length) {
        const replaceImage = {
            url: productResource.images[0].path,
            alt: productVariantResource.name || productResource.name || '',
        };

        image = transformInstantSearchImageToImage(replaceImage);
    }

    return {
        ...item,
        variantId: id?.toString() || '',
        image,
    };
}));

const createCart = async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const body = { localeCode: 'nl' };

        const createCartResponse = await syliusCustomerOrGuestFetch<CreateOrderResponse>(customer?.id, '/shop/orders', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(createCartResponse)) {
            dispatch(setError(createCartResponse.error));
            return;
        }

        const cartId = createCartResponse.data.tokenValue;

        if (!cartId) {
            console.error('Token value is missing after creating a cart.');
            return;
        }

        localStorage.setItem(cartToken, JSON.stringify(cartId));

        const cart = transformToCart(createCartResponse.data);

        dispatch(setCart(cart));
        dispatch(setOrder(cart));
    } catch (error) {
        console.error('[createCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const getCartByToken = async (dispatch: TypedDispatch, getState: SliceGetter, storedCartToken: string): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const cartTokenValue = JSON.parse(storedCartToken);

        const cartResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${cartTokenValue}`);

        if (!isFetchResultSuccessful(cartResponse)) {
            dispatch(setError(cartResponse.error));
            return;
        }

        const cartResource = cartResponse.data;
        const { tokenValue } = cartResource;

        const hasItems = cartResource.items.length > 0;

        let pricing: PricesResource | undefined;

        if (hasItems) {
            const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${tokenValue}/prices`);

            if (!isFetchResultSuccessful(pricesResponse)) {
                dispatch(setError(pricesResponse.error));
                return;
            }

            pricing = pricesResponse.data;
        }

        const cart = transformToCart(cartResource, pricing);

        const cartItems = await getCartItem(cart, dispatch);

        const newCart: CartInterface = {
            ...cart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
    } catch (error) {
        console.error('[getCartByToken]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const getCart: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const storedCartToken = localStorage.getItem(cartToken);

        if (!storedCartToken) {
            await createCart(dispatch, getState);
        } else {
            await getCartByToken(dispatch, getState, storedCartToken);
        }
    } catch (error) {
        console.error('[getCart]', error);
    }
};

export const addProductVariantToCart: ReduxFetchAction<string> = productVariantCode => async (dispatch, getState) => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const body = {
            productVariant: productVariantCode,
            quantity: 1,
        };

        if (!cart.id) {
            return;
        }

        const addProductVariantToCartResponse = await syliusCustomerOrGuestFetch<CreateOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}/items`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(addProductVariantToCartResponse)) {
            dispatch(setError(addProductVariantToCartResponse.error));
            return;
        }

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${addProductVariantToCartResponse.data.tokenValue}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const updatedCart = transformToCart(addProductVariantToCartResponse.data, pricesResponse.data);

        const cartItems = await getCartItem(updatedCart, dispatch);

        const newCart: CartInterface = {
            ...updatedCart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));

        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateProductVariantInCart = (
    cartItemId: string,
    newQuantity: number,
) => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const body = {
            quantity: newQuantity,
        };

        const updateProductVariantInCartResponse = await syliusCustomerOrGuestFetch<SetOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}/items/${cartItemId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/merge-patch+json' },
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateProductVariantInCartResponse)) {
            dispatch(setError(updateProductVariantInCartResponse.error));
            return;
        }

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${updateProductVariantInCartResponse.data.tokenValue}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const transformedCart = transformToCart(updateProductVariantInCartResponse.data, pricesResponse.data);

        const cartItems = await getCartItem(transformedCart, dispatch);

        const newCart: CartInterface = {
            ...transformedCart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
    } catch (error) {
        console.error('[updateProductVariantInCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromCart: ReduxFetchAction<string> = cartItemId => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const removeVariantResponse = await syliusCustomerOrGuestFetch<DeleteOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}/items/${cartItemId}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(removeVariantResponse)) {
            dispatch(setError(removeVariantResponse.error));
            return;
        }

        const cartResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${cart.id}`);

        if (!isFetchResultSuccessful(cartResponse)) {
            dispatch(setError(cartResponse.error));
            return;
        }

        const cartResource = cartResponse.data;
        const { tokenValue } = cartResource;

        const pricesResponse = await syliusFetch<GetOrderPricesResponse>(`/shop/orders/${tokenValue}/prices`);

        if (!isFetchResultSuccessful(pricesResponse)) {
            dispatch(setError(pricesResponse.error));
            return;
        }

        const updatedCart = transformToCart(cartResource, pricesResponse.data);

        const cartItems = await getCartItem(updatedCart, dispatch);

        const newCart: CartInterface = {
            ...updatedCart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
        dispatch(setOrder(newCart));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const clearCart: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const clearCartResponse = await syliusCustomerOrGuestFetch<DeleteOrderResponse>(customer?.id, `/shop/orders/${cart.id}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(clearCartResponse)) {
            dispatch(setError(clearCartResponse.error));
        }

        const storedCartToken = localStorage.getItem(cartToken);

        if (storedCartToken) {
            localStorage.removeItem(cartToken);
        }

        dispatch(getCart());
    } catch (error) {
        console.error('[clearCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeCartLocally: ReduxFetchAction = () => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice } = getState();
        const { cart } = cartSlice;

        if (cart.id.length) {
            dispatch(setCart(defaultCart));
            dispatch(setOrder(defaultCart));
        }

        const storedCartToken = localStorage.getItem(cartToken);

        if (storedCartToken) {
            localStorage.removeItem(cartToken);
        }

        dispatch(getCart());
    } catch (error) {
        console.error('[removeCartLocally]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
