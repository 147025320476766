import { WishlistItemResource, WishlistResource } from '../@api/Sylius';
import { Wishlist } from './Wishlist';

export const transformToCartItems = (resource: WishlistItemResource): string => resource.variant.id.toString();

export const transformToWishlist = (resource: WishlistResource): Wishlist => {
    const items = resource.wishlistProducts.map(transformToCartItems);

    return ({
        id: resource.token,
        productVariantIds: items,
    });
};
