import { authorizedSyliusFetch, GetOrderResponse } from '../../@api/Sylius';
import { isFetchResultSuccessful } from '../../FetchResult/FetchResult';
import { EnrichedOrderProductVariant, OrderBase } from '../OrderDetail/OrderDetail';
import { fetchEnrichedOrderProductVariant } from '../OrderDetail/OrderDetailService';
import { transformToOrderProductVariant } from '../OrderDetail/OrderDetailTransformers';
import { OrderOverviewOrder } from './OrderOverview';

export const fetchOrderOverviewOrder = async (orderBase: OrderBase): Promise<OrderOverviewOrder | undefined> => {
    const { tokenValue } = orderBase;

    const orderDetailResponse = await authorizedSyliusFetch<GetOrderResponse>(`/shop/orders/${tokenValue}`);

    if (!isFetchResultSuccessful(orderDetailResponse)) {
        return undefined;
    }

    const orderDetailResource = orderDetailResponse.data;
    const orderProductVariants = orderDetailResource.items.map(transformToOrderProductVariant);

    const productVariantPromises = orderProductVariants.map(fetchEnrichedOrderProductVariant);
    const resolvedProductVariantPromises = await Promise.all(productVariantPromises);
    const productVariants = resolvedProductVariantPromises.filter(Boolean) as EnrichedOrderProductVariant[];

    return { ...orderBase, productVariants };
};
