import { FC, ReactElement } from 'react';

import { AccountTabHeader } from '../../compositions';
import { ConnectedChangePasswordForm } from '../../connectors';
import { useTrans } from '../../hooks';

import './AccountChangePasswordPage.scss';

export const AccountChangePasswordPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-change-password-page">
            <AccountTabHeader
                title={trans('pages.accountChangePassword.title')}
                description={trans('pages.accountChangePassword.description')}
            />

            <ConnectedChangePasswordForm className="account-change-password-page__form" />
        </div>
    );
};
