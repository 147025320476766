import { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { RegisterCallToAction } from '../../compositions';
import { ConnectedBlockRenderer, ConnectedLoginForm } from '../../connectors';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { fetchCustomer } from '../../redux/customer/customerActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

import './LoginPage.scss';

interface LoginPageProps {
    className?: string;
}

export const LoginPage: FC<LoginPageProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();
    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.customerSlice.hasFetched);
    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    useEffect((): void => {
        if (hasFetched && isAuthenticated) {
            navigate(trans(appRoutes[AppRoute.accountDashboard].path));
        }
    }, [hasFetched, isAuthenticated]);

    const handleAfterLogin = async (): Promise<void> => {
        dispatch(fetchCustomer({
            fetchCartAndWishlist: true,
        }));

        navigate(trans(appRoutes[AppRoute.accountDashboard].path));
    };

    return (
        <Page className={`login-page ${className}`}>
            <Wrapper className="login-page__wrapper">
                <RegisterCallToAction className="login-page__register-call-to-action" />

                <ConnectedLoginForm
                    heading={trans('containers.loginForm.heading')}
                    onLoggedIn={handleAfterLogin}
                    className="login-page__login-form"
                />
            </Wrapper>

            <ConnectedBlockRenderer
                appRoute={AppRoute.login}
                className="login-page__section "
            />
        </Page>
    );
};
