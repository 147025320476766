import { FC, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../../components';

import './CategoryHeaderSkeletons.scss';

interface CategoryHeaderSkeletonsProps {
    className?: string;
}

export const CategoryHeaderSkeletons: FC<CategoryHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <Wrapper className={`category-header-skeletons ${className}`}>
        <div className="category-header-skeletons__title-wrapper">
            <Skeleton className="category-header-skeletons__title" />
        </div>

        <div className="category-header-skeletons__image" />
    </Wrapper>
);
