import { FC, ReactElement } from 'react';

import { Index } from 'react-instantsearch';

import { Page } from '../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet, ConnectedProductList } from '../../connectors';
import { MeilisearchAttributes } from '../../entities/@api/Sylius';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { AppRoute } from '../../entities/Routing/Routing';

import './OwnCollectionPage.scss';

interface OwnCollectionPageProps {
    className?: string;
}

export const OwnCollectionPage: FC<OwnCollectionPageProps> = ({
    className = '',
}): ReactElement => {
    const query = generateFilterQuery(MeilisearchAttributes.brand, 'Loods 5 Goods');

    return (
        <Page className={`own-collection-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.ownCollection} />

            <ConnectedBlockRenderer
                appRoute={AppRoute.ownCollection}
                className="own-collection-page__block-renderer"
            />

            <Index indexName={MeilisearchIndex.variantsNl}>
                <ConnectedProductList
                    showFilterBar
                    filterQuery={query}
                    className="own-collection-page__product-list"
                />
            </Index>
        </Page>
    );
};
