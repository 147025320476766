export enum ProductVariantColor {
    anthracite = 'Antraciet',
    beige = 'Beige',
    blue = 'Blauw',
    brown = 'Bruin',
    cognac = 'Cognac',
    yellow = 'Geel',
    gold = 'Goud',
    grey = 'Grijs',
    green = 'Groen',
    multicolor = 'Multicolor',
    orange = 'Oranje',
    purple = 'Paars',
    red = 'Rood',
    pink = 'Roze',
    taupe = 'Taupe',
    transparent = 'Transparant',
    turquoise = 'Turquoise',
    white = 'Wit',
    silver = 'Zilver',
    black = 'Zwart',
}

export enum ProductVariantColorVariable {
    anthracite = '--c-pv-anthracite',
    beige = '--c-pv-beige',
    blue = '--c-pv-blue',
    brown = '--c-pv-brown',
    cognac = '--c-pv-cognac',
    yellow = '--c-pv-yellow',
    gold = '--c-pv-gold',
    grey = '--c-pv-grey',
    green = '--c-pv-green',
    multicolor = '--c-pv-multicolor',
    orange = '--c-pv-orange',
    purple = '--c-pv-purple',
    red = '--c-pv-red',
    pink = '--c-pv-pink',
    taupe = '--c-pv-taupe',
    transparent = '--c-pv-transparent',
    turquoise = '--c-pv-turquoise',
    white = '--c-pv-white',
    silver = '--c-pv-silver',
    black = '--c-pv-black',
    noData = '--c-pv-no-data',
}
