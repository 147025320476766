import { Navigation } from '../../entities/Navigation/Navigation';

export const MOCK_SOCIAL_MEDIA_NAVIGATION: Navigation = {
    id: 'social',
    title: 'Social media',
    groups: [
        {
            id: '1',
            items: [
                {
                    id: '1',
                    order: 1,
                    label: 'Instagram',
                    to: 'https://www.instagram.com/Loods5/',
                    icon: 'instagram',
                },
                {
                    id: '2',
                    order: 2,
                    label: 'Facebook',
                    to: 'https://nl-nl.facebook.com/Loods5/',
                    icon: 'facebook',
                },
                {
                    id: '3',
                    order: 3,
                    label: 'Pinterest',
                    to: 'https://www.pinterest.com/Loods5/',
                    icon: 'pinterest',
                },
                {
                    id: '4',
                    order: 4,
                    label: 'YouTube',
                    to: 'https://www.youtube.com/c/loods5',
                    icon: 'youtube',
                },
                {
                    id: '5',
                    order: 5,
                    label: 'TikTok',
                    to: 'https://www.tiktok.com/@loods5',
                    icon: 'tiktok',
                },
                {
                    id: '6',
                    order: 6,
                    label: 'LinkedIn',
                    to: 'https://www.linkedin.com/company/loods-5',
                    icon: 'linkedin',
                },
            ],
        },
    ],
};
