import { generateUuid } from '../../helpers/string';
import trans from '../../helpers/trans';
import {
    Locales,
    ProductAttributeKey,
    ProductDimensionAttributeKey,
    ProductVariantResource,
} from '../@api/Sylius';
import { transformBrandNameToLink } from '../Brand/BrandTransformers';
import { transformToProductCode } from '../Product/ProductTransformers';
import { ProductAttribute, ProductDimensionAttribute } from '../ProductAttribute/ProductAttribute';
import { transformToProductAttribute } from '../ProductAttribute/ProductAttributeTransformers';
import { ProductVariantColor } from '../ProductVariantColor/ProductVariantColor';
import { ProductVariant } from './ProductVariant';

const locale = Locales.nl;

export const transformToProductVariant = (resource: ProductVariantResource): ProductVariant => {
    const productCode = transformToProductCode(resource.product);

    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const translatedProductAttributes = resource.attributes
        ? resource.attributes.filter(attribute => attribute.localeCode === locale)
        : [];

    const productVariantAttributes = translatedProductAttributes
        ? translatedProductAttributes.map(transformToProductAttribute)
        : [];

    const productVariantAttributesWithData = productVariantAttributes.filter(productVariantAttribute => productVariantAttribute.value);

    const skuAttribute = {
        key: ProductAttributeKey.sku,
        label: trans('entities.product.sku'),
        value: productCode,
    };

    const attributes: ProductAttribute[] = [
        skuAttribute,
        ...productVariantAttributesWithData,
    ];

    const dimensionAttributeValues = Object.values(ProductDimensionAttributeKey);
    const dimensionAttributes: ProductDimensionAttribute[] = dimensionAttributeValues.map(attributeKey => {
        const foundAttribute = productVariantAttributes.find(productAttribute => productAttribute.key === attributeKey);

        return {
            key: attributeKey,
            label: trans(`entities.productFilters.${attributeKey}`),
            value: foundAttribute?.value || '',
        };
    });

    const dimensionAttributesWithData = dimensionAttributes.filter(dimensionAttribute => dimensionAttribute.value);

    const attributesToExclude = [
        ...dimensionAttributeValues,
        ProductAttributeKey.brand,
    ];

    const filteredAttributes = attributes.filter(productAttribute => (
        !attributesToExclude.includes(productAttribute.key)
    ));

    attributes.length = 0;
    attributes.push(...filteredAttributes);

    const brandAttribute = productVariantAttributesWithData.find(productAttribute => productAttribute.key === ProductAttributeKey.brand);
    const brand = brandAttribute
        ? transformBrandNameToLink(brandAttribute.value)
        : undefined;

    const colorsAttribute = productVariantAttributesWithData.find(productAttribute => productAttribute.key === ProductAttributeKey.colors);
    const colors = colorsAttribute
        ? colorsAttribute.value.split(', ') as ProductVariantColor[]
        : [];

    return {
        id,
        variantId: resource.id?.toString() || '',
        variantCode: resource.code,
        name: resource.name || '',
        price: resource.price / 100,
        attributes,
        dimensionAttributes: dimensionAttributesWithData,
        brand,
        colors,
        stock: resource.inStock,
        productCode,
    };
};
