import { FC, ReactElement } from 'react';

import { Picture } from '../../../../../components';
import { LinkIconButton } from '../../../../../compositions';
import { OrderOverviewOrder } from '../../../../../entities/@account/OrderOverview/OrderOverview';
import { HorizontalAlignment } from '../../../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { convertNumberToCurrency } from '../../../../../helpers/number';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useFormatDate, useTrans } from '../../../../../hooks';

import './OrderOverviewCard.scss';

interface OrderOverviewCardProps {
    order: OrderOverviewOrder;
    className?: string;
}

export const OrderOverviewCard: FC<OrderOverviewCardProps> = ({
    order,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const orderDetailPath = trans(appRoutes[AppRoute.accountOrderDetail].path);
    const orderDetailUrl = replaceUrlParamKeysWithValues(orderDetailPath, { id: order.tokenValue });

    const productImages = order.productVariants.map(productVariant => productVariant.image);

    const maxProductImages = 4;
    const excessProductImages = productImages.length - maxProductImages;
    const truncatedProductImages = excessProductImages > 0
        ? productImages.slice(0, maxProductImages - 1)
        : productImages;

    return (
        <li className={`order-overview-card ${className}`}>
            <div className="order-overview-card__product-wrapper">
                <ul className="order-overview-card__product-image-grid">
                    {truncatedProductImages.map(image => (
                        <li key={image.src} className="order-overview-card__product-image-item">
                            <Picture
                                {...image}
                                className="order-overview-card__product-image"
                            />
                        </li>
                    ))}

                    {excessProductImages > 0 && (
                        <li className="order-overview-card__product-image-item">
                            <div className="order-overview-card__excess-products-indicator">
                                {`+${excessProductImages + 1}`}
                            </div>
                        </li>
                    )}
                </ul>
            </div>

            <div className="order-overview-card__info-wrapper">
                <p className="order-overview-card__order-number">
                    {trans('containers.orderOverview.orderNumber', {
                        orderNumber: order.orderNumber,
                    })}
                </p>

                <p className="order-overview-card__order-date">
                    {trans('containers.orderOverview.orderDate', {
                        date: formatDate(new Date().toDateString()),
                    })}
                </p>

                <p className="order-overview-card__order-total">
                    {trans('containers.orderOverview.total', {
                        numberOfProducts: order.productVariants.length,
                        totalPrice: convertNumberToCurrency(order.priceTotal / 100, false),
                    })}
                </p>

                <LinkIconButton
                    hasAnimation
                    to={orderDetailUrl}
                    icon="arrow-right"
                    iconPos={HorizontalAlignment.right}
                    text={trans('containers.orderOverview.viewOrder')}
                    className="order-overview-card__link-button"
                />
            </div>
        </li>
    );
};
