import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Link } from '../../entities/Link/Link';
import { Product } from '../../entities/Product/Product';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ProductState = AsyncReduxState<{
    breadcrumbs: Link[];
    product?: Product;
}>;

const initialState: ProductState = {
    ...initialAsyncReduxState,
    breadcrumbs: [],
};

const productSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ProductState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): ProductState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ProductState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProductState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBreadcrumbs(state, action: PayloadAction<Link[]>): ProductState {
            return {
                ...state,
                breadcrumbs: action.payload,
            };
        },
        setProduct(state, action: PayloadAction<Product | undefined>): ProductState {
            return {
                ...state,
                product: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setHasFetched,
    setBreadcrumbs,
    setProduct,
} = productSlice.actions;

export default productSlice;
