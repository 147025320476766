import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Wrapper } from '../../../components';
import { FaqList } from '../../../compositions';
import { FaqCategories, FaqDataInterface } from '../../../entities/@blocks/Faq/Faq';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';
import { FaqSkeletons } from './skeletons';
import { FaqHelmet } from './subcomponents';

import './Faq.scss';

export interface FaqProps extends FaqDataInterface {
    isLoading?: boolean;
    showAllFaq?: boolean;
    faqCategories?: FaqCategories;
    className?: string;
}

export const Faq: FC<FaqProps> = ({
    isLoading,
    showAllFaq,
    showSearchBar,
    title,
    faqItems,
    faqCategories,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const titleClassNames = classNames('faq__title', {
        'faq__title--is-sticky': !showSearchBar,
    }, className);

    return (
        <section className={`faq ${className}`}>
            {faqCategories && (
                <FaqHelmet faqItems={Object.values(faqCategories).flat()} />
            )}

            <Wrapper className="faq__wrapper">
                {title && (
                    <h2 className={titleClassNames}>
                        {title}
                    </h2>
                )}

                <div className="faq__card">
                    {isLoading && (
                        <FaqSkeletons />
                    )}

                    {!isLoading && (
                        <FaqList
                            showAllFaq={showAllFaq}
                            showSearchBar={showSearchBar}
                            faqList={faqItems}
                            faqCategories={faqCategories}
                        />
                    )}

                    {!showAllFaq && (
                        <div className="faq__link-wrapper">
                            <h2 className="faq__link-title">
                                {trans('containers.faq.followUp.title')}
                            </h2>

                            <p className="faq__link-text">
                                {trans('containers.faq.followUp.linkIntro')}

                                <Link
                                    to={trans(appRoutes[AppRoute.customerServiceFaq].path)}
                                    className="faq__link"
                                >
                                    {trans('containers.faq.followUp.link')}
                                </Link>
                                .
                            </p>
                        </div>
                    )}
                </div>
            </Wrapper>
        </section>
    );
};
