import { Link } from '../entities/Link/Link';

export const generateBreadcrumbStructuredData = (siteUrl: string, breadcrumbs: Link[]): string => {
    const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((breadcrumb, index) => ({
            '@type': 'ListItem',
            position: index + 1,
            name: breadcrumb.label,
            item: index < breadcrumbs.length - 1
                ? siteUrl + breadcrumb.href
                : undefined,
        })),
    };

    return JSON.stringify(structuredData);
};
