import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

import { Image } from '../../entities/Media/Media';
import { useTrans } from '../../hooks';

interface DefaultHelmetProps extends PropsWithChildren {
    title: string;
    description: string;
    keywords?: string;
    image?: Pick<Image, 'src' | 'alt'>;
    canonical?: string;
}

export const DefaultHelmet: FC<DefaultHelmetProps> = ({
    title,
    description,
    keywords,
    image,
    canonical,
    children,
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const twitterHandle = trans('company.twitterHandle');
    const siteUrl = trans('company.url');

    const fallbackImage = {
        src: `${siteUrl}/images/metadata-preview.jpg`,
        alt: trans('company.name'),
    };

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />

            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />

            <meta property="og:image" content={image?.src || fallbackImage.src} />
            <meta property="og:image:alt" content={image?.alt || fallbackImage.alt} />

            <meta property="og:url" content={siteUrl + pathname} />

            {twitterHandle.startsWith('@') && (
                <>
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:site" content={twitterHandle} />

                    <meta property="twitter:title" content={title} />
                    <meta property="twitter:description" content={description} />

                    <meta property="twitter:image" content={image?.src || fallbackImage.src} />
                    <meta property="twitter:image:alt" content={image?.alt || fallbackImage.alt} />
                </>
            )}

            <link rel="canonical" href={canonical || siteUrl + pathname} />

            {children}
        </Helmet>
    );
};
