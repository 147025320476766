import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';

import './StoreDetailHeaderSkeletons.scss';

interface StoreDetailHeaderSkeletonsProps {
    className?: string;
}

export const StoreDetailHeaderSkeletons: FC<StoreDetailHeaderSkeletonsProps> = ({
    className = '',
}): ReactElement => (
    <div className={`store-detail-header-skeletons ${className}`}>
        <div className="store-detail-header-skeletons__image" />

        <div className="store-detail-header-skeletons__info-wrapper">
            <Skeleton className="store-detail-header-skeletons__title" />

            <div className="store-detail-header-skeletons__address">
                <Skeleton className="store-detail-header-skeletons__address-line" />
                <Skeleton className="store-detail-header-skeletons__address-line" />
            </div>

            <Skeleton className="store-detail-header-skeletons__availability" />

            <div className="store-detail-header-skeletons__opening-hours-wrapper">
                <div className="store-detail-header-skeletons__opening-hours-column">
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                </div>

                <div className="store-detail-header-skeletons__opening-hours-column">
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                    <Skeleton className="store-detail-header-skeletons__opening-hours-line" />
                </div>
            </div>
        </div>
    </div>
);
