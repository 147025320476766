import { IconName } from '../../components';
import { RouteParams } from '../../LocalizedRoutes';
import { Link } from '../Link/Link';

export enum AccountPages {
    changePassword = 'accountChangePassword',
    dashboard = 'accountDashboard',
    data = 'accountData',
    orderDetail = 'accountOrderDetail',
    orderOverview = 'accountOrderOverview',
    wishlist = 'accountWishlist',
}

export interface AccountParams extends RouteParams {
    tab?: AccountPages;
}

export interface AccountNavigationItem {
    id: number;
    icon: IconName;
    link: Link;
}

export enum AccountDashboardConfigTab {
    myData = 'myData',
    myAddresses = 'myAddresses',
    giftcard = 'giftcard',
}
