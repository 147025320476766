import { strapiFetch } from '../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { appRoutePageTypes, PageConfig, PageResponse } from '../../entities/Page/Page';
import { generatePageQuery, getPageDraftParams } from '../../entities/Page/PageRequests';
import { transformToPage } from '../../entities/Page/PageTransformers';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setPages,
} from './pagesSlice';

export const fetchPage: ReduxFetchAction<PageConfig> = ({ appRoute, queryParams }) => async (dispatch, getState) => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    const pageType = appRoutePageTypes[appRoute];

    if (!pageType) {
        return;
    }

    try {
        const draftParams = getPageDraftParams(queryParams);
        const query = generatePageQuery(pageType, draftParams);

        const pageResponse = await strapiFetch<PageResponse>(`/pages?${query}`);

        if (!isFetchResultSuccessful(pageResponse)) {
            dispatch(setError(pageResponse.error));
            return;
        }

        const { data: pageData } = pageResponse.data;

        const pageResource = pageData
            ? pageData[0]
            : undefined;

        const page = pageResource
            ? transformToPage(pageResource)
            : undefined;

        const { pagesSlice } = getState();
        const allPages = pagesSlice.pages;

        dispatch(setPages({
            ...allPages,
            [appRoute]: page,
        }));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
