import { NavLinkProps } from 'react-router-dom';

import { IconName } from '../../components';
import { StrapiEntityData } from '../@api/Strapi';
import { Link } from '../Link/Link';
import { Image } from '../Media/Media';

export interface NavigationRequestConfig {
    key: string;
    slug: string;
}

export interface NavigationItemImage {
    width: number;
    height: number;
    mime: string;
    name: string;
    previewUrl: unknown | null;
    url: string;
}

export interface NavigationItemAdditionalFields {
    image: NavigationItemImage;
    section_line: boolean;
}

export interface NavigationItemResource extends StrapiEntityData {
    additionalFields: Partial<NavigationItemAdditionalFields>;
    collapsed: boolean;
    items: NavigationItemResource[];
    menuAttached: boolean;
    order: number;
    path: string;
    slug: string;
    title: string;
    type: string;
    uiRouterKey: string;
}

export type NavigationResponse = NavigationItemResource[];

export interface NavigationParent extends Link {
    id: string;
}

export interface Navigation {
    id: string;
    title: string;
    parent?: NavigationParent;
    groups: NavigationGroup[];
    image?: Image;
}

export interface NavigationGroup {
    id: string;
    items: NavigationItem[];
}

type NavigationLink = Pick<NavLinkProps, 'to' | 'replace' | 'caseSensitive' | 'end'>;

export interface NavigationItem extends NavigationLink {
    id: string;
    order?: number;
    icon?: IconName;
    label: string;
    subNavigation?: Navigation;
}

export enum NavigationTransitionState {
    idle = 'idle',
    goingBackwards = 'going-backwards',
    goingForwards = 'going-forwards',
}
