import qs from 'qs';

export const generateSpaceQuery = (slug?: string): string => {
    const filters = slug
        ? {
            slug: {
                $eq: slug,
            },
        } : undefined;

    const populate = {
        image: {
            fields: ['*'],
        },
        productCategories: {
            populate: ['image'],
        },
    };

    return qs.stringify({
        filters,
        populate,
    }, {
        encodeValuesOnly: true,
    });
};
