import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import {
    useClearRefinements,
    useConfigure,
    useHits,
    useInstantSearch,
    usePagination,
    useRange,
    useSortBy,
} from 'react-instantsearch';
import { useSearchParams } from 'react-router-dom';

import { ProductList } from '../../containers';
import { InstantSearchProductResource, MeilisearchAttributes } from '../../entities/@api/Sylius';
import { InstantSearchStatus, resultsPerPage } from '../../entities/Meilisearch/Meilisearch';
import { Product } from '../../entities/Product/Product';
import { transformProductHitToProduct } from '../../entities/Product/ProductTransformers';
import { productDefaultFilters, ProductFilter } from '../../entities/ProductFilters/ProductFilters';
import { getProductSpecificAttributes, transformAttributeToProductFilter } from '../../entities/ProductFilters/ProductFiltersTransformer';
import { ProductListCallToActionInterface } from '../../entities/ProductListCallToAction/ProductListCallToAction';
import { productSortingOptions } from '../../entities/ProductSorting/ProductSorting';
import { searchTermQueryKey } from '../../entities/Search/Search';
import { ProductSlider } from '../../entities/SliderSection/SliderSection';
import { scrollToTop } from '../../helpers/scroll';
import { useTrans } from '../../hooks';
import { MOCK_PRODUCTS } from '../../mock/mock-data';

interface ConnectedProductListProps {
    showFilterBar?: boolean;
    showProductsCount?: boolean;
    title?: string;
    filterQuery?: string;
    query?: string;
    callToActions?: ProductListCallToActionInterface[];
    onTotalResults?: (totalResults: number) => void;
    className?: string;
}

export const ConnectedProductList: FC<ConnectedProductListProps> = ({
    showFilterBar,
    showProductsCount,
    title,
    filterQuery,
    query,
    callToActions = [],
    onTotalResults,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [searchParams, setSearchParams] = useSearchParams();

    const { status } = useInstantSearch();
    const { items, results } = useHits<InstantSearchProductResource>();

    const { refine: clearRefine } = useClearRefinements();
    const { nbHits, currentRefinement, refine } = usePagination();

    const productsCount = results?.nbHits || 0;

    const { refine: configureRefine } = useConfigure({
        filters: filterQuery,
        query,
        hitsPerPage: resultsPerPage,
        facets: ['*'],
    });

    const { refine: sortRefine } = useSortBy({
        items: productSortingOptions,
    });

    const priceRangeConnector = useRange({
        attribute: MeilisearchAttributes.price,
        precision: 0,
    });

    const [filters, setFilters] = useState<ProductFilter[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);

    const products = useMemo((): Product[] => (
        items.map(transformProductHitToProduct)
    ), [items]);

    useEffect((): void => {
        configureRefine({ filters: filterQuery, query });
    }, []);

    useEffect((): void => {
        const pages = Math.ceil(nbHits / resultsPerPage);

        setTotalPages(pages);
    }, [nbHits]);

    useEffect((): void => {
        if (!onTotalResults) {
            return;
        }

        onTotalResults(productsCount);
    }, [results?.nbHits]);

    useEffect((): void => {
        if (!results?._rawResults?.[0]?.facets) {
            return;
        }

        const filterableAttributes = Object.keys(results._rawResults[0].facets);
        const productSpecificAttributes = getProductSpecificAttributes(filterableAttributes);

        const productSpecificFilters = productSpecificAttributes.map(transformAttributeToProductFilter);

        const productFilters = [
            ...productDefaultFilters,
            ...productSpecificFilters,
        ];

        setFilters(productFilters);
    }, [results]);

    const callToActionsOnPage = useMemo((): ProductListCallToActionInterface[] => {
        const amountPerPage = 3;
        const sliceIndex = currentRefinement * amountPerPage;

        return callToActions.slice(sliceIndex, sliceIndex + amountPerPage);
    }, [callToActions, currentRefinement]);

    const handleResetFilters = (): void => {
        clearRefine();

        if (searchParams.has(searchTermQueryKey) && query) {
            setSearchParams({ [searchTermQueryKey]: query });
        }
    };

    const handleOnChangePage = (page: number): void => {
        scrollToTop();
        refine(page);
    };

    const mockProductSliders: ProductSlider[] = [ // TODO: Get products data from API for this blocks (L5W-98)
        {
            id: 'others-also-viewed-products',
            title: trans('pages.search.othersAlsoViewedProducts'),
            products: [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS],
            link: {
                label: 'Bekijk meer',
                href: '/producten', // TODO: determine what this link should be (L5W-98)
            },
        },
        {
            id: 'popular-products-at-this-moment',
            title: trans('pages.search.popularAtThisMoment'),
            products: [...MOCK_PRODUCTS, ...MOCK_PRODUCTS, ...MOCK_PRODUCTS],
            link: {
                label: 'Bekijk meer',
                href: '/producten', // TODO: determine what this link should be (L5W-98)
            },
        },
    ];

    return (
        <ProductList
            isLoading={status === InstantSearchStatus.loading}
            showFilterBar={showFilterBar}
            showProductsCountForSearchTerm={showProductsCount}
            resultsCount={productsCount}
            title={title}
            query={query}
            filters={filters}
            priceRangeConnector={priceRangeConnector}
            pages={totalPages}
            currentPage={currentRefinement + 1}
            sortItems={productSortingOptions}
            noProductsAvailableSliders={mockProductSliders}
            products={products}
            callToActions={callToActionsOnPage}
            onChangePage={handleOnChangePage}
            onChangeSorting={sortRefine}
            onResetFilters={handleResetFilters}
            className={className}
        />
    );
};
