/* eslint-disable max-len */
import { combineSlices } from '@reduxjs/toolkit';

// Account
import orderDetailSlice, { OrderDetailState } from './@account/orderDetail/orderDetailSlice';
import orderOverviewSlice, { OrderOverviewState } from './@account/orderOverview/orderOverviewSlice';
// Blocks
import serviceSectionSlice, { ServiceSectionState } from './@blocks/serviceSection/serviceSectionSlice';
import topNavbarSlice, { TopNavbarState } from './@blocks/topNavbar/topNavbarSlice';
import urgentBannerSlice, { UrgentBannerState } from './@blocks/urgentBanner/urgentBannerSlice';
// Blog
import blogCategoriesSlice, { BlogCategoriesState } from './@blog/blogCategories/blogCategoriesSlice';
import blogOverviewsSlice, { BlogOverviewsState } from './@blog/blogOverviews/blogOverviewsSlice';
import blogPostSlice, { BlogPostState } from './@blog/blogPost/blogPostSlice';
// Departments
import departmentDetailSlice, { DepartmentDetailState } from './@departments/departmentDetail/departmentDetailSlice';
import departmentOverviewSlice, { DepartmentOverviewState } from './@departments/departmentOverview/departmentOverviewSlice';
// Forms
import giftcardBalanceFormSlice, { GiftcardBalanceFormState } from './@forms/giftcardBalanceForm/giftcardBalanceFormSlice';
import newsletterFormSlice, { NewsletterFormState } from './@forms/newsletterForm/newsletterFormSlice';
import participateFormSlice, { ParticipateFormState } from './@forms/participateForm/participateFormSlice';
// Other
import appSlice, { AppState } from './app/appSlice';
import authenticationSlice, { AuthenticationState } from './authentication/authenticationSlice';
import brandSlice, { BrandState } from './brand/brandSlice';
import brandOverviewSlice, { BrandOverviewState } from './brandOverview/brandOverviewSlice';
import cartSlice, { CartState } from './cart/cartSlice';
import changePasswordSlice, { ChangePasswordState } from './changePassword/changePasswordSlice';
import customerSlice, { CustomerState } from './customer/customerSlice';
import customPageSlice, { CustomPageState } from './customPage/customPageSlice';
import customPagesSlice, { CustomPagesState } from './customPages/customPagesSlice';
import faqSlice, { FaqState } from './faq/faqSlice';
import navigationsSlice, { NavigationsState } from './navigations/navigationsSlice';
import orderSlice, { OrderState } from './order/orderSlice';
import pagesSlice, { PagesState } from './pages/pagesSlice';
import productSlice, { ProductState } from './product/productSlice';
import productCategoriesSlice, { ProductCategoriesState } from './productCategories/productCategoriesSlice';
import productCategorySlice, { ProductCategoryState } from './productCategory/productCategorySlice';
import productDefaultSlice, { ProductDefaultState } from './productDefault/productDefaultSlice';
import productVariantSlice, { ProductVariantState } from './productVariant/productVariantSlice';
import registrationSlice, { RegistrationState } from './registration/registrationSlice';
import resetPasswordSlice, { ResetPasswordState } from './resetPassword/resetPasswordSlice';
import searchSlice, { SearchState } from './search/searchSlice';
import spaceSlice, { SpaceState } from './space/spaceSlice';
import storeSlice, { StoreState } from './store/storeSlice';
import storesSlice, { StoresState } from './stores/storesSlice';
import toastSlice, { ToastState } from './toast/toastSlice';
import wishlistSlice, { WishlistState } from './wishlist/wishlistSlice';

export interface Slices {
    // Account
    orderDetailSlice: OrderDetailState;
    orderOverviewSlice: OrderOverviewState;
    // Blocks
    serviceSectionSlice: ServiceSectionState;
    topNavbarSlice: TopNavbarState;
    urgentBannerSlice: UrgentBannerState;
    // Blog
    blogCategoriesSlice: BlogCategoriesState;
    blogOverviewsSlice: BlogOverviewsState;
    blogPostSlice: BlogPostState;
    // Departments
    departmentDetailSlice: DepartmentDetailState;
    departmentOverviewSlice: DepartmentOverviewState;
    // Forms
    giftcardBalanceFormSlice: GiftcardBalanceFormState;
    newsletterFormSlice: NewsletterFormState;
    participateFormSlice: ParticipateFormState;
    // Account/customer
    authenticationSlice: AuthenticationState;
    changePasswordSlice: ChangePasswordState;
    customerSlice: CustomerState;
    // Other
    appSlice: AppState;
    cartSlice: CartState;
    orderSlice: OrderState;
    customPageSlice: CustomPageState;
    customPagesSlice: CustomPagesState;
    brandSlice: BrandState;
    brandOverviewSlice: BrandOverviewState;
    faqSlice: FaqState;
    navigationsSlice: NavigationsState;
    pagesSlice: PagesState;
    productSlice: ProductState;
    productCategorySlice: ProductCategoryState;
    productCategoriesSlice: ProductCategoriesState;
    productDefaultSlice: ProductDefaultState;
    productVariantSlice: ProductVariantState;
    registrationSlice: RegistrationState;
    resetPasswordSlice: ResetPasswordState;
    searchSlice: SearchState;
    spaceSlice: SpaceState;
    storeSlice: StoreState;
    storesSlice: StoresState;
    toastSlice: ToastState;
    wishlistSlice: WishlistState;
}

export default combineSlices(
    // Account
    orderDetailSlice,
    orderOverviewSlice,
    // Blocks
    serviceSectionSlice,
    urgentBannerSlice,
    topNavbarSlice,
    // Blog
    blogCategoriesSlice,
    blogOverviewsSlice,
    blogPostSlice,
    // Departments
    departmentDetailSlice,
    departmentOverviewSlice,
    // Forms
    giftcardBalanceFormSlice,
    newsletterFormSlice,
    participateFormSlice,
    // Account/customer
    authenticationSlice,
    changePasswordSlice,
    customerSlice,
    registrationSlice,
    resetPasswordSlice,
    // Other
    appSlice,
    brandSlice,
    brandOverviewSlice,
    cartSlice,
    customPageSlice,
    customPagesSlice,
    faqSlice,
    navigationsSlice,
    orderSlice,
    pagesSlice,
    productSlice,
    productCategorySlice,
    productCategoriesSlice,
    productDefaultSlice,
    productVariantSlice,
    searchSlice,
    spaceSlice,
    storeSlice,
    storesSlice,
    toastSlice,
    wishlistSlice,
);
