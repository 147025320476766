/* eslint-disable max-len */
import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import {
    BrandCarousel,
    Carousel,
    CategoryHeader,
    ContentColumns,
    FeaturedProducts,
    Header,
    HighlightedBlogPost,
    InteractiveProductHeader,
    MakeAppointmentSection,
    MediaBanner,
    ProductCollection,
    RelatedBlogPosts,
    TextMedia,
} from '../../containers';
import { FeaturedProductsType } from '../../entities/@blocks/FeaturedProducts/FeaturedProducts';
import { LinkTarget } from '../../entities/Link/Link';
import {
    MOCK_BLOG_POSTS,
    MOCK_BRANDS_LOGOS,
    MOCK_IMAGE_1,
    MOCK_IMAGE_2,
    MOCK_IMAGE_3,
    MOCK_IMAGE_4,
    MOCK_IMAGE_5,
    MOCK_IMAGE_BANNER_1,
    MOCK_INTERACTIVE_PRODUCT_HEADER_IMAGE_1,
    MOCK_LINK,
    MOCK_MEDIA_BANNER,
    MOCK_PRODUCT_1,
    MOCK_PRODUCT_2,
    MOCK_PRODUCT_3,
    MOCK_PRODUCT_4,
    MOCK_PRODUCT_5,
    MOCK_PRODUCT_6,
    MOCK_PRODUCT_7,
    MOCK_PRODUCT_COLLECTION,
    MOCK_PRODUCTS,
    MOCK_VIDEO,
} from '../../mock/mock-data';
import { MediaGridExamples, SliderExamples, UspSectionExamples } from './subcomponents';

import './ContentBlocksPage.scss';

export const ContentBlocksPage: FC = (): ReactElement => (
    <Page className="content-blocks-page">
        <Header
            image={MOCK_IMAGE_1}
            video={MOCK_VIDEO}
            title="Titel van het artikel staat hier"
            subTitle="Dit is een optionele subtitel"
            introHtml="Lorem ipsum dolor sit amet consectetur. Leo nisl risus iaculis dignissim morbi ultricies tristique cras condimentum. Senectus facilisis ut vitae odio sit quis euismod nulla. Leo nisl aliquam quis at egestas leo varius bibendum nec. Turpis id volutpat lectus pellentesque nunc semper enim. Ut tincidunt erat est ac varius auctor auctor quis. Malesuada amet arcu blandit lobortis quam praesent odio at."
            accentColour="#000000"
            className="content-blocks-page__block"
        />

        <InteractiveProductHeader
            image={MOCK_INTERACTIVE_PRODUCT_HEADER_IMAGE_1}
            products={[
                {
                    id: '1',
                    product: MOCK_PRODUCT_1,
                    position: { x: 72, y: 70 },
                    mobilePosition: { x: 85, y: 72 },
                },
                {
                    id: '2',
                    product: MOCK_PRODUCT_2,
                    position: { x: 60, y: 45 },
                    mobilePosition: { x: 64, y: 50 },
                },
                {
                    id: '3',
                    product: MOCK_PRODUCT_3,
                    position: { x: 30, y: 80 },
                    mobilePosition: { x: 12, y: 70 },
                },
            ]}
            className="content-blocks-page__block"
        />

        <MakeAppointmentSection
            onSubmit={formData => {
                console.log('Form data submitted:', formData);
            }}
        />

        <MediaBanner
            media={{
                id: '1',
                image: MOCK_IMAGE_BANNER_1,
            }}
            link={{
                label: 'Bekijk onze producten',
                href: '/',
                target: LinkTarget.self,
            }}
            className="content-blocks-page__block"
        />

        <MediaBanner
            {...MOCK_MEDIA_BANNER}
            className="content-blocks-page__block"
        />

        <CategoryHeader
            title="Tafel- & Bureaulampen"
            image={MOCK_IMAGE_5}
            className="content-blocks-page__block"
        />

        <HighlightedBlogPost
            blogPost={MOCK_BLOG_POSTS[0]}
            className="content-blocks-page__block"
        />

        <ContentColumns
            columns={[
                {
                    id: 1,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<h6>Een kolom tekst met titel</h6><p>Lorem ipsum dolor sit amet consectetur. Laoreet scelerisque in mauris turpis ante. Dignissim eleifend egestas ultricies eget lorem justo mi leo tincidunt. Duis at dictumst dolor nunc vulputate orci et odio. Potenti eget rhoncus elementum commodo a in lorem. In integer quam orci eros dui fermentum augue volutpat viverra. Eu et elementum rhoncus varius netus scelerisque justo. Mattis lobortis nunc neque rutrum tellus morbi ultricies. At mattis sed neque et enim. Arcu leo turpis vivamus volutpat. Amet id tortor diam volutpat vitae varius arcu rhoncus. Congue.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
            ]}
            className="content-blocks-page__block"
        />

        <ContentColumns
            columns={[
                {
                    id: 1,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<q>Dit is een extra grote leuke of mooie quote uit dit inspirerende artikel</q><p>Naam van de persoon (optioneel)</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 2,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
            ]}
            className="content-blocks-page__block"
        />

        <ContentColumns
            columns={[
                {
                    id: 1,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p><p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 2,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '',
                        image: MOCK_IMAGE_5,
                        video: undefined,
                    },
                },
            ]}
            className="content-blocks-page__block"
        />

        <ContentColumns
            columns={[
                {
                    id: 1,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '',
                        image: MOCK_IMAGE_5,
                        video: MOCK_VIDEO,
                    },
                },
                {
                    id: 2,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p><p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
            ]}
            className="content-blocks-page__block"
        />

        <ContentColumns
            columns={[
                {
                    id: 1,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '',
                        image: MOCK_IMAGE_2,
                        video: undefined,
                    },
                },
                {
                    id: 2,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 3,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '',
                        image: MOCK_IMAGE_3,
                        video: undefined,
                    },
                },
            ]}
            className="content-blocks-page__block"
        />

        <ContentColumns
            columns={[
                {
                    id: 1,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<h3>Grote tussen <em>titel</em></h3><p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 2,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<p>Lorem ipsum dolor sit amet consectetur. Vel quisque turpis sed donec enim nulla ut viverra. In vestibulum maecenas nunc felis pharetra suspendisse aliquam cum velit. Feugiat et cras semper eu eget risus. Nullam sed ullamcorper blandit placerat nibh. Eget tincidunt risus rhoncus mauris fringilla viverra urna vestibulum. In in tempor urna donec ante. Nulla maecenas elit imperdiet lectus sed sodales leo ac. Ultrices pellentesque vel et cursus donec quam neque. Porttitor consectetur adipiscing ac enim quis.<br />Facilisis nec mus pretium morbi massa lacinia. Ut enim interdum ipsum erat ut. Sem bibendum tempor integer tincidunt nisl et massa. A vestibulum in ut phasellus faucibus pellentesque blandit neque elementum. Aliquam molestie orci morbi in nibh viverra pellentesque tincidunt. Dis dolor eros lobortis blandit amet. Diam id cursus fermentum.</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
                {
                    id: 3,
                    content: {
                        titleHtml: '',
                        paragraphHtml: '<q>Dit is een leuke of mooie quote uit dit inspirerende artikel</q><p>Naam van de persoon (optioneel)</p>',
                        image: undefined,
                        video: undefined,
                    },
                },
            ]}
            className="content-blocks-page__block"
        />

        <MediaGridExamples className="content-blocks-page__block" />

        <Carousel
            id="carousel-example"
            images={[
                MOCK_IMAGE_1,
                MOCK_IMAGE_2,
                MOCK_IMAGE_3,
                MOCK_IMAGE_4,
                MOCK_IMAGE_5,
            ]}
            className="content-blocks-page__block"
        />

        <TextMedia
            media={{
                id: '1',
                video: MOCK_VIDEO,
            }}
            title="Loods 5 heeft alles in huis"
            descriptionHtml="<q>Op zoek naar prachtige interieurbasics en trendy items? Loods 5 heeft een gigantisch aanbod!</q>"
            link={{
                label: 'Bekijk onze producten',
                href: '/',
                target: LinkTarget.self,
            }}
            className="content-blocks-page__block"
        />

        <BrandCarousel
            logos={MOCK_BRANDS_LOGOS}
            title="Merken shoppen"
            link={{
                label: 'Bekijk alle merken',
                href: '/Merken',
                target: LinkTarget.self,
            }}
            className="content-blocks-page__block"
        />

        <UspSectionExamples className="content-blocks-page__block" />

        <RelatedBlogPosts
            blogPosts={MOCK_BLOG_POSTS}
            title="Gerelateerde blogs"
            className="content-blocks-page__block"
        />

        <SliderExamples className="content-blocks-page__block" />

        <ProductCollection
            productCollection={MOCK_PRODUCT_COLLECTION}
            products={MOCK_PRODUCTS}
            title="Loods 5 Buitencollectie"
            titleColor="#bbb660"
            footerLink={{
                label: 'Bekijk alle producten',
                href: '/producten',
            }}
            className="content-blocks-page__block"
        />

        <FeaturedProducts
            layoutType={FeaturedProductsType.verticalTitle}
            title="Featured Products"
            products={MOCK_PRODUCTS}
            className="content-blocks-page__block"
        />

        <FeaturedProducts
            layoutType={FeaturedProductsType.alternatingSizes}
            title="Featured Products"
            products={[MOCK_PRODUCT_4, MOCK_PRODUCT_5, MOCK_PRODUCT_6, MOCK_PRODUCT_7]}
            link={MOCK_LINK}
            className="content-blocks-page__block"
        />
    </Page>
);
