import { FC, ReactElement } from 'react';

import { Picture } from '../../../../components';
import { useTrans } from '../../../../hooks';
import {
    PAYMENT_METHOD_GIFTCARD,
    PAYMENT_METHOD_IDEAL,
    PAYMENT_METHOD_MASTERCARD,
    PAYMENT_METHOD_VISA,
} from '../../../../mock/mock-data';

import './PaymentMethods.scss';

interface PaymentMethodsProps {
    className?: string;
}

export const PaymentMethods: FC<PaymentMethodsProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`payment-methods ${className}`}>
            <p className="payment-methods__header">
                {trans('containers.checkout.paymentMethods.paySafe')}
            </p>

            <div className="payment-methods__methods-wrapper">
                <Picture
                    {...PAYMENT_METHOD_GIFTCARD}
                    className="payment-methods__picture"
                />

                <Picture
                    {...PAYMENT_METHOD_IDEAL}
                    className="payment-methods__picture"
                />

                <Picture
                    {...PAYMENT_METHOD_MASTERCARD}
                    className="payment-methods__picture"
                />

                <Picture
                    {...PAYMENT_METHOD_VISA}
                    className="payment-methods__picture"
                />
            </div>
        </div>
    );
};
