import { generateUuid } from '../../../helpers/string';
import {
    CheckoutStateResource,
    OrderBaseResource,
    OrderItemResource,
    OrderPaymentMethodResource,
    OrderPaymentResource,
    OrderResource,
    OrderShipmentMethodResource,
    OrderShipmentResource,
    PaymentStateResource,
    ShippingStateResource,
} from '../../@api/Sylius';
import { AddressType } from '../../Address/Address';
import { transformToCustomerAddress } from '../../Address/AddressTransformers';
import { transformToVariantCode } from '../../Product/ProductTransformers';
import {
    CheckoutState,
    OrderBase,
    OrderDetailInterface,
    OrderPayment,
    OrderPaymentMethod,
    OrderProductVariant,
    OrderShipment,
    OrderShipmentMethod,
    OrderState,
    PaymentState,
    ShippingState,
} from './OrderDetail';

export const transformToOrderBase = (resource: OrderBaseResource): OrderBase => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    return {
        id,
        tokenValue: resource.tokenValue || '',
        orderNumber: resource.number || '',
        state: resource.state as OrderState,
        priceTotal: resource.itemsTotal,
        priceSubTotal: resource.itemsSubtotal,
        channel: resource.channel,
    };
};

export const transformToOrderProductVariant = (resource: OrderItemResource): OrderProductVariant => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const variantId = transformToVariantCode(resource.variant);

    return {
        id,
        variantId,
        productName: resource.productName || '',
        quantity: resource.quantity,
        priceUnit: resource.unitPrice,
        priceUnitOriginal: resource.originalUnitPrice || 0,
        priceUnitDiscounted: resource.discountedUnitPrice,
        priceSubTotal: resource.subtotal,
        priceTotal: resource.total,
    };
};

const transformToCheckoutState = (resource: CheckoutStateResource): CheckoutState => {
    if (resource === CheckoutStateResource.completed) {
        return CheckoutState.completed;
    }

    // TODO: Add other CheckoutState enum values and return a default, once available
    return CheckoutState.completed;
};


const transformToPaymentState = (resource: PaymentStateResource): PaymentState => {
    if (resource === PaymentStateResource.paid) {
        return PaymentState.paid;
    }

    // TODO: Add other PaymentState enum values and return a default, once available
    return PaymentState.paid;
};

const transformToShippingState = (resource: ShippingStateResource): ShippingState => {
    if (resource === ShippingStateResource.ready) {
        return ShippingState.ready;
    }

    // TODO: Add other ShippingState enum values and return a default, once available
    return ShippingState.ready;
};

const transformToOrderPaymentMethod = (resource: OrderPaymentMethodResource): OrderPaymentMethod => {
    if (resource === OrderPaymentMethodResource.paynl) {
        return OrderPaymentMethod.paynl;
    }

    return OrderPaymentMethod.paynl;
};

const transformToOrderPayment = (resource: OrderPaymentResource): OrderPayment => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const method = transformToOrderPaymentMethod(resource.method);

    return { id, method };
};

const transformToOrderShipmentMethod = (resource: OrderShipmentMethodResource): OrderShipmentMethod => {
    if (resource === OrderShipmentMethodResource.ups) {
        return OrderShipmentMethod.ups;
    }

    return OrderShipmentMethod.ups;
};

const transformToOrderShipment = (resource: OrderShipmentResource): OrderShipment => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const method = transformToOrderShipmentMethod(resource.method);

    return { id, method };
};

export const transformToOrderDetail = (resource: OrderResource): OrderDetailInterface => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const productVariants = resource.items.map(transformToOrderProductVariant);

    const checkoutState = transformToCheckoutState(resource.checkoutState);
    const paymentState = transformToPaymentState(resource.paymentState);
    const shippingState = transformToShippingState(resource.shippingState);

    const shippingAddress = resource.shippingAddress
        ? transformToCustomerAddress(resource.shippingAddress, AddressType.shipping)
        : undefined;

    const billingAddress = resource.billingAddress
        ? transformToCustomerAddress(resource.billingAddress, AddressType.billing)
        : undefined;

    const payments = resource.payments.map(transformToOrderPayment);
    const shipments = resource.shipments.map(transformToOrderShipment);

    return {
        id,
        tokenValue: resource.tokenValue || '',
        orderNumber: resource.number || '',
        productVariants,
        currencyCode: resource.currencyCode,
        localeCode: resource.localeCode,
        checkoutState,
        paymentState,
        shippingState,
        priceItemsSubTotal: resource.itemsSubtotal,
        priceItemsTotal: resource.itemsTotal,
        priceTaxTotal: resource.taxTotal,
        priceShippingTotal: resource.shippingTotal,
        priceShippingTaxTotal: resource.shippingTaxTotal,
        priceTaxExcludedTotal: resource.taxExcludedTotal,
        priceTaxIncludedTotal: resource.taxIncludedTotal,
        priceOrderPromotionTotal: resource.orderPromotionTotal,
        priceShippingPromotionTotal: resource.shippingPromotionTotal,
        priceTotal: resource.total,
        shippingAddress,
        billingAddress,
        payments,
        shipments,
    };
};
