import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';

import trans from '../../helpers/trans';
import { MeilisearchAttributes } from '../@api/Sylius';
import { InstantSearchSortingDirection, MeilisearchIndex } from '../Meilisearch/Meilisearch';

export const productSortingOptions: SortByItem[] = [
    {
        label: trans('entities.productSorting.recommended'),
        value: MeilisearchIndex.variantsNl,
    },
    {
        label: trans('entities.productSorting.new'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchAttributes.isNew}:${InstantSearchSortingDirection.descending}`,
    },
    {
        label: trans('entities.productSorting.priceAsc'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchAttributes.price}:${InstantSearchSortingDirection.ascending}`,
    },
    {
        label: trans('entities.productSorting.priceDesc'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchAttributes.price}:${InstantSearchSortingDirection.descending}`,
    },
    {
        label: trans('entities.productSorting.highestDiscount'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchAttributes.highestDiscount}:${InstantSearchSortingDirection.ascending}`,
    },
    {
        label: trans('entities.productSorting.bestRating'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchAttributes.ranking}:${InstantSearchSortingDirection.ascending}`,
    },
];
