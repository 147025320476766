import { FC, ReactElement } from 'react';

import { TopNavbar } from '../../containers';
import { useClientEffect } from '../../hooks';
import { fetchTopNavbar } from '../../redux/@blocks/topNavbar/topNavbarActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedTopNavbarProps {
    className?: string;
}

export const ConnectedTopNavbar: FC<ConnectedTopNavbarProps> = ({
    className = '',
    ...topNavbarProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const topNavbarItems = useTypedSelector(state => state.topNavbarSlice.topNavbarItems);

    useClientEffect((): void => {
        dispatch(fetchTopNavbar());
    }, []);

    return (
        <TopNavbar
            {...topNavbarProps}
            links={topNavbarItems?.links || []}
            usps={topNavbarItems?.usps || []}
            className={className}
        />
    );
};
