import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Store } from '../../entities/Store/Store';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type StoreState = AsyncReduxState<{
    store?: Store,
}>;

const initialState: StoreState = {
    ...initialAsyncReduxState,
};

const storeSlice = createSlice({
    name: 'storeSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): StoreState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): StoreState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): StoreState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): StoreState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setStore(state, action: PayloadAction<Store | undefined>): StoreState {
            return {
                ...state,
                store: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setHasFetched,
    setIsSuccessful,
    setStore,
} = storeSlice.actions;

export default storeSlice;
