import { FC, memo, ReactElement } from 'react';

import { Skeleton, Wrapper } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';
import { randomInBetweenValue } from '../../../../helpers/number';

import './FooterSkeletons.scss';


export const FooterSkeletons: FC = memo((): ReactElement => {
    const numberOfGroups = 4;
    const groupSkeletons = generateIdArray(numberOfGroups);

    return (
        <div className="footer-skeletons">
            <Wrapper className="footer-skeletons__wrapper">
                {groupSkeletons.map(group => {
                    const numberOfItems = randomInBetweenValue(3, 6);
                    const itemSkeletons = generateIdArray(numberOfItems);

                    return (
                        <div key={group} className="footer-skeletons__nav">
                            <Skeleton className="footer-skeletons__nav-title" />

                            <div className="footer-skeletons__nav-list">
                                {itemSkeletons.map(item => (
                                    <Skeleton key={item} className="footer-skeletons__nav-item" />
                                ))}
                            </div>
                        </div>
                    );
                })}
            </Wrapper>
        </div>
    );
});
