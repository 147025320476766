import trans from '../../../helpers/trans';
import {
    isBaseErrorResponse,
    isFetchResultClientErrorResponse,
    isFetchResultErrorResponse,
    isFetchResultStartPaymentErrorResponse,
} from '../../Error/Error';
import {
    transformBaseErrorResponseToString,
    transformFetchResultClientErrorToString,
    transformFetchResultErrorToString,
    transformFetchResultStartPaymentErrorToString,
} from '../../Error/ErrorTransformers';
import {
    FetchResult,
    FetchResultClientError,
    FetchResultError,
    FetchResultStartPaymentError,
    FetchResultType,
} from '../../FetchResult/FetchResult';
import { BaseErrorResponse, Token, tokenKey } from './index';

export const syliusFetch = async <ResponseData>(
    endpoint: string,
    options?: RequestInit,
): Promise<FetchResult<ResponseData, string>> => {
    let data: ResponseData | BaseErrorResponse | FetchResultError | FetchResultClientError | FetchResultStartPaymentError | null;

    try {
        const apiUrl = process.env.REACT_APP_SYLIUS_URL || '';

        const requestOptions: RequestInit = {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/ld+json',
                'Accept-Language': 'nl',
                ...options?.headers,
            },
        };

        const response = await fetch(apiUrl + endpoint, requestOptions);

        const statusString = response.status.toString();
        const isSuccessfulStatusCode = statusString.startsWith('2') || statusString.startsWith('3');
        const responseHasContent = ![202, 204].includes(response.status);

        data = responseHasContent ? await response.json() : null;

        if (!isSuccessfulStatusCode) {
            if (isBaseErrorResponse(data)) {
                return {
                    status: response.status,
                    type: FetchResultType.Error,
                    error: transformBaseErrorResponseToString(data),
                };
            }

            if (isFetchResultErrorResponse(data)) {
                return {
                    status: response.status,
                    type: FetchResultType.Error,
                    error: transformFetchResultErrorToString(data),
                };
            }

            if (isFetchResultClientErrorResponse(data)) {
                return {
                    status: response.status,
                    type: FetchResultType.Error,
                    error: transformFetchResultClientErrorToString(data),
                };
            }

            if (isFetchResultStartPaymentErrorResponse(data)) {
                return {
                    status: response.status,
                    type: FetchResultType.Error,
                    error: transformFetchResultStartPaymentErrorToString(data),
                };
            }

            return {
                status: response.status,
                type: FetchResultType.Error,
                error: trans('errors.unknownError'),
            };
        }

        return {
            status: response.status,
            type: FetchResultType.Success,
            data: data as ResponseData,
        };
    } catch (error: unknown) {
        console.error('[syliusFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    } finally {
        data = null;
    }
};

export const authorizedSyliusFetch = async <ResponseData>(
    endpoint: string,
    options?: RequestInit,
): Promise<FetchResult<ResponseData, string>> => {
    try {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);

        if (!storedToken) {
            // TODO: Log out
            throw new Error('No token found.');
        }

        const tokenData = JSON.parse(storedToken) as Token;

        return await syliusFetch<ResponseData>(endpoint, {
            ...options,
            headers: {
                ...options?.headers,
                Authorization: tokenData.token || '',
            },
        });
    } catch (error: unknown) {
        console.error('[authorizedSyliusFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};

export const syliusCustomerOrGuestFetch = async <ResponseData>(
    customerId: string | undefined,
    endpoint: string,
    options?: RequestInit,
): Promise<FetchResult<ResponseData, string>> => {
    try {
        if (customerId) {
            return await authorizedSyliusFetch<ResponseData>(endpoint, { ...options });
        }

        return await syliusFetch<ResponseData>(endpoint, { ...options });
    } catch (error: unknown) {
        console.error('[syliusCustomerOrGuestFetch]', error);

        return {
            status: 500,
            type: FetchResultType.Error,
            error: trans('errors.unknownError'),
        };
    }
};
