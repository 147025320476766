import { PaginationResource } from '../@api/Strapi';
import { PaginationConfig } from '../@api/Sylius';
import { PaginationInterface } from './Pagination';

export const transformSyliusPaginationToPagination = (totalItems: number, paginationConfig: PaginationConfig): PaginationInterface => {
    const pageCount = Math.ceil(totalItems / paginationConfig.itemsPerPage);

    return {
        total: totalItems,
        page: paginationConfig.page,
        pageSize: paginationConfig.itemsPerPage,
        pageCount,
    };
};

export const transformStrapiPaginationToPagination = (resource: PaginationResource): PaginationInterface => ({
    total: resource.total,
    page: resource.page,
    pageSize: resource.pageSize,
    pageCount: resource.pageCount,
});
