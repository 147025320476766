import { FC, ReactElement } from 'react';

import { Picture, SafeHtml } from '../../components';
import { LinkIconButton } from '../../compositions';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { Brand } from '../../entities/Brand/Brand';
import { Product } from '../../entities/Product/Product';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';
import { ProductInformationSkeletons } from './skeletons';

import './ProductInformation.scss';

interface ProductInformationProps {
    isLoading: boolean;
    product?: Product;
    brand?: Brand;
    className?: string;
}

export const ProductInformation: FC<ProductInformationProps> = ({
    isLoading,
    product,
    brand,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    if (isLoading || !product) {
        return (
            <ProductInformationSkeletons />
        );
    }

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: brand?.slug,
    });

    return (
        <div className={`product-information ${className}`}>
            <section className="product-information__block product-information__main-wrapper">
                <div className="product-information__main-inner">
                    <div className="product-information__description-wrapper">
                        <h2 className="product-information__title">
                            {trans('containers.productInformation.description')}
                        </h2>

                        {product.description ? (
                            <p className="product-information__description">
                                {product.description}
                            </p>
                        ) : (
                            <p className="product-information__description">
                                {trans('containers.productInformation.noDescriptionAvailable')}
                            </p>
                        )}
                    </div>

                    <aside className="product-information__details-wrapper">
                        {product.attributes.length > 0 && (
                            <div className="product-information__specifications-wrapper">
                                <h2 className="product-information__title">
                                    {trans('containers.productInformation.specifications')}
                                </h2>

                                <table className="product-information__table">
                                    <tbody>
                                        {product.attributes.map(attribute => (
                                            <tr key={attribute.key} className="product-information__table-row">
                                                <td className="product-information__table-cell">
                                                    {attribute.label}
                                                </td>

                                                <td className="product-information__table-cell">
                                                    {attribute.value}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}

                        {product.dimensionAttributes && (
                            <div className="product-information__dimensions-wrapper">
                                <h2 className="product-information__title">
                                    {trans('containers.productInformation.dimensions')}
                                </h2>

                                <table className="product-information__table">
                                    <tbody>
                                        {product.dimensionAttributes.map(dimensionAttribute => (
                                            <tr key={dimensionAttribute.key} className="product-information__table-row">
                                                <td className="product-information__table-cell">
                                                    {dimensionAttribute.label}
                                                </td>

                                                <td className="product-information__table-cell">
                                                    {dimensionAttribute.value}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </aside>
                </div>
            </section>

            {brand && (
                <section className="product-information__brand-wrapper">
                    {brand.logo && (
                        <div className="product-information__brand-logo">
                            <Picture
                                {...brand.logo}
                                className="brand-header__logo"
                            />
                        </div>
                    )}

                    <div className="product-information__brand-information">
                        <h2 className="product-information__brand-name">
                            {brand.name}
                        </h2>

                        {brand.description && (
                            <SafeHtml
                                html={brand.description}
                                className="product-information__brand-description"
                            />
                        )}

                        <LinkIconButton
                            hasAnimation
                            icon="arrow-right"
                            iconPos={HorizontalAlignment.right}
                            to={brandDetailUrl}
                            text={trans('containers.productInformation.viewAllFromBrand', { brand: brand.name })}
                            className="product-information__brand-link"
                        />
                    </div>
                </section>
            )}
        </div>
    );
};
