import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Brand } from '../../entities/Brand/Brand';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type BrandState = AsyncReduxState<{
    ssrBrandQuery?: string;
    brand?: Brand;
}>;

const initialState: BrandState = {
    ...initialAsyncReduxState,
};

const brandSlice = createSlice({
    name: 'brandSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BrandState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): BrandState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BrandState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BrandState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSsrBrandQuery(state, action: PayloadAction<string | undefined>): BrandState {
            return {
                ...state,
                ssrBrandQuery: action.payload,
            };
        },
        setBrand(state, action: PayloadAction<Brand | undefined>): BrandState {
            return {
                ...state,
                brand: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setSsrBrandQuery,
    setBrand,
} = brandSlice.actions;

export default brandSlice;
