import { FC, ReactElement } from 'react';

import { useTypedSelector } from '../../../redux/store';

interface ConnectedSearchChildProps {
    ssrTotalHits: number;
    ssrQuery?: string;
}

interface ConnectedSearchWrapperProps {
    children: (childProps: ConnectedSearchChildProps) => ReactElement;
}

export const ConnectedSearchWrapper: FC<ConnectedSearchWrapperProps> = ({
    children,
}): ReactElement => {
    const ssrTotalHits = useTypedSelector(state => state.searchSlice.ssrTotalHits);
    const ssrSearchQuery = useTypedSelector(state => state.searchSlice.ssrSearchQuery);

    return (
        <div>
            {children({
                ssrTotalHits,
                ssrQuery: ssrSearchQuery,
            })}
        </div>
    );
};
