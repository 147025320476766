import { ParsedQs } from 'qs';

import {
    PaginationMetaResource,
    StrapiEntity,
    StrapiEntityData,
    StrapiMeta,
} from '../@api/Strapi';
import { Block, BlockResource } from '../Block/Block';
import { DateResource } from '../Date/Date';
import { AppRoute } from '../Routing/Routing';
import { Seo, SeoResource } from '../Seo/Seo';

export enum PageType {
    account = 'Account (klantomgeving)',
    brandDetail = 'Merk detail pagina (onder producten)',
    brandOverview = 'Merken',
    checkout = 'Checkout',
    cart = 'Winkelwagen',
    checkoutThankYou = 'Bedanktpagina',
    companyInspiration = 'Inspiratie',
    companyJoinUs = 'Deelnemen bij Loods 5',
    companyMedia = 'Media & PR',
    companyStory = 'Ons verhaal',
    companySustainability = 'Duurzaamheid',
    customerService = 'Contact',
    customerServiceAccount = 'Account (klantenservice)',
    customerServiceDelivery = 'Bezorging levertijden',
    customerServiceFaq = 'Veelgestelde vragen',
    customerServiceOrdering = 'Bestellingen betalen',
    customerServiceReturns = 'Ruilen retouren',
    customerServiceTerms = 'Winkelvoorwaarden',
    forgotPassword = 'Wachtwoord vergeten',
    giftcardBalance = 'Cadeaukaart',
    home = 'Home',
    login = 'Login',
    legalCookies = 'Cookiebeleid',
    legalDisclaimer = 'Disclaimer',
    legalPrivacy = 'Privacy',
    newsletter = 'Nieuwsbrief',
    ownCollection = 'Eigen collectie',
    orderDetail = 'Bestelling',
    products = 'Producten',
    registration = 'Registratie',
    sale = 'Sale',
    spacesOverview = 'Ruimtes',
    storesOverview = 'Winkels',
    storesDepartments = 'Afdelingen',
    storesRestaurant = 'Restaurant',
}

export const appRoutePageTypes: Partial<Record<AppRoute, PageType>> = {
    [AppRoute.account]: PageType.account,
    [AppRoute.accountOrderDetail]: PageType.orderDetail,
    [AppRoute.brandDetail]: PageType.brandDetail,
    [AppRoute.brandOverview]: PageType.brandOverview,
    [AppRoute.blogOverview]: PageType.companyInspiration,
    [AppRoute.checkoutOrder]: PageType.cart,
    [AppRoute.checkoutThankYou]: PageType.checkoutThankYou,
    [AppRoute.companyJoinUs]: PageType.companyJoinUs,
    [AppRoute.companyMedia]: PageType.companyMedia,
    [AppRoute.companyStory]: PageType.companyStory,
    [AppRoute.companySustainability]: PageType.companySustainability,
    [AppRoute.customerService]: PageType.customerService,
    [AppRoute.customerServiceAccount]: PageType.customerServiceAccount,
    [AppRoute.customerServiceDelivery]: PageType.customerServiceDelivery,
    [AppRoute.customerServiceFaq]: PageType.customerServiceFaq,
    [AppRoute.customerServiceOrdering]: PageType.customerServiceOrdering,
    [AppRoute.customerServiceReturns]: PageType.customerServiceReturns,
    [AppRoute.customerServiceTerms]: PageType.customerServiceTerms,
    [AppRoute.forgotPassword]: PageType.forgotPassword,
    [AppRoute.giftcardBalance]: PageType.giftcardBalance,
    [AppRoute.home]: PageType.home,
    [AppRoute.login]: PageType.login,
    [AppRoute.legalCookies]: PageType.legalCookies,
    [AppRoute.legalDisclaimer]: PageType.legalDisclaimer,
    [AppRoute.legalPrivacy]: PageType.legalPrivacy,
    [AppRoute.newsletter]: PageType.newsletter,
    [AppRoute.ownCollection]: PageType.ownCollection,
    [AppRoute.products]: PageType.products,
    [AppRoute.registration]: PageType.registration,
    [AppRoute.sale]: PageType.sale,
    [AppRoute.spacesOverview]: PageType.spacesOverview,
    [AppRoute.storesDepartments]: PageType.storesDepartments,
    [AppRoute.storesOverview]: PageType.storesOverview,
    [AppRoute.storesRestaurant]: PageType.storesRestaurant,
};

export interface PageResource extends StrapiEntityData {
    title: string;
    slug: string;
    description: string;
    elements: BlockResource[];
    seo?: SeoResource;
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type PageResponse = StrapiEntity<PageResource[]> & StrapiMeta<PaginationMetaResource>;

export interface PageInterface {
    id: string;
    title: string;
    description: string;
    blocks: Block[];
    seo?: Seo;
}

export interface PageConfig {
    appRoute: AppRoute,
    queryParams?: ParsedQs;
}

export interface CustomPageConfig {
    slug: string;
    queryParams?: ParsedQs;
}

export interface PageDraftConfig {
    status?: string;
    previewKey?: string;
}
