import {
    FC,
    ReactElement,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';

import { Wrapper } from '../../components';
import {
    Progress,
    SectionFooter,
    Slider,
    SliderProps,
} from '../../compositions';
import { Device } from '../../entities/Device/Device';
import { SliderSectionInterface } from '../../entities/SliderSection/SliderSection';
import { useDeviceWidth } from '../../hooks';
import { SliderSectionSkeletons } from './subcomponents';

import './SliderSection.scss';

interface SliderSectionProps extends SliderSectionInterface, Pick<SliderProps, 'items' | 'columns'> {
    isLoading?: boolean;
    titleHasThemeColor?: boolean;
    className?: string;
    sliderClassName?: string;
}

export const SliderSection: FC<SliderSectionProps> = ({
    isLoading,
    titleHasThemeColor,
    id,
    title,
    items,
    columns,
    link,
    className = '',
    sliderClassName = '',
}): ReactElement => {
    const { device } = useDeviceWidth();

    const [activeBlogPostIndex, setActiveBlogPostIndex] = useState<number>(-1);

    const columnData = useMemo((): Record<Device, number> => ({
        [Device.mobile]: 1,
        [Device.tabletPortrait]: 2,
        [Device.tabletLandscape]: 3,
        [Device.desktop]: 4,
        ...columns,
    }), [columns]);

    const maxIndex = useMemo((): number => {
        if (!device) {
            return items.length;
        }

        return items.length - (columnData[device] - 1);
    }, [items, columnData, device]);

    if (isLoading) {
        return (
            <SliderSectionSkeletons />
        );
    }

    const sliderSectionTitleClassNames = classNames('slider-section__title', {
        'slider-section__title--is-green': titleHasThemeColor,
    }, className);

    return (
        <section className={`slider-section ${className}`}>
            <header className="slider-section__header">
                <Wrapper className="slider-section__header-wrapper">
                    <h2 className={sliderSectionTitleClassNames}>
                        {title}
                    </h2>

                    <Progress
                        total={maxIndex}
                        activeIndex={activeBlogPostIndex}
                        onChange={setActiveBlogPostIndex}
                        className="slider-section__progress"
                    />
                </Wrapper>
            </header>

            <Slider
                id={id}
                activeIndex={activeBlogPostIndex}
                maxIndex={maxIndex}
                items={items}
                columns={columnData}
                onChange={setActiveBlogPostIndex}
                className={`slider-section__slider ${sliderClassName}`}
            />

            {link && (
                <Wrapper className="slider-section__footer-wrapper">
                    <SectionFooter link={link} />
                </Wrapper>
            )}
        </section>
    );
};
