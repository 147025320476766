import { syliusFetch, TokenResource } from '../../entities/@api/Sylius';
import { CreateAccountDuringCheckoutFormData } from '../../entities/@forms/GuestForm/GuestForm';
import { RegistrationFormData } from '../../entities/@forms/RegistrationForm/RegistrationForm';
import { Address, AddressType, CustomerAddress } from '../../entities/Address/Address';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import trans from '../../helpers/trans';
import { authenticateUser } from '../authentication/authenticationActions';
import { addAddress, fetchCustomer } from '../customer/customerActions';
import { ReduxFetchAction } from '../defaults';
import { connectOrderToCustomer } from '../order/orderActions';
import { addNegativeToast, addPositiveToast } from '../toast/toastActions';
import { setError, setIsLoading, setIsRegistered } from './registrationSlice';

export const registerUser: ReduxFetchAction<RegistrationFormData> = formData => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsRegistered(false));

    try {
        const registerResponse = await syliusFetch<TokenResource>('/shop/customers', {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(registerResponse)) {
            dispatch(setError(registerResponse.error));
            return;
        }

        await dispatch(authenticateUser({
            username: formData.email,
            password: formData.password,
            rememberMe: true,
        }));

        await dispatch(fetchCustomer());

        const address: CustomerAddress = {
            id: '',
            type: AddressType.shipping,
            label: '',
            street: formData.street,
            postalCode: formData.postcode,
            houseNumber: formData.number,
            addition: formData.addition,
            city: formData.city,
            country: formData.countryCode,
        };

        await dispatch(addAddress(address));

        dispatch(addPositiveToast({
            title: trans('redux.registration.successMessageTitle'),
            description: trans('redux.registration.successMessageDescription'),
        }));

        dispatch(setIsRegistered(true));
    } catch (error) {
        console.error('[registerUser]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const registerUserDuringCheckout: ReduxFetchAction<CreateAccountDuringCheckoutFormData> = formData => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsRegistered(false));

    try {
        const registerResponse = await syliusFetch<TokenResource>('/shop/customers', {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(registerResponse)) {
            dispatch(setError(registerResponse.error));

            dispatch(addNegativeToast({
                title: trans('errors.unknownError'),
                description: registerResponse.error,
            }));

            return;
        }

        await dispatch(authenticateUser({
            username: formData.email,
            password: formData.password,
            rememberMe: true,
        }));

        await dispatch(fetchCustomer());

        await dispatch(connectOrderToCustomer());

        if (formData.shippingAddress) {
            const shippingAddress: Address = {
                id: '',
                label: '',
                street: formData.shippingAddress.street,
                postalCode: formData.shippingAddress.postalCode,
                houseNumber: formData.shippingAddress.number,
                addition: formData.shippingAddress.addition,
                city: formData.shippingAddress.city,
                country: formData.shippingAddress.country,
            };

            await dispatch(addAddress(shippingAddress));
        }

        if (formData.billingAddress) {
            const billingAddress: Address = {
                id: '',
                label: '',
                street: formData.billingAddress.street,
                postalCode: formData.billingAddress.postalCode,
                houseNumber: formData.billingAddress.number,
                addition: formData.billingAddress.addition,
                city: formData.billingAddress.city,
                country: formData.billingAddress.country,
            };

            await dispatch(addAddress(billingAddress));
        }

        dispatch(addPositiveToast({
            title: trans('redux.registration.successMessageTitle'),
            description: trans('redux.registration.successMessageDescription'),
        }));

        dispatch(setIsRegistered(true));
    } catch (error) {
        console.error('[registerUserDuringCheckout]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
