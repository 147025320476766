import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { NotFound, ProductDetailRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchBrand } from '../../../redux/brand/brandActions';
import { fetchProductFromVariantSlug } from '../../../redux/product/productActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedProductDetailRendererProps {
    className?: string;
}

export const ConnectedProductDetailRenderer: FC<ConnectedProductDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.productSlice.hasFetched);
    const productIsLoading = useTypedSelector(state => state.productSlice.isLoading);
    const breadcrumbs = useTypedSelector(state => state.productSlice.breadcrumbs);
    const product = useTypedSelector(state => state.productSlice.product);

    const brand = useTypedSelector(state => state.brandSlice.brand);

    const cartIsLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const wishlistIsLoading = useTypedSelector(state => state.wishlistSlice.isLoading);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchProductFromVariantSlug(slug));
        }
    }, [slug]);

    useClientEffect((): void => {
        if (!product?.brand) {
            return;
        }

        dispatch(fetchBrand(product.brand.label));
    }, [product]);

    if (hasFetched && !product) {
        return (
            <NotFound />
        );
    }

    return (
        <ProductDetailRenderer
            productIsLoading={productIsLoading}
            cartIsLoading={cartIsLoading}
            wishlistIsLoading={wishlistIsLoading}
            breadcrumbs={breadcrumbs}
            product={product}
            brand={brand}
            className={className}
        />
    );
};
