import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { ConnectedSpaceDetailRenderer, ConnectedSpaceDetailWrapper } from '../../connectors';
import { transformSlugToName } from '../../helpers/string';

import './SpaceDetailPage.scss';

interface SpaceDetailPageProps {
    className?: string;
}

export const SpaceDetailPage: FC<SpaceDetailPageProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();
    const name = transformSlugToName(slug);

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect((): void => {
        if (!slug) {
            return;
        }

        // TODO: Replace brand with list ID or filtering Meilisearch (L5W-544)
        const query = slug;
        setProductsQuery(query);
    }, [slug]);

    return (
        <ConnectedSpaceDetailWrapper>
            {({ ssrQuery }) => (
                <Page className={`space-detail-page ${className}`}>
                    <ConnectedSpaceDetailRenderer title={name} />

                    <Wrapper>
                        TODO: Voeg producten toe voor
                        {' '}
                        {ssrQuery || productsQuery}
                    </Wrapper>
                </Page>
            )}
        </ConnectedSpaceDetailWrapper>
    );
};
