import { FC, ReactElement } from 'react';

import { Icon, Price } from '../../../../components';
import { Button, CartItem, LinkIconButton } from '../../../../compositions';
import { UspItem } from '../../../../entities/@blocks/UspSection/UspSection';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { OrderInterface } from '../../../../entities/Order/Order';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useDeviceWidth, useTrans } from '../../../../hooks';
import { PaymentMethods } from '../PaymentMethods/PaymentMethods';
import { Pricing } from '../Pricing/Pricing';

import './StartStep.scss';

interface StartStepProps extends OrderInterface {
    isAuthenticated: boolean;
    isLoading: boolean;
    uspItems: UspItem[];
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onClearCart: () => void;
    className?: string;
}

export const StartStep: FC<StartStepProps> = ({
    isAuthenticated,
    isLoading,
    uspItems,
    items,
    subTotalPrice,
    totalPrice,
    discount,
    onUpdateCartItem,
    onRemoveItemFromCart,
    onClearCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const nextStepPath = isAuthenticated
        ? trans(appRoutes[AppRoute.checkoutPayment].path)
        : trans(appRoutes[AppRoute.checkoutDetails].path);

    return (
        <div className={`start-step ${className}`}>
            <details open={!isMobile} className="start-step__left-section">
                <summary className="start-step__accordion-summary">
                    <div className="start-step__accordion-heading">
                        <h2 className="start-step__heading">
                            {trans('common.cart')}
                        </h2>

                        <Icon name="chevron-down" className="start-step__accordion-icon" />
                    </div>

                    <Price
                        includeCurrencySymbol
                        amount={totalPrice}
                        className="start-step__accordion-price"
                    />
                </summary>

                <section className="start-step__left-section-wrapper">
                    <div className="start-step__top">
                        <h2 className="start-step__heading">
                            {trans('common.cart')}
                        </h2>

                        {items.length !== 0 && (
                            <Button
                                text={trans('containers.checkout.startStep.clear')}
                                onClick={onClearCart}
                                className="start-step__clear-cart-button"
                            />
                        )}
                    </div>

                    <div className="start-step__product-list">
                        {items.map(item => (
                            <CartItem
                                {...item}
                                showBigRemoveButton
                                isEditMode
                                key={item.id}
                                isLoading={isLoading}
                                onUpdateCartItem={onUpdateCartItem}
                                onRemoveItemFromCart={onRemoveItemFromCart}
                                className="start-step__product"
                            />
                        ))}

                        {!isLoading && items.length === 0 && (
                            <div className="start-step__no-products-wrapper">
                                <Icon name="cart" className="start-step__no-products-icon" />

                                <p className="start-step__no-products-text">
                                    {trans('containers.checkout.startStep.noProductsInCart')}
                                </p>
                            </div>
                        )}
                    </div>
                </section>
            </details>

            <section className="start-step__right-section">
                <h2 className="start-step__heading">
                    {trans('containers.checkout.startStep.yourOrder')}
                </h2>

                <Pricing
                    subTotal={subTotalPrice}
                    total={totalPrice}
                    discount={discount}
                    className="start-step__prices-wrapper"
                />

                <LinkIconButton
                    isSmall
                    hasAnimation
                    icon="arrow-right"
                    iconPos={HorizontalAlignment.right}
                    to={nextStepPath}
                    text={trans('containers.checkout.startStep.continueToDetails')}
                    disabled={!items.length}
                    className="start-step__button"
                />

                <PaymentMethods />

                <ul className="start-step__usp-list">
                    {uspItems.map(item => (
                        <li key={item.id} className="start-step__usp-item">
                            <Icon
                                name={item.icon}
                                className="start-step__usp-item-icon"
                            />

                            {item.text}
                        </li>
                    ))}
                </ul>
            </section>
        </div>
    );
};
