import { FC, ReactElement } from 'react';

import { AccountTabHeader } from '../../compositions';
import { ConnectedAccountData } from '../../connectors';
import { useTrans } from '../../hooks';

import './AccountDataPage.scss';

export const AccountDataPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-data-page">
            <AccountTabHeader
                title={trans('pages.accountData.accountData')}
                description={trans('pages.accountData.description')}
            />

            <ConnectedAccountData className="account-data-page__content" />
        </div>
    );
};
