/* eslint-disable max-len */

import { BrandLogo } from '../../entities/@blocks/BrandCarousel/BrandCarousel';
import { Brand } from '../../entities/Brand/Brand';
import { MOCK_IMAGE_1 } from './media';

export const MOCK_BRANDS: Brand[] = [
    {
        id: '1',
        blocks: [],
        name: 'Fatboy',
        slug: 'fatboy',
        description: 'Fatboy is een Nederlands designmerk dat in 2002 is opgericht. Het is vooral bekend van de Fatboy zitzak, die inmiddels in vele kleuren en stoffen verkrijgbaar is. Naast zitzakken maakt Fatboy ook lampen, poefs, tafels en andere meubels en accessoires.',
        logo: {
            src: '../mock/brand-logos/fatboy.png',
            alt: 'fatboy',
            formats: {
                original: {
                    srcSet: '../mock/brand-logos/fatboy.png',
                    type: 'image/png',
                    media: '(min-width: 320px)',
                },
            },
        },
    },
    {
        id: '2',
        blocks: [],
        name: 'HAY',
        slug: 'hay',
        logo: {
            src: '../mock/brand-logos/hay.png',
            alt: 'hay',
            formats: {
                original: {
                    srcSet: '../mock/brand-logos/hay.png',
                    type: 'image/png',
                    media: '(min-width: 320px)',
                },
            },
        },
    },
    {
        id: '3',
        blocks: [],
        name: 'Loods5-design',
        slug: 'loods5-design',
        description: 'De eigen collectie van Loods 5 staat garant voor prachtige basics in neutrale kleuren met een eigentijds karakter. Mooie artikelen die goed gemaakt zijn met aandacht voor kwaliteit, zodat je er jaren van geniet. Het assortiment van Loods 5 vindt makkelijk een plekje in je huis, want je gebruikt deze meubels en woonaccessoires iedere dag!',
        logo: {
            src: '../mock/brand-logos/loods5-design.png',
            alt: 'Loods5-design',
            formats: {
                original: {
                    srcSet: '../mock/brand-logos/loods5-design.png',
                    type: 'image/png',
                    media: '(min-width: 320px)',
                },
            },
        },
    },
    {
        id: '4',
        blocks: [],
        name: 'WOOOD',
        slug: 'woood',
        logo: {
            src: '../mock/brand-logos/woood.png',
            alt: 'WOOOD',
            formats: {
                original: {
                    srcSet: '../mock/brand-logos/woood.png',
                    type: 'image/png',
                    media: '(min-width: 320px)',
                },
            },
        },
    },
    {
        id: '5',
        blocks: [],
        name: 'Zuiver',
        slug: 'zuiver',
        logo: {
            src: '../mock/brand-logos/zuiver.png',
            alt: 'zuiver',
            formats: {
                original: {
                    srcSet: '../mock/brand-logos/zuiver.png',
                    type: 'image/png',
                    media: '(min-width: 320px)',
                },
            },
        },
    },
];

export const MOCK_BRANDS_LOGOS: BrandLogo[] = MOCK_BRANDS.map(brand => ({
    id: brand.id,
    label: brand.name,
    href: brand.slug,
    image: brand.logo ?? MOCK_IMAGE_1,
}));
