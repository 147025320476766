import { FC, ReactElement } from 'react';

import { Button } from '../../../../compositions';

import './Suggestions.scss';

interface SuggestionsProps {
    label: string;
    suggestions: string[];
    onSuggestionClick: (suggestion: string) => void;
    className?: string;
}

export const Suggestions: FC<SuggestionsProps> = ({
    label,
    suggestions,
    onSuggestionClick,
    className = '',
}): ReactElement => (
    <div className={`suggestions ${className}`}>
        <p className="suggestions__label">
            {label}
        </p>

        <ul className="suggestions__list">
            {suggestions.map(suggestion => {
                const handleSuggestionClick = (): void => {
                    onSuggestionClick(suggestion);
                };

                return (
                    <li key={suggestion}>
                        <Button
                            text={suggestion}
                            onClick={handleSuggestionClick}
                            className="suggestions__list-item"
                        />
                    </li>
                );
            })}
        </ul>
    </div>
);

