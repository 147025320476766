import {
    CSSProperties,
    FC,
    ReactElement,
    TransitionEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';

import classNames from 'classnames';

import { SwipeDetector, Wrapper } from '../../../../components';
import { IconButton, LinkIconButton } from '../../../../compositions';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { Navigation, NavigationItem, NavigationTransitionState } from '../../../../entities/Navigation/Navigation';
import { resetElementScroll } from '../../../../helpers/scroll';
import { usePrefersReducedMotion, useTimeout, useTrans } from '../../../../hooks';
import { NavigationLink } from '../NavigationLink/NavigationLink';

import './MobileNavigation.scss';

interface MobileNavigationProps {
    isLoading: boolean;
    isOpen: boolean;
    hasActiveSubNavigation: boolean;
    shouldNavigateBack: boolean;
    navigation?: Navigation;
    activeNavigation?: Navigation;
    accountNavigationItems: NavigationItem[];
    onNavigationToggle: () => void;
    onNavigationChange: (navigation?: Navigation) => void;
    onBackTransitionMidpoint: () => void;
    onTransitionEnd: () => void;
    className?: string;
}

export const MobileNavigation: FC<MobileNavigationProps> = ({
    isLoading,
    isOpen,
    hasActiveSubNavigation,
    shouldNavigateBack,
    navigation,
    activeNavigation,
    accountNavigationItems,
    onNavigationToggle,
    onNavigationChange,
    onBackTransitionMidpoint,
    onTransitionEnd,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const userPrefersReducedMotion = usePrefersReducedMotion();

    const [navigationToBe, setNavigationToBe] = useState<Navigation>();
    const [transitionState, setTransitionState] = useState<NavigationTransitionState>(NavigationTransitionState.idle);

    const transitionDuration = userPrefersReducedMotion ? 0 : 300;

    const navigationLabel = activeNavigation?.parent?.label || trans('containers.menu.mainNavigation');

    const hasLowerCaseTypeface = useMemo((): boolean => {
        if (!navigation || !activeNavigation) {
            return false;
        }

        return navigation.id !== activeNavigation.id;
    }, [navigation, activeNavigation]);

    const handleGoBackwards = (): void => {
        if (navigation && navigation.id !== activeNavigation?.id) {
            setTransitionState(NavigationTransitionState.goingBackwards);
        }
    };

    const handleDrawerTransitionEnd = (event: TransitionEvent<HTMLDivElement>): void => {
        if (!isOpen) {
            setTransitionState(NavigationTransitionState.idle);
            onNavigationChange(navigation);

            resetElementScroll<HTMLDivElement>(event.currentTarget);
        }
    };

    useEffect((): void => {
        if (shouldNavigateBack) {
            handleGoBackwards();
        }
    }, [shouldNavigateBack]);

    // Code to run halfway through navigation transition, when everything is invisible
    useTimeout((): void => {
        if (transitionState === NavigationTransitionState.idle) {
            return;
        }

        if (transitionState === NavigationTransitionState.goingBackwards) {
            onBackTransitionMidpoint();
            return;
        }

        if (transitionState === NavigationTransitionState.goingForwards && navigationToBe) {
            onNavigationChange(navigationToBe);
            setNavigationToBe(undefined);
        }
    }, transitionDuration / 2, [transitionState]);

    // Code to run if animation has completed
    useTimeout((): void => {
        setTransitionState(NavigationTransitionState.idle);
        onTransitionEnd();
    }, transitionDuration, [transitionState]);

    const drawerClassNames = classNames('mobile-navigation__drawer', {
        'mobile-navigation__drawer--is-open': isOpen,
    });

    const navigationClassNames = classNames('mobile-navigation__navigation', [
        `mobile-navigation__navigation--is-${transitionState}`,
    ]);

    const cssVariables = {
        '--navigation-transition-duration': `${transitionDuration}ms`,
    } as CSSProperties;

    return (
        <div style={cssVariables} className={`mobile-navigation ${className}`}>
            <IconButton
                hideLabel
                icon={isOpen ? 'cross' : 'hamburger'}
                text={trans(`containers.menu.toggleButton.${isOpen ? 'close' : 'open'}`)}
                disabled={isLoading}
                onClick={onNavigationToggle}
                className="mobile-navigation__toggle-button"
            />

            <div
                // @ts-ignore
                inert={!isOpen ? 'true' : undefined}
                onTransitionEnd={handleDrawerTransitionEnd}
                className={drawerClassNames}
            >
                <SwipeDetector threshold={100} onSwipeRight={handleGoBackwards}>
                    <nav aria-label={navigationLabel} className={navigationClassNames}>
                        {activeNavigation && (
                            <Wrapper className="mobile-navigation__contents">
                                {activeNavigation.groups.map(group => (
                                    <ul key={group.id} className="mobile-navigation__group">
                                        {group.items.map(item => {
                                            const handleItemClick = (): void => {
                                                if (item.subNavigation) {
                                                    setNavigationToBe(item.subNavigation);
                                                    setTransitionState(NavigationTransitionState.goingForwards);
                                                }
                                            };

                                            return (
                                                <NavigationLink
                                                    key={item.id}
                                                    hasLowerCaseTypeface={hasLowerCaseTypeface}
                                                    item={item}
                                                    onButtonClick={handleItemClick}
                                                    onLinkClick={onNavigationToggle}
                                                    className="mobile-navigation__item"
                                                />
                                            );
                                        })}
                                    </ul>
                                ))}

                                {!hasActiveSubNavigation && (
                                    <ul className="mobile-navigation__group">
                                        {accountNavigationItems?.map(item => (
                                            <NavigationLink
                                                key={item.id}
                                                isAccountNavigation
                                                item={item}
                                                className="mobile-navigation__item"
                                            />
                                        ))}
                                    </ul>
                                )}

                                {activeNavigation.parent && (
                                    <LinkIconButton
                                        to={activeNavigation.parent.href}
                                        icon="arrow-short-right"
                                        iconPos={HorizontalAlignment.right}
                                        text={trans('containers.menu.visitParent')}
                                        onClick={onNavigationToggle}
                                        className="mobile-navigation__parent-link-button"
                                        iconClassName="mobile-navigation__parent-link-icon"
                                    />
                                )}
                            </Wrapper>
                        )}
                    </nav>
                </SwipeDetector>
            </div>
        </div>
    );
};
