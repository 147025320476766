import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaginationInterface } from '../../../entities/Pagination/Pagination';
import { Tab } from '../../../entities/Tab/Tab';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type BlogCategoriesState = AsyncReduxState<{
    categories: string[];
    categoryTabs: Tab[];
    pagination?: PaginationInterface;
}>;

const initialState: BlogCategoriesState = {
    ...initialAsyncReduxState,
    categories: [],
    categoryTabs: [],
};

const blogCategoriesSlice = createSlice({
    name: 'blogCategoriesSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): BlogCategoriesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): BlogCategoriesState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): BlogCategoriesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): BlogCategoriesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setBlogCategories(state, action: PayloadAction<string[]>): BlogCategoriesState {
            return {
                ...state,
                categories: action.payload,
            };
        },
        setBlogCategoryTabs(state, action: PayloadAction<Tab[]>): BlogCategoriesState {
            return {
                ...state,
                categoryTabs: action.payload,
            };
        },
        setPagination(state, action: PayloadAction<PaginationInterface | undefined>): BlogCategoriesState {
            return {
                ...state,
                pagination: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setBlogCategories,
    setBlogCategoryTabs,
    setPagination,
} = blogCategoriesSlice.actions;

export default blogCategoriesSlice;
