import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { OrderDetailRenderer } from '../../../containers';
import { fetchOrderDetail } from '../../../redux/@account/orderDetail/orderDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedOrderDetailRendererProps {
    className?: string;
}

export const ConnectedOrderDetailRenderer: FC<ConnectedOrderDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { id } = useParams<{ id: string }>();

    const dispatch = useTypedDispatch();

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    const isLoading = useTypedSelector(state => state.orderDetailSlice.isLoading);
    const orderDetail = useTypedSelector(state => state.orderDetailSlice.orderDetail);

    useEffect((): void => {
        if (isAuthenticated && id) {
            dispatch(fetchOrderDetail(id));
        }
    }, [isAuthenticated, id]);

    return (
        <OrderDetailRenderer
            isLoading={isLoading}
            order={orderDetail}
            className={className}
        />
    );
};
