import { FC, PropsWithChildren, ReactElement } from 'react';

import { DefaultHelmet } from '../../../../../compositions';
import { BlogPost } from '../../../../../entities/@blog/BlogPost/BlogPost';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { generateBreadcrumbStructuredData } from '../../../../../helpers/structuredData';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

interface BlogPostHelmetProps extends PropsWithChildren {
    blogPost: BlogPost;
}

export const BlogPostHelmet: FC<BlogPostHelmetProps> = ({
    blogPost,
    children,
}): ReactElement => {
    const trans = useTrans();

    const siteUrl = trans('company.url');

    const logoUrl = `${siteUrl}/images/logo.jpg`;

    const metaTitle = blogPost.seo
        ? blogPost.seo.title
        : blogPost.title;

    const metaDescription = blogPost.seo
        ? blogPost.seo.description
        : blogPost.intro;

    const metaKeywords = blogPost.seo
        ? blogPost.seo.keywords
        : undefined;

    const metaImage = blogPost.seo
        ? blogPost.seo.image
        : blogPost.image;

    const blogCategoryPath = trans(appRoutes[AppRoute.blogOverviewCategory].path);
    const blogCategoryUrl = replaceUrlParamKeysWithValues(blogCategoryPath, {
        category: blogPost.category.slug,
    });

    const blogPostPath = trans(appRoutes[AppRoute.blogPost].path);
    const blogPostUrl = replaceUrlParamKeysWithValues(blogPostPath, {
        category: blogPost.category.slug,
        slug: blogPost.slug,
    });

    const breadcrumbs = generateBreadcrumbStructuredData(siteUrl, [
        {
            label: trans(appRoutes[AppRoute.blogOverview].label),
            href: trans(appRoutes[AppRoute.blogOverview].path),
        },
        {
            label: blogPost.category.title,
            href: blogCategoryUrl,
        },
        {
            label: blogPost.title,
            href: blogPostUrl,
        },
    ]);

    return (
        <DefaultHelmet
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
            image={metaImage}
            canonical={blogPost.seo?.canonicalURL}
        >
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'BlogPosting',
                    mainEntityOfPage: {
                        '@type': 'WebPage',
                    },
                    author: {
                        '@type': 'Organization',
                        name: trans('company.name'),
                    },
                    publisher: {
                        '@type': 'Organization',
                        name: trans('company.name'),
                        logo: {
                            '@type': 'ImageObject',
                            url: logoUrl,
                        },
                    },
                    headline: metaTitle,
                    image: metaImage?.src ? [metaImage.src] : undefined,
                    datePublished: blogPost.publishDate,
                    dateModified: blogPost.modifyDate,
                })}
            </script>

            <script type="application/ld+json">
                {breadcrumbs}
            </script>

            {children}
        </DefaultHelmet>
    );
};
