import { ProductVariantColor, ProductVariantColorVariable } from './ProductVariantColor';

export const transformToProductVariantColorVariable = (resource: ProductVariantColor): ProductVariantColorVariable => {
    const colorTable = {
        [ProductVariantColor.anthracite]: ProductVariantColorVariable.anthracite,
        [ProductVariantColor.beige]: ProductVariantColorVariable.beige,
        [ProductVariantColor.blue]: ProductVariantColorVariable.blue,
        [ProductVariantColor.brown]: ProductVariantColorVariable.brown,
        [ProductVariantColor.cognac]: ProductVariantColorVariable.cognac,
        [ProductVariantColor.yellow]: ProductVariantColorVariable.yellow,
        [ProductVariantColor.gold]: ProductVariantColorVariable.gold,
        [ProductVariantColor.grey]: ProductVariantColorVariable.grey,
        [ProductVariantColor.green]: ProductVariantColorVariable.green,
        [ProductVariantColor.multicolor]: ProductVariantColorVariable.multicolor,
        [ProductVariantColor.orange]: ProductVariantColorVariable.orange,
        [ProductVariantColor.purple]: ProductVariantColorVariable.purple,
        [ProductVariantColor.red]: ProductVariantColorVariable.red,
        [ProductVariantColor.pink]: ProductVariantColorVariable.pink,
        [ProductVariantColor.taupe]: ProductVariantColorVariable.taupe,
        [ProductVariantColor.transparent]: ProductVariantColorVariable.transparent,
        [ProductVariantColor.turquoise]: ProductVariantColorVariable.turquoise,
        [ProductVariantColor.white]: ProductVariantColorVariable.white,
        [ProductVariantColor.silver]: ProductVariantColorVariable.silver,
        [ProductVariantColor.black]: ProductVariantColorVariable.black,
    };

    return colorTable[resource] || ProductVariantColorVariable.noData;
};
