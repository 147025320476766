import { RequestCustomerResetPasswordResponse, syliusFetch } from '../../entities/@api/Sylius';
import { ForgotPasswordFormData } from '../../entities/@forms/ForgotPasswordForm/ForgotPasswordForm';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSuccessful } from './resetPasswordSlice';

export const forgotPassword = (formData: ForgotPasswordFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsSuccessful(false));

    try {
        const requestBody = {
            email: formData.username,
        };

        const forgotPasswordResponse = await syliusFetch<RequestCustomerResetPasswordResponse>('/shop/customers/reset-password', {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });

        if (!isFetchResultSuccessful(forgotPasswordResponse)) {
            dispatch(setError(forgotPasswordResponse.error));
            return;
        }

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[forgotPassword]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
