import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedBlockRenderer, ConnectedFaq, ConnectedPageHelmet } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';

import './CustomerServiceFaqPage.scss';

export const CustomerServiceFaqPage: FC = (): ReactElement => (
    <Page className="customer-service-faq-page">
        <ConnectedPageHelmet appRoute={AppRoute.customerServiceFaq} />

        <ConnectedFaq
            showAllFaq
            showSearchBar
            faqItems={[]}
        />

        <ConnectedBlockRenderer
            appRoute={AppRoute.customerServiceFaq}
            className="customer-service-faq-page__page-renderer"
        />
    </Page>
);
