import { FC, ReactElement, useEffect } from 'react';

import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';

import { Logo, Page, Wrapper } from '../../components';
import { ConnectedBlockRenderer, ConnectedCheckout } from '../../connectors';
import { CheckoutNavigation } from '../../containers';
import { orderParamKey, PaymentStatus, stateParamKey } from '../../entities/@api/Sylius';
import { CheckoutParams, CheckoutTab } from '../../entities/Checkout/Checkout';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useRouteByTranslatedPath, useTrans } from '../../hooks';
import { removeCartLocally } from '../../redux/cart/cartActions';
import { useTypedDispatch } from '../../redux/store';
import { addNegativeToast } from '../../redux/toast/toastActions';

import './CheckoutPage.scss';

interface CheckoutPageProps {
    page?: string;
}

export const CheckoutPage: FC<CheckoutPageProps> = (): ReactElement => {
    const trans = useTrans();
    const dispatch = useTypedDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const { id } = useParams<CheckoutParams>();

    const navigateToCheckoutPage = (orderId: string): void => {
        const pathname = trans(appRoutes[AppRoute.checkoutThankYou].path);
        const params = new URLSearchParams();

        params.set(orderParamKey, orderId);

        const paramString = params.toString();
        const query = `?${paramString}`;

        navigate(pathname + query);
    };

    useEffect((): void => {
        const orderId = searchParams.get(orderParamKey);
        const orderState = searchParams.get(stateParamKey);

        if (orderId && orderState === PaymentStatus.error) {
            navigate(trans(appRoutes[AppRoute.checkoutPayment].path));

            dispatch(addNegativeToast({
                title: trans('pages.checkoutPage.paymentHasFailedTitle'),
                description: trans('pages.checkoutPage.paymentHasFailedDescription'),
            }));

            return;
        }

        if (orderId && (orderState === PaymentStatus.success || orderState === PaymentStatus.pending)) {
            dispatch(removeCartLocally());

            navigateToCheckoutPage(orderId);
        }
    }, [searchParams]);

    const { pathname } = useLocation();

    const routeByTranslatedPath = useRouteByTranslatedPath();

    const translatedTab = routeByTranslatedPath(pathname, { id }) as CheckoutTab;

    return (
        <Page className="checkout-page">
            <Wrapper className="checkout-page__navigation-wrapper">
                {translatedTab !== CheckoutTab.order && (
                    <Logo className="checkout-page__logo" />
                )}

                <CheckoutNavigation />
            </Wrapper>

            <Wrapper className="checkout-page__contents-wrapper">
                <ConnectedCheckout tab={translatedTab} />
            </Wrapper>

            {translatedTab === CheckoutTab.order && (
                <ConnectedBlockRenderer appRoute={AppRoute.checkoutOrder} />
            )}
        </Page>
    );
};
