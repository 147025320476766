import { FC, ReactElement } from 'react';

import { Page } from '../../../components';
import { ConnectedStoreDetailRenderer } from '../../../connectors';

export const StoreDetailPage: FC = (): ReactElement => (
    <Page className="store-detail-page">
        <ConnectedStoreDetailRenderer />
    </Page>
);
