import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EnrichedOrderDetailInterface } from '../../../entities/@account/OrderDetail/OrderDetail';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type OrderDetailState = AsyncReduxState<{
    orderDetail?: EnrichedOrderDetailInterface;
}>;

const initialState: OrderDetailState = {
    ...initialAsyncReduxState,
};

const orderDetailSlice = createSlice({
    name: 'orderDetailSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): OrderDetailState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): OrderDetailState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): OrderDetailState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): OrderDetailState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setOrderDetail(state, action: PayloadAction<EnrichedOrderDetailInterface | undefined>): OrderDetailState {
            return {
                ...state,
                orderDetail: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setOrderDetail,
} = orderDetailSlice.actions;

export default orderDetailSlice;
