import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductDefault } from '../../entities/ProductDefault/ProductDefault';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ProductDefaultState = AsyncReduxState<{
    productDefault?: ProductDefault;
}>;

const initialState: ProductDefaultState = {
    ...initialAsyncReduxState,
};

const productDefaultSlice = createSlice({
    name: 'productDefaultSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): ProductDefaultState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): ProductDefaultState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ProductDefaultState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProductDefaultState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setProductDefault(state, action: PayloadAction<ProductDefault | undefined>): ProductDefaultState {
            return {
                ...state,
                productDefault: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setHasFetched,
    setProductDefault,
} = productDefaultSlice.actions;

export default productDefaultSlice;
