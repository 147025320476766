import { FC, ReactElement } from 'react';

import './AccountTabHeader.scss';

interface AccountTabHeaderProps {
    title: string;
    description: string;
    className?: string;
}

export const AccountTabHeader: FC<AccountTabHeaderProps> = ({
    title,
    description,
    className = '',
}): ReactElement => (
    <header className={`account-tab-header ${className}`}>
        <h1 className="account-tab-header__label">
            {title}
        </h1>

        <p className="account-tab-header__description">
            {description}
        </p>
    </header>
);
