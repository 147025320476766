import { strapiFetch } from '../../entities/@api/Strapi';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { CustomPageConfig, PageResponse } from '../../entities/Page/Page';
import { generateCustomPageQuery, getPageDraftParams } from '../../entities/Page/PageRequests';
import { transformToPage } from '../../entities/Page/PageTransformers';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setPageData,
} from './customPageSlice';

export const fetchCustomPage: ReduxFetchAction<CustomPageConfig> = ({ slug, queryParams }) => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const draftParams = getPageDraftParams(queryParams);
        const query = generateCustomPageQuery(slug, draftParams);

        const pageResponse = await strapiFetch<PageResponse>(`/custom-pages?${query}`);

        if (!isFetchResultSuccessful(pageResponse)) {
            dispatch(setError(pageResponse.error));
            return;
        }

        const { data: pageData } = pageResponse.data;

        const pageResource = pageData
            ? pageData[0]
            : undefined;

        const page = pageResource
            ? transformToPage(pageResource)
            : undefined;

        dispatch(setPageData(page));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchCustomPage]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
