import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';

import { ErrorLabel } from '../../../../components';
import {
    Checkbox,
    IconButton,
    PasswordInput,
    RecaptchaDisclaimer,
    TextInput,
} from '../../../../compositions';
import { FormProps } from '../../../../entities/@forms/Form/Form';
import {
    CreateAccountDuringCheckoutFormData,
    defaultGuestFormData,
    GuestAddress,
    GuestFormData,
    PayAsGuestFormErrors,
    UpdateCartFormData,
} from '../../../../entities/@forms/GuestForm/GuestForm';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { Country, countryOptions } from '../../../../entities/Country';
import { LinkTarget } from '../../../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { scrollIntoView } from '../../../../helpers/scroll';
import { useTrans } from '../../../../hooks';
import { validateGuestFormData } from './validations';

import './GuestForm.scss';

interface GuestFormProps extends FormProps<CreateAccountDuringCheckoutFormData> {
    onContinueAsGuest: (data: UpdateCartFormData) => void;
    className?: string;
}

export const GuestForm: FC<GuestFormProps> = ({
    isLoading,
    error,
    onSubmit,
    onContinueAsGuest,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const formRef = useRef<HTMLFormElement>(null);

    const defaultCountry = countryOptions.find(item => item.value === Country.nl) || countryOptions[0];

    const [formErrors, setFormErrors] = useState<PayAsGuestFormErrors>({});
    const [formData, setFormData] = useState<GuestFormData>(defaultGuestFormData());
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const setFirstName = (firstName: string): void => setFormData({ ...formData, firstName });
    const setLastName = (lastName: string): void => setFormData({ ...formData, lastName });
    const setEmail = (email: string): void => setFormData({ ...formData, email });

    const setStreet = (street: string): void => setFormData({ ...formData, shippingAddressStreet: street });
    const setNumber = (number: string): void => setFormData({ ...formData, shippingAddressNumber: number });
    const setAddition = (addition: string): void => setFormData({ ...formData, shippingAddressAddition: addition });
    const setCity = (city: string): void => setFormData({ ...formData, shippingAddressCity: city });
    const setPostalCode = (postalCode: string): void => setFormData({ ...formData, shippingAddressPostalCode: postalCode });

    const setBillingStreet = (street: string): void => setFormData({ ...formData, billingAddressStreet: street });
    const setBillingNumber = (number: string): void => setFormData({ ...formData, billingAddressNumber: number });
    const setBillingAddition = (addition: string): void => setFormData({ ...formData, billingAddressAddition: addition });
    const setBillingCity = (city: string): void => setFormData({ ...formData, billingAddressCity: city });
    const setBillingPostalCode = (postalCode: string): void => setFormData({ ...formData, billingAddressPostalCode: postalCode });
    const setUseAsBillingAddress = (value: boolean): void => setFormData({ ...formData, useShippingAddressForBilling: value });

    const [createAccount, setCreateAccount] = useState<boolean>(false);
    const setPassword = (password: string): void => setFormData({ ...formData, password });

    const setSubscribedToNewsletter = (subscribedToNewsletter: boolean): void => setFormData({ ...formData, subscribedToNewsletter });

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }

        const [errors, hasErrors] = validateGuestFormData(formData);
        const token = await executeRecaptcha();

        setFormErrors(errors);
        setBotDetected(!token);

        const userWantsToRegister = formData.password?.length;

        if (hasErrors) {
            scrollIntoView<HTMLFormElement>(formRef);
            return;
        }

        const name = {
            firstName: formData.firstName,
            lastName: formData.lastName,
        };

        const shippingAddress: GuestAddress = {
            ...name,
            street: formData.shippingAddressStreet,
            addition: formData.shippingAddressAddition,
            number: formData.shippingAddressNumber,
            city: formData.shippingAddressCity,
            postalCode: formData.shippingAddressPostalCode,
            country: defaultCountry.value.toUpperCase(),
        };

        const defaultBillingAddress: GuestAddress = {
            ...name,
            street: formData.billingAddressStreet || '',
            addition: formData.billingAddressAddition || '',
            number: formData.billingAddressNumber || '',
            city: formData.billingAddressCity || '',
            postalCode: formData.billingAddressPostalCode || '',
            country: defaultCountry.value.toUpperCase(),
        };

        const billingAddress = formData.useShippingAddressForBilling
            ? shippingAddress
            : defaultBillingAddress;

        const addresses = {
            shippingAddress,
            billingAddress,
        };

        if (userWantsToRegister) {
            const body: CreateAccountDuringCheckoutFormData = {
                ...name,
                ...addresses,
                email: formData.email,
                password: formData.password || '',
                subscribedToNewsletter: formData.subscribedToNewsletter,
            };

            onSubmit(body);
            return;
        }

        const body: UpdateCartFormData = {
            ...addresses,
            email: formData.email,
            subscribedToNewsletter: formData.subscribedToNewsletter,
        };

        onContinueAsGuest(body);
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`guest-form ${className}`}
        >
            <div className="guest-form__content">
                <p className="guest-form__section-label">
                    {trans('containers.checkout.guestForm.personalData')}
                </p>

                <div className="guest-form__input-row">
                    <TextInput
                        autoComplete="given-name"
                        autoCapitalize="words"
                        label={trans('form.firstName')}
                        value={formData.firstName}
                        error={formErrors.firstName}
                        disabled={isLoading}
                        onChange={setFirstName}
                        className="guest-form__text-input"
                    />

                    <TextInput
                        autoComplete="family-name"
                        autoCapitalize="words"
                        label={trans('form.lastName')}
                        value={formData.lastName}
                        error={formErrors.lastName}
                        disabled={isLoading}
                        onChange={setLastName}
                        className="guest-form__text-input"
                    />
                </div>

                <TextInput
                    type="email"
                    autoComplete="email"
                    label={trans('form.email')}
                    value={formData.email}
                    error={formErrors.email}
                    disabled={isLoading}
                    onChange={setEmail}
                    className="guest-form__text-input"
                />

                <Checkbox
                    label={trans('containers.checkout.guestForm.subscribeToNewsletterDescription')}
                    checked={formData.subscribedToNewsletter}
                    disabled={isLoading}
                    onChange={setSubscribedToNewsletter}
                    className="guest-form__newsletter-subscription-input"
                />

                <p className="guest-form__section-label">
                    {trans('containers.checkout.guestForm.shippingAddress')}
                </p>

                <div className="guest-form__input-row">
                    <TextInput
                        autoCapitalize="words"
                        label={trans('form.street')}
                        value={formData.shippingAddressStreet}
                        error={formErrors.shippingAddressStreet}
                        disabled={isLoading}
                        onChange={setStreet}
                        className="guest-form__text-input"
                    />

                    <TextInput
                        type="number"
                        autoCapitalize="words"
                        label={trans('form.houseNumber')}
                        value={formData.shippingAddressNumber}
                        error={formErrors.shippingAddressNumber}
                        disabled={isLoading}
                        onChange={setNumber}
                        className="guest-form__text-input"
                    />

                    <TextInput
                        autoCapitalize="words"
                        label={trans('form.addition')}
                        value={formData.shippingAddressAddition}
                        disabled={isLoading}
                        onChange={setAddition}
                        className="guest-form__text-input"
                    />
                </div>

                <div className="guest-form__input-row">
                    <TextInput
                        autoCapitalize="words"
                        label={trans('form.postalCode')}
                        value={formData.shippingAddressPostalCode}
                        maxLength={7}
                        error={formErrors.shippingAddressPostalCode}
                        disabled={isLoading}
                        onChange={setPostalCode}
                        className="guest-form__text-input"
                    />

                    <TextInput
                        autoCapitalize="words"
                        label={trans('form.city')}
                        value={formData.shippingAddressCity}
                        error={formErrors.shippingAddressCity}
                        disabled={isLoading}
                        onChange={setCity}
                        className="guest-form__text-input"
                    />
                </div>
            </div>

            <Checkbox
                label={trans('containers.checkout.guestForm.useAsBillingAddress')}
                checked={formData.useShippingAddressForBilling}
                disabled={isLoading}
                onChange={setUseAsBillingAddress}
                className="guest-form__newsletter-subscription-input"
            />

            {!formData.useShippingAddressForBilling && (
                <div className="guest-form__content">
                    <p className="guest-form__section-label">
                        {trans('containers.checkout.guestForm.billingAddress')}
                    </p>

                    <div className="guest-form__input-row">
                        <TextInput
                            autoCapitalize="words"
                            label={trans('form.street')}
                            value={formData.billingAddressStreet}
                            error={formErrors.billingAddressStreet}
                            disabled={isLoading}
                            onChange={setBillingStreet}
                            className="guest-form__text-input"
                        />

                        <TextInput
                            type="number"
                            label={trans('form.houseNumber')}
                            value={formData.billingAddressNumber}
                            error={formErrors.billingAddressNumber}
                            disabled={isLoading}
                            onChange={setBillingNumber}
                            className="guest-form__text-input"
                        />

                        <TextInput
                            autoCapitalize="words"
                            label={trans('form.addition')}
                            value={formData.billingAddressAddition}
                            disabled={isLoading}
                            onChange={setBillingAddition}
                            className="guest-form__text-input"
                        />
                    </div>

                    <div className="guest-form__input-row">
                        <TextInput
                            autoCapitalize="words"
                            label={trans('form.postalCode')}
                            value={formData.billingAddressPostalCode}
                            maxLength={7}
                            error={formErrors.billingAddressPostalCode}
                            disabled={isLoading}
                            onChange={setBillingPostalCode}
                            className="guest-form__text-input"
                        />

                        <TextInput
                            autoCapitalize="words"
                            label={trans('form.city')}
                            value={formData.billingAddressCity}
                            error={formErrors.billingAddressCity}
                            disabled={isLoading}
                            onChange={setBillingCity}
                            className="guest-form__text-input"
                        />
                    </div>
                </div>
            )}

            <div className="guest-form__column">
                <div className="guest-form__content">
                    <p className="guest-form__section-label">
                        {trans('containers.checkout.guestForm.wantToMakeAnAccount')}
                    </p>

                    <Checkbox
                        label={trans('containers.checkout.guestForm.makeAccountDescription')}
                        checked={createAccount}
                        disabled={isLoading}
                        onChange={setCreateAccount}
                        className="guest-form__newsletter-subscription-input"
                    />

                    {createAccount && (
                        <PasswordInput
                            autoComplete="new-password"
                            label={trans('form.password')}
                            value={formData.password}
                            error={formErrors.password}
                            disabled={isLoading}
                            onChange={setPassword}
                            className="guest-form__text-input"
                            fieldClassName="guest-form__password-input-field"
                        />
                    )}

                    <p>
                        {trans('containers.checkout.guestForm.agreeWithTerms')}

                        <Link
                            to={trans(appRoutes[AppRoute.customerServiceTerms].path)}
                            target={LinkTarget.blank}
                        >
                            {trans('containers.checkout.guestForm.terms')}
                        </Link>
                    </p>

                    <IconButton
                        hasAnimation
                        type="submit"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        text={trans('containers.checkout.guestForm.nextStepButton')}
                        className="guest-form__submit-button"
                    />

                    <RecaptchaDisclaimer className="guest-form__recaptcha-disclaimer" />

                    {error && (
                        <div className="guest-form__error">
                            <ErrorLabel
                                text={error}
                                className="guest-form__error-label"
                            />
                        </div>
                    )}

                    {botDetected && (
                        <ErrorLabel text={trans('errors.unknownError')} />
                    )}
                </div>
            </div>
        </form>
    );
};
