import { FC, ReactElement, useMemo } from 'react';

import { BlockRenderer } from '../../../containers';
import { Block, BlockType } from '../../../entities/Block/Block';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useClientEffect } from '../../../hooks';
import { fetchPage } from '../../../redux/pages/pagesActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedBlockRendererProps {
    preventFetch?: boolean;
    appRoute: AppRoute;
    blocksToShow?: BlockType[];
    blocksToIgnore?: BlockType[];
    className?: string;
}

export const ConnectedBlockRenderer: FC<ConnectedBlockRendererProps> = ({
    preventFetch,
    appRoute,
    blocksToShow = [],
    blocksToIgnore = [],
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.pagesSlice.isLoading);
    const pages = useTypedSelector(state => state.pagesSlice.pages);

    useClientEffect((): void => {
        if (!pages[appRoute] && !preventFetch) {
            dispatch(fetchPage({ appRoute }));
        }
    }, []);

    const blocks = useMemo((): Block[] => {
        const pageData = pages[appRoute];

        if (!pageData) {
            return [];
        }

        return pageData.blocks.filter(block => {
            if (blocksToShow.length > 0) {
                return blocksToShow.includes(block.type as BlockType);
            }

            if (blocksToIgnore.length > 0) {
                return !blocksToIgnore.includes(block.type as BlockType);
            }

            return true;
        });
    }, [pages[appRoute], blocksToShow, blocksToIgnore]);

    return (
        <BlockRenderer
            isLoading={isLoading}
            blocks={blocks}
            className={className}
        />
    );
};
