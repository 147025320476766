import { FC, ReactElement } from 'react';

import { BrandDetailRenderer } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

export const ConnectedBrandDetailRenderer: FC = (): ReactElement => {
    const isLoading = useTypedSelector(state => state.brandSlice.isLoading);
    const brand = useTypedSelector(state => state.brandSlice.brand);

    return (
        <BrandDetailRenderer
            isLoading={isLoading}
            brand={brand}
        />
    );
};
