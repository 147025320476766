import { InstantSearchProductResource } from '../@api/Sylius';
import { transformInstantSearchBrandNameToLink } from '../Brand/BrandTransformers';
import { defaultMediaItem, transformInstantSearchImagesToMediaItems } from '../Media/MediaTransformers';
import { ProductDefault } from '../ProductDefault/ProductDefault';
import { ProductVariant } from '../ProductVariant/ProductVariant';
import { SearchProductResult } from '../Search/Search';
import { Product, productVariantSeparator } from './Product';

export const transformSlugToVariantId = (slug: string): string => slug.split(productVariantSeparator)[1];

export const transformToProductCode = (product: string): string => product.split('/api/v2/shop/products/')[1];

export const transformToVariantCode = (product: string): string => product.split('/api/v2/shop/product-variants/')[1];

export const transformProductHitToProduct = (resource: InstantSearchProductResource): Product => {
    const variantSlug = resource.slug + productVariantSeparator + resource.code;

    const brand = resource.brand
        ? transformInstantSearchBrandNameToLink(resource.brand)
        : undefined;

    const mediaItems = resource.main_image && resource.image
        ? transformInstantSearchImagesToMediaItems(resource)
        : [defaultMediaItem()];

    const colors = resource.attributes?.Color
        ? Object.values(resource.attributes.Color)
        : [];

    return {
        id: resource.id.toString(),
        slug: resource.slug,
        name: resource.article_title || '',
        productCode: resource.code,
        shortDescription: resource.short_description || '',
        description: resource.description || '',
        variantId: resource.id,
        variantCode: resource.code,
        brand,
        mediaItems,
        price: resource.price.LOODS_5.price / 100,
        attributes: [],
        dimensionAttributes: [],
        colors,
        variants: resource.variants || 0,
        variantSlug,
        stock: resource.stock,
        categories: resource.categories,
    };
};

export const transformInstantSearchProductToSearchProductResult = (resource: InstantSearchProductResource): SearchProductResult => {
    const slug = resource.slug + productVariantSeparator + resource.code;

    return {
        id: resource.id,
        name: resource.name || '',
        slug,
        brand: resource.brand.name,
        price: resource.price.LOODS_5.price / 100,
    };
};

export const transformToProduct = (
    productDefault: ProductDefault,
    productVariant: ProductVariant,
    categories: string[],
): Product => ({
    id: productDefault.id,
    slug: productDefault.slug || '',
    name: productVariant.name || '',
    shortDescription: productDefault.shortDescription || '',
    description: productDefault.description || '',
    mediaItems: productDefault.mediaItems,
    price: productVariant.price,
    attributes: productVariant.attributes,
    dimensionAttributes: productVariant.dimensionAttributes,
    brand: productVariant.brand,
    colors: productVariant.colors,
    variantCode: productVariant.variantCode,
    variantId: productVariant.variantId,
    variantSlug: productDefault.variantSlug,
    variants: productDefault.variants,
    stock: productVariant.stock,
    categories,
    productCode: productDefault.productCode,
});
