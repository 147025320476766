import { strapiFetch } from '../../../entities/@api/Strapi';
import { transformToDepartmentDetailInterface } from '../../../entities/@departments/DepartmentDetail/DepartmentDetailTransformers';
import { DepartmentOverviewRequestConfig, DepartmentOverviewResponse } from '../../../entities/@departments/DepartmentOverview/DepartmentOverview';
import { generateDepartmentOverviewQuery } from '../../../entities/@departments/DepartmentOverview/DepartmentOverviewRequests';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { transformStrapiPaginationToPagination } from '../../../entities/Pagination/PaginationTransformers';
import { ReduxFetchAction } from '../../defaults';
import {
    setDepartments,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setPagination,
} from './departmentOverviewSlice';

export const fetchDepartmentOverview: ReduxFetchAction<DepartmentOverviewRequestConfig> = config => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateDepartmentOverviewQuery(config);

        const departmentOverviewResponse = await strapiFetch<DepartmentOverviewResponse>(`/departments?${query}`);

        if (!isFetchResultSuccessful(departmentOverviewResponse)) {
            dispatch(setError(departmentOverviewResponse.error));
            return;
        }

        const { data: departmentOverviewData, meta: departmentOverviewMeta } = departmentOverviewResponse.data;

        const departments = departmentOverviewData
            ? departmentOverviewData.map(transformToDepartmentDetailInterface)
            : [];

        const pagination = departmentOverviewMeta
            ? transformStrapiPaginationToPagination(departmentOverviewMeta.pagination)
            : undefined;

        dispatch(setDepartments(departments));
        dispatch(setPagination(pagination));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchDepartmentOverview]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
