import { FC, PropsWithChildren, ReactElement } from 'react';

import { Address as AddressInterface } from '../../entities/Address/Address';

import './Address.scss';

interface AddressProps extends PropsWithChildren {
    address: AddressInterface;
    className?: string;
}

export const Address: FC<AddressProps> = ({
    address,
    className = '',
    children,
}): ReactElement => (
    <address className={`address ${className}`}>
        {address.label && (
            <strong className="address__label">
                {address.label}
            </strong>
        )}

        {`${address.street} ${address.houseNumber}${address.addition || ''}`}
        <br />
        {`${address.postalCode.toUpperCase()}  ${address.city.toUpperCase()}`}
        <br />
        {address.country}

        {children}
    </address>
);
