import { ParticipantFormSubscriptionResponse, syliusFetch } from '../../../entities/@api/Sylius';
import { ParticipateFormData } from '../../../entities/@forms/ParticipateFormData/ParticipateFormData';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import trans from '../../../helpers/trans';
import { ReduxFetchAction } from '../../defaults';
import { addNegativeToast, addPositiveToast } from '../../toast/toastActions';
import { setError, setIsLoading, setIsSent } from './participateFormSlice';

export const sendParticipateForm: ReduxFetchAction<ParticipateFormData> = formData => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsSent(false));

    try {
        const body: ParticipantFormSubscriptionResponse = {
            company: formData.companyName,
            postcode: formData.postalCode,
            number: formData.houseNumber,
            addition: formData.addition || null,
            street: formData.street,
            city: formData.city,
            countryCode: formData.country,
            chamberOfCommerce: formData.kvk,
            firstName: formData.firstName,
            lastName: formData.lastName,
            nameInfix: formData.insertion || null,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            website: formData.website || null,
            acceptTermsAndConditions: formData.acceptTermsAndConditions,
        };

        const registerParticipantResponse = await syliusFetch<ParticipantFormSubscriptionResponse>('/shop/forms/participants/signup', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(registerParticipantResponse)) {
            dispatch(setError(registerParticipantResponse.error));

            dispatch(addNegativeToast({
                title: trans('redux.participantForm.failedMessageTitle'),
                description: registerParticipantResponse.error,
            }));

            return;
        }

        dispatch(setIsSent(true));

        dispatch(addPositiveToast({
            title: trans('redux.participantForm.successMessageTitle'),
            description: trans('redux.participantForm.successMessageDescription'),
        }));
    } catch (error) {
        console.error('[sendParticipateForm]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
