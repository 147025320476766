import { FC, ReactElement, useState } from 'react';

import { AccountTabHeader, TabList } from '../../compositions';
import { ConnectedAccountDashboardConfig, ConnectedOrderOverview } from '../../connectors';
import { OrderOverviewType } from '../../entities/@account/OrderOverview/OrderOverview';
import { useTrans } from '../../hooks';

import './AccountDashboardPage.scss';

export const AccountDashboardPage: FC = (): ReactElement => {
    const trans = useTrans();

    const [activeOrderTab, setActiveOrderTab] = useState<OrderOverviewType>(OrderOverviewType.online);

    const tabs = [
        { label: trans('pages.accountDashboard.recentOrders.onlineOrders'), value: OrderOverviewType.online },
        // TODO: Enable when in store purchases are retrievable — it'll just work after uncommenting the next line
        // { label: trans('pages.accountDashboard.recentOrders.inStoreOrders'), value: OrderOverviewType.inStore },
    ];

    const handleSelectOrderTab = (value: string): void => {
        setActiveOrderTab(value as OrderOverviewType);
    };

    return (
        <div className="account-dashboard-page">
            <AccountTabHeader
                title={trans('pages.accountDashboard.dashboard')}
                description={trans('pages.accountDashboard.description')}
            />

            <div className="account-dashboard-page__recent-orders">
                <h2 className="account-dashboard-page__heading">
                    {trans('pages.accountDashboard.recentOrders.title')}
                </h2>

                <TabList
                    tabs={tabs}
                    activeTab={activeOrderTab}
                    onTabSelect={handleSelectOrderTab}
                    className="account-dashboard-page__tab-list"
                    tabItemLabelClassName="account-dashboard-page__tab-item-label"
                />

                <ConnectedOrderOverview
                    overviewType={activeOrderTab}
                    pageSize={3}
                    className="account-dashboard-page__order-overview"
                />
            </div>

            <div className="account-dashboard-page__configuration-section">
                <ConnectedAccountDashboardConfig />
            </div>
        </div>
    );
};
