export enum InstantSearchSortingDirection {
    ascending = 'asc',
    descending = 'desc',
}

export enum InstantSearchStatus {
    idle = 'idle',
    loading = 'loading',
    stalled = 'stalled',
    error = 'error',
}

export enum MeilisearchIndex {
    pages = 'pages',
    variantsNl = 'variants_nl',
    variantsEn = 'variants_en',
}

export interface MeilisearchSearchResult {
    nbHits: number;
}

export const resultsPerPage = 30;
