import { NewsletterSubscriptionResponse, syliusFetch } from '../../../entities/@api/Sylius';
import { NewsletterFormData } from '../../../entities/@forms/NewsletterForm/NewsletterForm';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import trans from '../../../helpers/trans';
import { ReduxFetchAction } from '../../defaults';
import { addPositiveToast } from '../../toast/toastActions';
import { setError, setIsLoading, setIsSent } from './newsletterFormSlice';

export const signUpForNewsletter: ReduxFetchAction<NewsletterFormData> = ({ email }) => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsSent(false));

    try {
        const body = { email };

        const registerResponse = await syliusFetch<NewsletterSubscriptionResponse>('/shop/newsletter-subscription', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(registerResponse)) {
            dispatch(setError(registerResponse.error));
            return;
        }

        dispatch(setIsSent(true));

        dispatch(addPositiveToast({
            title: trans('redux.newsletterForm.successMessageTitle'),
            description: trans('redux.newsletterForm.successMessageDescription'),
        }));
    } catch (error) {
        console.error('[signUpForNewsletter]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
