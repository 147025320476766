import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';

import './StoreOverviewSkeletons.scss';

interface StoreOverviewSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const StoreOverviewSkeletons: FC<StoreOverviewSkeletonsProps> = ({
    numberOfSkeletons = 6,
    className = '',
}): ReactElement => {
    const storeListSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`store-overview-skeletons ${className}`}>
            {storeListSkeletons.map(store => (
                <li key={store} className="store-overview-skeletons__item">
                    <Skeleton className="store-overview-skeletons__title" />

                    <div className="store-overview-skeletons__address">
                        <Skeleton className="store-overview-skeletons__address-line" />
                        <Skeleton className="store-overview-skeletons__address-line" />
                    </div>

                    <Skeleton className="store-overview-skeletons__opening-hours" />

                    <Skeleton className="store-overview-skeletons__link-button" />
                </li>
            ))}
        </ul>
    );
};
