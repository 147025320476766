import { FC, ReactElement } from 'react';

import { SpacesOverviewRenderer } from '../../../containers';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTypedSelector } from '../../../redux/store';

export const ConnectedSpacesOverviewRenderer: FC = (): ReactElement => {
    const pages = useTypedSelector(state => state.pagesSlice.pages);
    const pageData = pages[AppRoute.spacesOverview];

    const isLoading = useTypedSelector(state => state.spaceSlice.isLoading);
    const spaces = useTypedSelector(state => state.spaceSlice.spaces);

    return (
        <SpacesOverviewRenderer
            spaces={spaces}
            isLoading={isLoading}
            intro={pageData?.description || ''}
        />
    );
};
