import { Image } from '../../entities/Media/Media';

export const PAYMENT_METHOD_MASTERCARD: Image = {
    src: '/payment-methods/MasterCard.webp',
    formats: {
        original: {
            srcSet: '/payment-methods/MasterCard.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Afbeelding van MasterCard',
};

export const PAYMENT_METHOD_IDEAL: Image = {
    src: '/payment-methods/iDeal.webp',
    formats: {
        original: {
            srcSet: '/payment-methods/iDeal.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Afbeelding van iDeal',
};

export const PAYMENT_METHOD_GIFTCARD: Image = {
    src: '/payment-methods/Giftcard.webp',
    formats: {
        original: {
            srcSet: '/payment-methods/Giftcard.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Afbeelding van Giftcard',
};

export const PAYMENT_METHOD_VISA: Image = {
    src: '/payment-methods/Visa.webp',
    formats: {
        original: {
            srcSet: '/payment-methods/Visa.webp',
            type: 'image/webp',
            media: '(min-width: 320px)',
        },
    },
    alt: 'Afbeelding van Visa',
};
