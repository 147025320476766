// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const objectContainsValues = <T extends Record<string, any>>(object: T): boolean => {
    const valueArray = Object.values(object);

    for (let i = 0; i < valueArray.length; i += 1) {
        if (![undefined, null].includes(valueArray[i])) {
            return true;
        }
    }

    return false;
};

export const areObjectsEqual = (value1: object, value2: object): boolean => JSON.stringify(value1) === JSON.stringify(value2);

export const isEmptyObject = (object: Record<string, unknown>) => Object.keys(object).length === 0;
