import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import {
    ConnectedBlockRenderer,
    ConnectedPageHelmet,
    ConnectedSpacesOverviewRenderer,
    ConnectedSpacesOverviewWrapper,
} from '../../connectors';
import { AppRoute } from '../../entities/Routing/Routing';

import './SpaceOverviewPage.scss';

interface SpaceOverviewPageProps {
    className?: string;
}

export const SpaceOverviewPage: FC<SpaceOverviewPageProps> = ({
    className = '',
}): ReactElement => (
    <ConnectedSpacesOverviewWrapper>
        <Page className={`space-overview-page ${className}`}>
            <ConnectedPageHelmet appRoute={AppRoute.spacesOverview} />

            <ConnectedSpacesOverviewRenderer />

            <ConnectedBlockRenderer
                appRoute={AppRoute.spacesOverview}
                className="space-overview-page__block-renderer"
            />
        </Page>
    </ConnectedSpacesOverviewWrapper>
);
