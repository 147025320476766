import { StrapiEntity, StrapiEntityData } from '../../@api/Strapi';
import { BlockResource, DefaultBlock } from '../../Block/Block';

export enum FaqCategoryTypeResource {
    store = 'Winkel',
    onlineStore = 'Webwinkel',
    faq = 'Veelgestelde vragen',
    returns = 'Ruilen en retouren',
    transport = 'Loods 5 transport',
    garage = 'Loods 5 garage',
}

export interface FaqItemResource extends StrapiEntityData {
    category?: FaqCategoryTypeResource;
    question: string;
    response: string;
}

export interface FaqDataResource extends BlockResource {
    title: string;
    showSearchbar: boolean;
    faqs: FaqItemResource[];
}

export type FaqItemsResponse = StrapiEntity<FaqItemResource[]>;

export enum FaqCategoryType {
    store = 'store',
    onlineStore = 'onlineStore',
    faq = 'faq',
    returns = 'returns',
    transport = 'transport',
    garage = 'garage',
    other = 'other',
}

export interface FaqItemInterface {
    id: number;
    category: FaqCategoryType;
    question: string;
    answer: string;
}

export type FaqCategories = Partial<Record<FaqCategoryType, FaqItemInterface[]>>;

export interface FaqDataInterface extends DefaultBlock {
    title?: string;
    showSearchBar: boolean;
    faqItems: FaqItemInterface[];
}
