import { FC, ReactElement } from 'react';

import { AccountDashboardConfig } from '../../containers';
import { Address } from '../../entities/Address/Address';
import { areObjectsEqual } from '../../helpers/object';
import { addAddress, updateCustomerAddress, updateDefaultAddress } from '../../redux/customer/customerActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedAccountDashboardConfig: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const customer = useTypedSelector(state => state.customerSlice.customer);

    if (!customer) {
        return null;
    }

    const handleUpdateAddresses = (newAddressesData: Address): void => {
        if (!newAddressesData.id) {
            dispatch(addAddress(newAddressesData));
            return;
        }

        const matchingAddress = customer.addresses.find(address => address.id === newAddressesData.id);

        if (matchingAddress) {
            const addressHasActualChanges = !areObjectsEqual(matchingAddress, newAddressesData);

            if (addressHasActualChanges) {
                dispatch(updateCustomerAddress(newAddressesData));
            }
        }
    };

    const setDefaultAddress = (id: string): void => {
        dispatch(updateDefaultAddress(id));
    };

    return (
        <AccountDashboardConfig
            customer={customer}
            onUpdateAddresses={handleUpdateAddresses}
            onChangeShippingAddress={setDefaultAddress}
        />
    );
};
