import { FC, ReactElement } from 'react';

import { UspSection } from '../../../../containers';
import { MOCK_USPS } from '../../../../mock/mock-data/usp';

interface UspsExamplesProps {
    className?: string;
}

export const UspSectionExamples: FC<UspsExamplesProps> = ({
    className = '',
}): ReactElement => (
    <UspSection
        items={MOCK_USPS}
        className={`usp-examples ${className}`}
    />
);
