import { PaginationResource } from './Resource';

export interface StrapiEntityData {
    id: number;
    documentId: string;
}

export interface StrapiEntity <T>{
    data?: T;
}

export interface StrapiMeta <T>{
    meta: T;
}

export enum QuerySortDirection {
    asc = 'asc',
    desc = 'desc',
}

export type QuerySortObject = Record<string, QuerySortDirection>;

export type QueryPaginationObject = Partial<Pick<PaginationResource, 'page' | 'pageSize'>>;
