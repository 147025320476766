import { FC, ReactElement } from 'react';

import { AccountTabHeader } from '../../compositions';
import { ConnectedWishlistItems } from '../../connectors';
import { useTrans } from '../../hooks';

import './AccountWishlistPage.scss';

export const AccountWishlistPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-wishlist-page">
            <AccountTabHeader
                title={trans('pages.accountWishlist.title')}
                description={trans('pages.accountWishlist.description')}
            />

            <div className="account-wishlist-page__wishlist-section">
                <h2 className="account-wishlist-page__wishlist-title">
                    {trans('pages.accountWishlist.wishlist.title')}
                </h2>

                <ConnectedWishlistItems className="account-wishlist-page__wishlist" />
            </div>
        </div>
    );
};
