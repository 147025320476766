import { FC, ReactElement } from 'react';

import { Address } from '../../entities/Address/Address';
import { Customer } from '../../entities/Customer/Customer';
import { MyAddresses, MyData } from '../AccountDashboardConfig/subcomponents';

import './AccountData.scss';

interface AccountDataProps {
    customer: Customer;
    onUpdateCustomer: (newCustomerData: Customer) => void;
    onUpdateAddresses: (newAddressesData: Address) => void;
    onChangeShippingAddress: (addressId: string) => void;
    className?: string;
}

export const AccountData: FC<AccountDataProps> = ({
    customer,
    onUpdateCustomer,
    onUpdateAddresses,
    onChangeShippingAddress,
    className = '',
}): ReactElement => (
    <div className={`account-dashboard-config ${className}`}>
        <MyData
            customer={customer}
            onSubmit={onUpdateCustomer}
        />

        <MyAddresses
            addresses={customer.addresses}
            onSubmit={onUpdateAddresses}
            onChangeShippingAddress={onChangeShippingAddress}
        />
    </div>
);
