import { FC, ReactElement, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { LinkButton, LinkIconButton } from '../../compositions';
import { ConnectedWishlistItems } from '../../connectors';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { useTypedSelector } from '../../redux/store';

import './GuestWishlistPage.scss';

export const GuestWishlistPage: FC = (): ReactElement => {
    const trans = useTrans();
    const navigate = useNavigate();

    const hasFetched = useTypedSelector(state => state.customerSlice.hasFetched);
    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    useEffect((): void => {
        if (hasFetched && isAuthenticated) {
            navigate(trans(appRoutes[AppRoute.accountWishlist].path));
        }
    }, [hasFetched, isAuthenticated]);

    return (
        <Page className="guest-wishlist-page">
            <Wrapper>
                <div className="guest-wishlist-page__header">
                    <h1>{trans('pages.guestWishlist.title')}</h1>
                    <p>{trans('pages.guestWishlist.description')}</p>

                    <LinkIconButton
                        hasAnimation
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        isSmall
                        text={trans(appRoutes[AppRoute.login].label)}
                        to={trans(appRoutes[AppRoute.login].path)}
                        className="guest-wishlist-page__login-button"
                    />
                    <div className="guest-wishlist-page__create-account">
                        <p className="guest-wishlist-page__no-account-label">
                            {trans('pages.guestWishlist.noAccount')}
                        </p>

                        <LinkButton
                            text={trans('pages.guestWishlist.createAccount')}
                            to={trans(appRoutes[AppRoute.registration].path)}
                            className="guest-wishlist-page__no-account-button"
                        />
                    </div>
                </div>

                <ConnectedWishlistItems />
            </Wrapper>
        </Page>
    );
};
